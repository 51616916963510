
import React, { useEffect, useState } from 'react'
import { MaterialReactTable } from 'material-react-table'
import Button from '@mui/material/Button'
import { Fade, FormControl, MenuItem, Select, Stack, TextField, ThemeProvider, createTheme } from '@mui/material'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { HttpNode } from '../../../../../axiosInstances';
import { AppBarReciclable } from '../../AppBar';
import swal from 'sweetalert';
import { MRT_Localization_ES } from 'material-react-table/locales/es'; //lenguaje de la tabla
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';

import { Link, useHistory } from 'react-router-dom';
function MisUsuarios() {
  const history = useHistory();
  const style = {//estilos de las modales
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
  };
  //-------MODAL DE CREAR NUEVO USUARIO

  const [open1, setOpen1] = useState(false); //Estado de la segunda modal
  const handleOpen1 = () => {
    setOpen1(true)
  }
  const handleClose1 = () => {
    setOpen1(false)
  }
  const [editing, setEditing] = useState(false); //Editable
  const [open, setOpen] = useState(false); //Estado de la modal
  const [selectedRow, setSelectedRow] = useState({
    cargo: "",
    created_at: "",
    created_by: "",
    email_address: "",
    es_activo: "",
    es_exento: "",
    id: "",
    idoc_dv: "",
    idoc_id: "",
    idoc_numero: "",
    nombre: "",
    rolename: "",
    telefono: "",
    tipo_doc: "",
    updated_at: "",
    updated_by: "",
    usr_name: ""
  }); // Estado para almacenar la fila seleccionada
  const handleEditUser = (event, fieldName) => {
    const { value } = event.target;
    setSelectedRow((EditUsuario) => ({
      ...EditUsuario,
      [fieldName]: value,
    }));
  };
  const [IDAPI, setID_API] = useState('')//Reemplazamos el localStorage y ustilizamos el estado para guardar los datos
  const [ActualizarDatos, setActualizarDatos] = useState('')
  const handleOpen = (rowData) => { // Función para abrir la modal y establecer la fila seleccionada
    setEditing(true)
    setOpen(true);
    ////console.log(rowData.id)//id de la API 1, utilizar un localStorage para reemplazar el numero de la id de los usuarios de la segunda API
    let ID_API = rowData.id
    //localStorage.setItem("API", ID_API)
    setID_API(ID_API)
    //console.log(rowData.tipo_doc)
    async function fetchUsersData() {
      try {
        const response1 = await HttpNode.get(`/setfx/usuarios/${ID_API}`)
        const dataAct = response1.data.data[0]
        //console.log(dataAct)
        //localStorage.setItem("datosActualizar", dataAct)
        setActualizarDatos(dataAct)
        ////console.log('datos de la segunda api: ',data1)
        setSelectedRow(dataAct);//datos que envia hacia la modal
      } catch (error) {
        console.error(error)
        //swal({ title: 'Ha ocurrido un error', icon: 'error' }) //mensaje generico
        console.error(error.message);
        swal({ title: 'Ha ocurrido un error', text: error.message, icon: 'error' })
      }
    }
    fetchUsersData();
  }
  const handleClose = () => {// Función para cerrar la modal
    setOpen(false);
    setEditing(false);
  }





  //filtro para activos y inactivos
  const [filtroActivo, setFiltroActivo] = useState('todos'); // 'activos', 'desactivados', 'todos'

  const [data, setData] = useState([]);
  async function fetchUsers() {//usuarios de la tabla
    try {
      const entityId = localStorage.getItem('entidadId');
      const response = await HttpNode.get(`/setfx/entidades/${entityId}/usuarios`); //esta api solo es para la tabla
      let data = response.data.data;
      if (filtroActivo === 'activos') {
        data = data.filter(user => user.es_activo);
        //console.log("validacion para el filtro", data);
      } else if (filtroActivo === 'inactivos') {
        data = data.filter(user => !user.es_activo);
        //console.log("validacion para el filtro", data);
      }

      localStorage.setItem("datos", data) //no se esta utilizado alpaarecer
      //console.log('datos de la primera api: ', data)
      setData(data);
    } catch (error) {
      console.error(error.message);
      //swal({title:'Ha ocurrido un error',text:error.message,icon:'error'}) //mensaje generico, este se muestra cuado se recarga la pagina y se elimina todo y cuando no hay usuarios
      swal({ title: 'Ha ocurrido un error', text: error.message, icon: 'error' })
    }
  }
  useEffect(() => {
    fetchUsers();
  }, [filtroActivo]);



  //validacion de campos 
  const [isError, setIsError] = useState(false);
  const handleInputChange = (event) => {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9]+$/;

    if (regex.test(value)) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  };



  //Opciones de Activo Excento y Rol
  const [Activo, setActivo] = useState([])
  const [selectedActivo, setSelectedActivo] = useState({ value: 'Si' });
  let activo = [{ value: 'Si' }, { value: 'No' }]

  useEffect(() => {
    function ActivoValue() {
      try {
        const optionsActivo = activo.map((activoValue) => ({
          value: activoValue.value
        }))
        //console.log('Seleccionado: ', optionsActivo)
        setActivo(optionsActivo)
        setSelectedActivo(optionsActivo[0]);
        //console.log("este es el valor del ACTIVO", setSelectedActivo(optionsActivo[0]))
      }
      catch (error) {
        //console.log('Algo salio mal: ', error)
      }
    }
    ActivoValue()
  }, [])

  const [Excento, setExcento] = useState([])
  const [selectedExcento, setSelectedExcento] = useState({ value: 'No' });
  let excento = [{ value: 'Si' }, { value: 'No' }]

  useEffect(() => {
    function ExcentoValue() {
      try {
        const optionsExcento = excento.map((excentoValue) => ({
          value: excentoValue.value
        }))
        //console.log('Selecionado Excento: ', optionsExcento)
        setExcento(optionsExcento)
        setSelectedExcento(optionsExcento[1]);
        //console.log("este es el valor del EXCENTO", setSelectedExcento(optionsExcento[1]))
      }
      catch (error) {
        //console.log('Algo salio mal: ', error)
      }
    }
    ExcentoValue()
  }, [])

  const [Rol, setRol] = useState([])
  const [selectedRol, setSelectedRol] = useState(null)
  let rol = [{ value: 'administrador' }, { value: 'usuario' }]
  useEffect(() => {
    function RolValue() {
      try {
        const optionsRol = rol.map((rolValue) => ({
          value: rolValue.value
        }))
        //console.log('Seleccionado Rol: ', optionsRol)
        setRol(optionsRol)
        //setSelectedRol(optionsRol)
      }
      catch (error) {
        //console.log('Algo salio mal: ', error)
      }
    }
    RolValue()
  }, [])
  //Fin de Opciones de Activo Excento y Rol
  //api para los documentos 
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  useEffect(() => {
    async function fetchDocumentTypes() {
      try {
        const response = await HttpNode.get("/setfx/app/idocs");
        const data = response.data.data;
        //console.log("API response data:", data);
        if (data && data.length > 0) {
          ////console.log("Funciona la traida de datos");
          const options = data.map((documentType) => ({
            label: documentType.nombre,
            value: documentType.codigo,
          }));
          //console.log("Funciona la traida de datos: ", options);
          setDocumentTypes(options);
          //setSelectedDocumentType(null); // Set to null or ''
        } else {
          //console.log("No esta mapeando bien los datos");
        }
      } catch (error) {
        console.error(error);
        //swal({ title: 'Ha ocurrido un error', icon: 'error' }) //mensaje generico
        swal({ title: 'Ha ocurrido un error', text: error.message, icon: 'error' })
      }
    }
    fetchDocumentTypes();
  }, []);
  // funcion para editar los campos del usuario
  ////console.log('jajas', selectedDocumentType)
  async function envio() {
    let $Cargo = document.getElementById("outlined-basic6") //-> Cargo
    let $Telefono = document.getElementById("outlined-basic7") //-> Telefono
    let $Nombre = document.getElementById("outlined-basic8") //-> Nombre
    let $Documento = document.getElementById("outlined-basic9") //-> Numero de Documento
    let DATOS = ActualizarDatos//localStorage.getItem("datosActualizar") reemplazamos este localStorage y utilizamos el estado de setActualizarDatos
    const DatosEnviar = {
      telefono: $Telefono.value,
      nombre: $Nombre.value,
      idoc_numero: $Documento.value,
      cargo: $Cargo.value,
      tipo_doc: selectedDocumentType
    };


    ////console.log(DatosEnviar)
    let ID_API = IDAPI //localStorage.getItem("API") utilizamos el estado IDAPI donde obtenemos el valor
    try {
      const updateUserData = Object.assign({}, DATOS, DatosEnviar)
      ////console.log(updateUserData.cargo)
      ////console.log("Datos que envia hacia la api: ",DatosEnviar)
      ////console.log('jejeej: ',updateUserData)
      const response = await HttpNode.put(`/setfx/usuarios/${ID_API}`, updateUserData)
      ////console.log('Datos modificados: ', response.data)
      swal({
        title: `${response.data.message}`,
        text: 'Los datos han sido editados',
        icon: 'success',
        button: 'OK'
      })
        .then(() => {
          handleClose()
        })
      fetchUsers();
    } catch (error) {
      console.error(error)
      console.error(error.message);
      swal({
        title: 'Error...',
        text: 'No se pudo guardar la información.',
        icon: 'error',
        button: 'OK'
      })
    }
    ////console.log(enviar.email_address + '\n' + enviar.cargo + '\n' + enviar.telefono + '\n' + enviar.usr_name + '\n' + enviar.idoc_numero)
    ////console.log($aver2.value+ '\n' + $aver3.value+ '\n' + $aver4.value+ '\n' + $aver5.value+ '\n' + $aver6.value+ '\n' + $aver7.value+ '\n' + $aver8.value+ '\n' + $aver9.value)
  }
  // Manejador de cambios para los campos del nuevo usuario
  const handleNuevoUsuarioChange = (event, fieldName) => {
    const { value } = event.target;

    if (fieldName === 'idoc_numero' || fieldName === 'nombre') {
      ////console.log("___________________validacion campo activo ____________")
      //       //console.log('Campo:', fieldName);
      // //console.log('Valor actual:', nuevoUsuario[fieldName]);
      // //console.log('Nuevo valor:', value);

      if (!value.trim() === '') {
        // Campos obligatorios están vacíos, mostrar SweetAlert de error
        swal({
          title: 'Error',
          text: 'Los campos obligatorios no pueden estar vacíos.',
          icon: 'error',
          button: 'OK',
        });
        return; // Evita actualizar el estado
      }
    }

    setNuevoUsuario((prevUsuario) => ({
      ...prevUsuario,
      [fieldName]: value,
    }));

    // Actualizar selectedRol si es el campo correspondiente
    if (fieldName === 'rolename') {
      setSelectedRol(value);
    }

    // Actualizar selectedDocumentType si es el campo correspondiente
    if (fieldName === 'tipo_doc') {
      setSelectedDocumentType(value);
    }

    //console.log('Nuevo valor de es_activo:', value === 'Si');
  };

  //modal de confirmacion de activacion/desactivacion de usuario
  async function confirmarAccion(usuario, accion, datos) {
    try {
      const confirmacion = await swal({
        title: "¿Estás seguro(a)?",
        text: `Estás a punto de ${accion} al usuario ${usuario}`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      });

      return confirmacion;
    } catch (error) {
      // Si el usuario cierra la alerta, se considera que canceló la acción
      return false;
    }
  }

  async function Desactivar(datos) {
    //console.log(datos)
    const usuario = datos.nombre; // Reemplaza "nombre" con el campo correcto en tus datos
    const confirmacion = await confirmarAccion(usuario, "desactivar", datos);
    if (confirmacion) {
      const desactivar = {
        id: datos.id,
        es_activo: false,
        updated_by: parseFloat(localStorage.getItem('id_usr'))
      }
      //console.log(desactivar)
      try {
        const response = await HttpNode.post('/setfx/usuarios/estatus', desactivar)
        //console.log(response.data)
        fetchUsers();
        swal({ title: 'Desactivado!!!', text: 'Usuario desactivado con exito.', icon: 'success', button: 'OK' })
      } catch (error) {
        console.error(error.message);
        swal({ title: 'Ha ocurrido un error', text: error.response.data.message, icon: 'error' })
      }
    }
  }

  async function Activar(datos) {
    //console.log(datos)
    const usuario = datos.nombre; // Reemplaza "nombre" con el campo correcto en tus datos
    const confirmacion = await confirmarAccion(usuario, "activar", datos);
    if (confirmacion) {
      const activar = {
        id: datos.id,
        es_activo: true,
        updated_by: parseFloat(localStorage.getItem('id_usr'))
      }
      //console.log(activar)
      try {
        const response = await HttpNode.post('/setfx/usuarios/estatus', activar)
        //console.log(response.data)
        fetchUsers();
        swal({ title: 'Activado!!!', text: 'Usuario activado con exito.', icon: 'success', button: 'OK' })
      } catch (error) {
        console.error(error.response.status)
        console.error(error.message);
        swal({ title: 'Ha ocurrido un error!!!', text: error.response.data.message, icon: 'error' })
      }
    }
  }
  function Botones(rowData) {
    if (rowData.es_activo === true) {
      if (rowData.rolename === "administrador") {
        return '';
      } else {
        return <Button sx={{ bgcolor: '#de1f21', '&:hover': { bgcolor: '#a22829' } }} variant='contained' onClick={() => Desactivar(rowData)} disabled={rowData.estatus !== 'disponible'}>Desactivar</Button>;
      }
    } else {
      if (rowData.rolename === "administrador") {
        return '';
      } else {
        return <Button color='success' variant='contained' onClick={() => Activar(rowData)}>Activar</Button>;
      }
    }
  }


  //doble click que me lleve hacia subdetalles pr el id del usuario
  const handleDoubleClick = (userId, estatus) => {
    if (estatus !== 'disponible') {
      history.push(`/imc/MisProductos/Detalles/SubDetalles/${userId}`);
    }
  };

  const columns = [
    {
      accessorKey: 'usr_name',
      header: 'Usuario',
      filterFn: 'customFilterFn',
    },
    {
      accessorKey: 'rolename',
      header: 'Rol',
      filterFn: 'customFilterFn',
    },
    {
      accessorKey: 'nombre',
      header: 'Nombre',
      filterFn: 'customFilterFn',
      Cell: ({ row }) => (
        <div
          onClick={() => handleDoubleClick(row.original.id, row.original.estatus)}
          style={{
            cursor: row.original.estatus !== 'disponible' ? 'pointer' : 'default',
            textDecoration: row.original.estatus !== 'disponible' ? 'underline' : 'none',
          }}
        >
          {row.original.estatus !== 'disponible' ? (
            <Link to={`/imc/MisProductos/Detalles/SubDetalles/${row.original.id}`}>
              {row.original.nombre}
            </Link>
          ) : (
            row.original.nombre
          )}
        </div>
      ),
    },
    {
      accessorKey: 'estatus',
      header: 'Estatus',
      filterFn: 'customFilterFn',
    },
    {
      accessorKey: 'nombre_perfil',
      header: 'Perfil',

    },
    {
      accessorKey: `es_activo`,
      header: 'Activo',
      Cell: ({ row }) => (row.original.es_activo ? 'Si' : 'No'),
    },
    {
      accessorKey: 'es_exento',
      header: 'Contingencia',
      Cell: ({ row }) => (row.original.es_exento ? 'Si' : 'No'),
      //Cell: ({ rowData }) => (rowData && rowData.es_exento !== undefined ? rowData.es_exento.toString() : '-'),
      // for (const objeto of data) {//mostrar solo el valor de es_exento
      //   return objeto.es_exento == true ? 'Si' : 'No';
      // }
    },
    {
      header: 'Editar',
      Cell: ({ row }) => (
        <>
          <Button
            sx={{ fontSize: "11px" }}
            variant='contained'
            onClick={() => handleOpen(row.original)}
          >
            Editar
          </Button>
        </>
      ),
    },
    {
      header: 'Acción',
      Cell: ({ row }) => (Botones(row.original))
    }
  ];
  const muiTopToolbarProps = {
    sx: {
      height: '55px',
      '& .MuiIconButton-root svg': {
        fontSize: '29px',
        '@media (max-width: 600px)': {
          fontSize: '22px', // Ajusta el tamaño de fuente para pantallas más pequeñas
        },
      },
      '& .MuiInputBase-root': {
        fontSize: '17px',
      },
      '& .MuiInputBase-root svg': {
        fontSize: '22px',
      },
      '& .MuiButtonBase-root': {
        fontSize: '34px',
        '@media (max-width: 600px)': {
          fontSize: '25px', // Ajusta el tamaño de fuente para pantallas más pequeñas
        },
      },
      display: 'contents',
    },
  };

  const muiBottomToolbarProps = {
    sx: {
      height: '60px',
      '& .MuiIconButton-root svg': {
        fontSize: '22px',
      },
      '& .MuiTablePagination-selectLabel': {
        fontSize: '12px',
      },
      '& .MuiSelect-standard': {
        fontSize: '12px',
        alignItems: 'center',
        display: 'flex',
      },
      '& .MuiSvgIcon-fontSizeMedium': {
        fontSize: '15px',
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: '15px',
      },
    },
  };
  const muiTableProps = {
    sx: {
      '& .MuiTableCell-root svg': {
        fontSize: '25px',
      },
    }
  }



  const tableTheme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.5rem', //override to make tooltip font size larger, tooltips
              },
            },
          },
          MuiTableCell: {//celdas de la tabla
            styleOverrides: {
              root: {
                fontSize: '15px', // Ajusta el tamaño de fuente de las celdas de la tabla
              },
            },
          },
        },
        typography: {//edicion de enableEditing, la lista del arreglo(despues del "Rows per page") y el Show/Hide columns(tercer icono parte superior)
          button: {//"botones" de enableEditing
            fontSize: '12px',
          },
          body1: {//formulario y lista del arreglo, si se coloca "body2" no se aplican los estilos
            fontSize: '14px'
          },
        },
      }),
    [],
  );
  ////console.log("Initial selected document type:", selectedDocumentType);
  // Agrega un nuevo estado para los datos del nuevo usuario
  const [nuevoUsuario, setNuevoUsuario] = useState({
    usr_name: '',
    email_address: '',
    es_activo: '',
    es_exento: '',
    rolename: '',
    cargo: '',
    telefono: '',
    nombre: '',
    tipo_doc: '',
  });
  useEffect(() => {
    if (open1) {
      setNuevoUsuario({
        usr_name: '',
        email_address: '',
        es_activo: '',
        es_exento: '',
        rolename: '',
        cargo: '',
        telefono: '',
        nombre: '',
        tipo_doc: '',
        idoc_numero: '',
      });
      setSelectedDocumentType('');
      setSelectedRol('')
    }
  }, [open1]);


  //control de adjunto para usuario
  const [isAdminRole, setIsAdminRole] = useState(false);

  //control de archivo adjunto
  // Estado para el archivo de contrato seleccionado
  const [contractFile, setContractFile] = useState(null);


  const handleFileChange = (event) => {
    const file = event.target.files[0];

    console.log("estoy dentro de handleFileChange y esto es lo que trae el file", file)
    if (file) {
      const fileName = file.name.toLowerCase();
      console.log("este es el filename: ", fileName);

      // Validar extensión y tamaño del archivo
      if ((fileName.endsWith('.pdf') || fileName.endsWith('.docx') || fileName.endsWith('.xlsx')) && file.size <= 500 * 1024) { // Tamaño máximo de 500 KB
        setContractFile(file);
      } else {
        // Muestra un mensaje de error si la extensión o el tamaño no son válidos
        swal({
          title: 'Error',
          text: 'Por favor, seleccione un archivo con una extensión y tamaño válidos.',
          icon: 'error',
          button: 'OK',
        });

        // Limpiar el campo de entrada de archivo
        event.target.value = null;
      }
    }
  };




  const handleUpload = async (userId) => {
    try {
      if (contractFile) {
        console.log('Valor de contractFile:', contractFile)
        const formData = new FormData();
        formData.append('fupload', contractFile)
        //  const config = {
        //    headers: {
        //      'Content-Type': 'multipart/form-data',
        //    },
        //  };    
        console.log("array de form data")
        console.log(Array.from(formData));

        const response = await HttpNode.post(`/setfx/usuarios/${userId}/contrato`, formData)
        // Manejar la respuesta de la API según tus necesidades
        console.log('Respuesta de carga de contrato:', response.data)
        // Limpiar el estado del archivo después de cargarlo
        setContractFile([]); // Limpia el estado de los archivos
        handleClose1(); // Cerrar el modal u realizar otras acciones necesaria
        console.log('Carga de contrato completada.');
      } else {
        // Muestra un mensaje si no se ha seleccionado ningún archivo
        swal({
          title: 'Error',
          text: 'Por favor, seleccione un archivo antes de cargar.',
          icon: 'error',
          button: 'OK',
        });
      }
    } catch (error) {
      console.error('Error al cargar el contrato:', error);
      swal({
        title: 'Error',
        text: 'Ha ocurrido un error al cargar el contrato.',
        icon: 'error',
        button: 'OK',
      });
    }
  };



  // Función para crear un nuevo usuario




  //estado para reestablecer el documento



  async function crearNuevoUsuario() {
    /* if (nuevoUsuario.usr_name === '') {
       return swal({
         title: 'Usuario no definido',
         text: 'Debe definir un nombre de usuario para continuar',
         icon: 'error'
       });
     }*/
    if (!nuevoUsuario.rolename) {
      return swal({
        title: 'Campo de rol vacío!!',
        text: 'El rol es obligatorio',
        icon: 'error'
      });
    }
    if (nuevoUsuario.email_address === '' && nuevoUsuario.rolename === 'administrador') {
      return swal({
        title: 'Campo de correo electrónico vacío!!',
        text: 'El correo electrónico es obligatorio',
        icon: 'error'
      });
    }
    if (!nuevoUsuario.idoc_numero || !nuevoUsuario.nombre) {
      return swal({
        title: 'Campos obligatorios vacíos',
        text: 'Los campos de Documento y Nombre son obligatorios',
        icon: 'error'
      });
    }
    else {
      try {
        let nuevoAct;
        //console.log("datos que envia hacia la base de datos", nuevoUsuario)
        if (nuevoUsuario.es_activo === 'Si') {
          nuevoAct = { es_activo: true }
          //console.log('Nuevos datos a enviar ACTIVO: ',nuevoAct)//
        }
        else {
          nuevoAct = { es_activo: false }
          //console.log('Nuevos datos a enviar ACTIVO: ',nuevoAct)
        }
        //console.log('Fuera de la condicional if else: ', nuevoAct)
        let nuevoExc;
        if (nuevoUsuario.es_exento === 'Si') {
          nuevoExc = { es_exento: true }
          ////console.log('Nuevos datos a enviar EXCENTO: ',nuevoExc)
        }
        else {
          nuevoExc = { es_exento: false }
          ////console.log('Nuevos datos a enviar EXCENTO: ',nuevoExc)
        }
        const UsuarioNuevo = Object.assign({}, nuevoUsuario, { es_activo: nuevoAct.es_activo, es_exento: nuevoExc.es_exento })
        const entityId = localStorage.getItem('entidadId');
        const administrador_id = localStorage.getItem("id_usr")


        console.log('Los datos a enviar son: ', UsuarioNuevo)
        console.log('ID del usuario obtenido del localStorage:', administrador_id);
        const response = await HttpNode.post('/setfx/usuarios', {
          ...UsuarioNuevo,
          entidad_id: entityId,
          administrador_id: administrador_id

        });



        if (response.data.data) {
          const userId = response.data.data.id;
          console.log("Este es el id de la respuesta del nuevo usuario", userId)


          // Verificar si el rol seleccionado es 'administrador'
          if (selectedRol === 'administrador') {
            if (contractFile) {
              await handleUpload(userId);
            } else {
              // Muestra un mensaje si no se ha seleccionado ningún archivo
              swal({
                title: 'Error',
                text: 'Por favor, seleccione un archivo antes de cargar.',
                icon: 'error',
                button: 'OK',
              });
              return;
            }
          }
        }

        fetchUsers();
        swal({
          title: 'Usuario creado',
          icon: 'success',
          text: 'Genial, los datos han sido registrados exitosamente.',
          button: 'OK'
        })
          .then(() => {
            handleClose1()
          })
      }
      catch (error) {
        console.error(error.response.status)
        console.error(error.message);
        console.error('Error al crear el usuario:', error);
        swal({
          title: 'Error!...',
          text: error.response.data.message,
          icon: 'error',
          button: 'OK'
        })
        // Manejo de errores en caso de que la solicitud no sea exitosa
      }
    }
  }

  //Inicio filtro buscar
  const [search, setSearch] = useState('');
  const handleChangeFiltro = (event) => {
    setSearch(event.target.value);
  };
  const filtro = data.filter((buscar) => {
    if (buscar.nombre.toUpperCase().includes(search.toUpperCase())) {
      return true;
    }
    return false;
  });
  //Fin filtro buscar
  return (
    <div>
      <AppBarReciclable />
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          className='head'
          renderTopToolbarCustomActions={({ table }) => (
            <>
              <div>
                <h3 style={{ marginTop: '2px', marginRight: '10px' }}>Mis Usuarios</h3>
                {/* <Button variant='contained' style={{ fontSize: '11px', marginTop: '5px', marginLeft: 'auto', marginRight: '4px', }} color='success' onClick={handleOpen1}>Crear Nuevo Usuario</Button> */}
                <Button variant='contained' style={{ fontSize: '11px', marginRight: '1208px' }} color='success' onClick={handleOpen1}>Crear Nuevo Usuario</Button>
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="filtro" name="filtro" value={filtroActivo} onChange={(e) => setFiltroActivo(e.target.value)}>
                    <FormControlLabel value="todos" control={<Radio size="small" />} label={<h5>Todos</h5>} aria-label="Todos" sx={{ labelSize: '0.2rem', variant: 'outlined' }} />
                    <FormControlLabel value="activos" control={<Radio size="small" />} label={<h5>Activos</h5>} aria-label="Activos" sx={{ labelSize: '0.2rem', variant: 'outlined' }} />
                    <FormControlLabel value="inactivos" control={<Radio size="small" />} label={<h5>Inactivos</h5>} aria-label="Inactivos" sx={{ labelSize: '0.2rem', variant: 'outlined' }} />
                  </RadioGroup>
                </FormControl>


              </div>
              {/**Esta es la modal para crear el usuario  */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                sx={{ opacity: 1 }}
                open={open1}
                slotProps={{ backdrop: { timeout: 500 } }}
                onClose={() => {
                  handleClose1();

                }}
              >
                <Fade in={open1} style={{ width: '450px' }}>
                  <Box sx={style}>
                    <form >
                      {/* <div>
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: '120px', fontSize: { xs: '18px', md: '21px' } }}>Usuario</Typography>
                        <TextField
                          id="outlined-basic"
                          size='small'
                          sx={{ width: { xs: '95px', md: '190px' } }}
                          placeholder='Usuario'
                          value={nuevoUsuario.usr_name}
                          onChange={(event) => {
                            // Sanitiza el valor eliminando caracteres no permitidos
                            const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
                            event.target.value = sanitizedValue;

                            // Llama al segundo manejador primero
                            handleInputChange(event);
                            // Luego llama al manejador de nuevoUsuario
                            handleNuevoUsuarioChange(event, 'usr_name');
                          }}
                          error={isError}
                          helperText={isError ? 'La entrada no es válida. Por favor, solo ingrese letras y números.' : ''}
                          disabled // Agregar la propiedad disabled para deshabilitar el campo
                          InputProps={{
                            readOnly: true, // Esto evita que el campo sea editable incluso visualmente
                          }}
                        />
                      </div> */}
                      <div >
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '31px', md: '15px' }, fontSize: { xs: '18px', md: '21px' } }}>Correo Electrónico</Typography>
                        <TextField id="outlined-basic2" size='small' sx={{ width: { xs: '95px', md: '190px' } }} placeholder='Correo Electronico' value={nuevoUsuario.email_address} onChange={(event) => handleNuevoUsuarioChange(event, 'email_address')} />
                      </div>
                      <div >


                      </div>
                      <div >
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '50px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '50px', md: '68px' }, fontSize: { xs: '18px', md: '21px' } }}>Contingencia</Typography>
                        {/* <TextField id="outlined-basic4" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={nuevoUsuario.es_exento === true ? 'Si' : 'No'} onChange={(event) => handleNuevoUsuarioChange(event, 'es_exento')} /> */}
                        <FormControl sx={{ width: { xs: '95px', md: '190px' } }} size='small' title='Excento'>
                          <Select
                            id="outlined-basic4"
                            value={selectedExcento.value} // Accede al valor 'value' de selectedExcento
                            onChange={(event) => {
                              const ExcentoVal = { value: event.target.value }; // Crea un objeto con 'value' como propiedad
                              setSelectedExcento(ExcentoVal)
                              handleNuevoUsuarioChange(event, 'es_exento')
                              //console.log("Linea 789 es excento",ExcentoVal)


                            }}
                          >
                            {Excento.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div >
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '155px', md: '160px' }, fontSize: { xs: '18px', md: '21px' } }}>Rol</Typography>
                        {/* <TextField id="outlined-basic5" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={nuevoUsuario.rolename} onChange={(event) => handleNuevoUsuarioChange(event, 'rolename')} /> */}
                        <FormControl sx={{ width: { xs: '95px', md: '190px' } }} size='small' title='Rol'>
                          <Select
                            id="outlined-basic5"
                            value={selectedRol}
                            onChange={(event) => {
                              const RolVal = event.target.value
                              setSelectedRol(RolVal)
                              handleNuevoUsuarioChange(event, 'rolename')

                              // Actualiza el estado isAdminRole
                              setIsAdminRole(RolVal === 'administrador');
                            }}
                          >
                            {Rol.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>


                      <div >
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '133px', md: '134px' }, fontSize: { xs: '18px', md: '21px' } }}>Cargo</Typography>
                        <TextField id="outlined-basic6" size='small' sx={{ width: { xs: '95px', md: '190px' } }} placeholder='Cargo' value={nuevoUsuario.cargo} onChange={(event) => handleNuevoUsuarioChange(event, 'cargo')} />
                      </div>
                      <div >
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '113px', md: '112px' }, fontSize: { xs: '18px', md: '21px' } }}>Teléfono</Typography>
                        <TextField id="outlined-basic7" size='small' sx={{ width: { xs: '95px', md: '190px' } }} placeholder='Telefono' value={nuevoUsuario.telefono} onChange={(event) => handleNuevoUsuarioChange(event, 'telefono')} />
                      </div>
                      <div >
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '118px', md: '117px' }, fontSize: { xs: '18px', md: '21px' } }}>Nombre</Typography>
                        <TextField id="outlined-basic8" size='small' sx={{ width: { xs: '95px', md: '190px' } }} placeholder='Nombre' value={nuevoUsuario.nombre} onChange={(event) => handleNuevoUsuarioChange(event, 'nombre')} />
                      </div>
                      <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
                        <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '40px', md: '32px' }, marginRight: { xs: '89px', md: '75px' }, fontSize: { xs: '18px', md: '23px' } }}>Documento</Typography>
                        <div style={{ display: 'grid' }}>
                          <FormControl sx={{ width: { xs: '95px', md: '190px' }, marginBottom: { xs: '4px', md: '7px' } }} placeholder='Tipo de documento' size="small" title='Tipo de documento'>
                            <Select
                              value={selectedDocumentType || ''}
                              placeholder='Tipo de documento'
                              onChange={(event) => {
                                const selectedValue = event.target.value;
                                setSelectedDocumentType(selectedValue);
                                handleNuevoUsuarioChange(event, 'tipo_doc');
                              }}
                            >
                              {documentTypes.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField id="outlined-basic9" size='small' sx={{ width: { xs: '95px', md: '190px' } }} placeholder='Numero de Documento' value={nuevoUsuario.idoc_numero} onChange={(event) => handleNuevoUsuarioChange(event, 'idoc_numero')} />
                        </div>
                      </div>

                      {isAdminRole && (
                        <div>
                          <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '31px', md: '15px' }, fontSize: { xs: '18px', md: '21px' } }}>
                            Adjunto
                          </Typography>
                          <input
                            type="file"
                            accept=".pdf,.docx,.xlsx"
                            onChange={(event) => handleFileChange(event)}
                            sx={{ marginBottom: '10px', position: 'relative', zIndex: -1 }}
                          />
                        </div>
                      )}
                      <Stack spacing={2} sx={{ justifyContent: 'center', marginTop: '32px' }} direction="row">
                        <Button variant='contained' color='success' onClick={crearNuevoUsuario}>Aplicar</Button>
                        <Button variant='contained' sx={{ bgcolor: '#ff3d00', '&:hover': { bgcolor: '#b22a00' } }} onClick={handleClose1}>Cancelar</Button>
                      </Stack>
                    </form>
                  </Box>
                </Fade>
              </Modal>
            </>)}
          positionGlobalFilter="right"
          muiSearchTextFieldProps={{
            placeholder: `Buscar por Nombre`,
            sx: { minWidth: '300px' },
            variant: 'outlined',
            size: 'small',
            value: search,
            onChange: handleChangeFiltro,
          }}
          // enableTableHead={{sx:{
          //   '& .css-1ek8zbm-MuiToolbar-root':{
          //     display: 'contents'
          //   }
          // }}}
          //.MuiTableHead-root
          enableColumnFilters={false} //filtering does not work with memoized table body
          data={filtro}
          filtroActivo={filtroActivo}
          muiTableHeadProps={{
            sx: {
              // '& .css-1ek8zbm-MuiToolbar-root':{
              //   display: 'contents' 
              // }
            }
          }}
          columns={columns}
          muiTopToolbarProps={muiTopToolbarProps}
          muiBottomToolbarProps={muiBottomToolbarProps}
          muiTableProps={muiTableProps}
          initialState={{ pagination: { pageSize: 40 } }}
          enableFilters={true}
          localization={MRT_Localization_ES}
          enableColumnOrdering={{
            color: '#5b95cf',
          }}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Editar',
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 60,
            },
          }}
          muiTablePaperProps={{
            sx: {
              '& .MuiTypography-root': {
                fontSize: '64px'
              }
            }
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: {
                xs: '20px',
                sm: '11px',
                md: '12px',
                lg: '15px',
                xl: '18px',
              },
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between',
              },
              '& .Mui-TableHeadCell-Content svg': {
                fontSize: '24px',
              },
              '& .MuiInputBase-input': {
                fontSize: '17px'
              },
              color: '#5b95cf',
            },
          }}
        />
        {/**Esta es la modal de edicion  */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          sx={{ opacity: 1 }}
          open={open}
          slotProps={{ backdrop: { timeout: 500 } }}
          onClose={handleClose}
        >
          <Fade in={open} style={{ width: '450px' }}>
            <Box sx={style}>
              <form >
                {/* <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: '120px', fontSize: { xs: '18px', md: '21px' } }}>Usuario</Typography>
                  <TextField id="outlined-basic" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.usr_name} disabled />
                </div> */}
                <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '31px', md: '15px' }, fontSize: { xs: '18px', md: '21px' } }}>Correo Electrónico</Typography>
                  <TextField id="outlined-basic2" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.email_address} disabled />
                </div>
                <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '133px', md: '135px' }, fontSize: { xs: '18px', md: '21px' } }}>Activo</Typography>
                  <TextField id="outlined-basic3" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.es_activo === true ? 'Si' : 'No'} disabled />
                </div>
                <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '50px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '50px', md: '68px' }, fontSize: { xs: '18px', md: '21px' } }}>Contingencia</Typography>
                  <TextField id="outlined-basic4" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.es_excento === true ? 'Si' : 'No'} disabled />
                </div>
                <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '155px', md: '160px' }, fontSize: { xs: '18px', md: '21px' } }}>Rol</Typography>
                  <TextField id="outlined-basic5" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.rolename} disabled />
                </div>
                <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '133px', md: '134px' }, fontSize: { xs: '18px', md: '21px' } }}>Cargo</Typography>
                  <TextField id="outlined-basic6" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.cargo || ""} onChange={(event) => { setSelectedRow({ ...selectedRow, cargo: event.target.value }); }} disabled={!editing} />
                </div>
                <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '113px', md: '112px' }, fontSize: { xs: '18px', md: '21px' } }}>Teléfono</Typography>
                  <TextField id="outlined-basic7" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.telefono || ""} onChange={(event) => { setSelectedRow({ ...selectedRow, telefono: event.target.value }); }} disabled={!editing} />
                </div>
                <div>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: { xs: '118px', md: '117px' }, fontSize: { xs: '18px', md: '21px' } }}>Nombre</Typography>
                  <TextField id="outlined-basic8" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.nombre || ""} onChange={(event) => { setSelectedRow({ ...selectedRow, nombre: event.target.value }); }} disabled={!editing} />
                </div>
                <div style={{ display: 'inline-flex', marginBottom: '20px' }}>
                  <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '40  px', md: '32px' }, marginRight: { xs: '89px', md: '75px' }, fontSize: { xs: '18px', md: '23px' } }}>Documento</Typography>
                  <div style={{ display: 'grid' }}>
                    <FormControl sx={{ width: { xs: '95px', md: '190px' }, marginBottom: { xs: '4px', md: '7px' } }} size="small" title='Tipo de documento'>
                      <Select
                        value={selectedRow.tipo_doc || ""}
                        onChange={(event) => {
                          const selectedTipoDoc = event.target.value
                          setSelectedDocumentType(selectedTipoDoc)
                          handleEditUser(event, 'tipo_doc')
                        }
                        }
                      >
                        {documentTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField id="outlined-basic9" size='small' sx={{ width: { xs: '95px', md: '190px' } }} value={selectedRow.idoc_numero} onChange={(event) => { setSelectedRow({ ...selectedRow, idoc_numero: event.target.value }); }} disabled={!editing} />
                  </div>
                </div>
                <Stack spacing={2} sx={{ justifyContent: 'center', top: '32px' }} direction="row">
                  <Button variant='contained' color='success' onClick={envio}>Aplicar</Button>
                  <Button variant='contained' sx={{ bgcolor: '#ff3d00', '&:hover': { bgcolor: '#b22a00' } }} onClick={handleClose}>Cancelar</Button>
                </Stack>
              </form>
            </Box>
          </Fade>
        </Modal>
      </ThemeProvider>
    </div>
  )
}
export default MisUsuarios
