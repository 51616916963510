import React, { useEffect, useState } from 'react'
import { AppBarReciclable } from '../../AppBar'
import { MaterialReactTable } from 'material-react-table'
import { ThemeProvider, createTheme } from '@mui/material';
import { HttpNode } from '../../../../../axiosInstances';
import swal from 'sweetalert';
import { MRT_Localization_ES } from 'material-react-table/locales/es'; 


function MisSolicitudes() {
  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchUsers() {
      try {
        const entityId = localStorage.getItem("entidadId")
        const response = HttpNode.get(`/setfx/entidades/${entityId}/solicitudes`)
        const data = (await response).data.data
        if (isMounted) {
          if (data && data.length > 0) {
            setData(data);
          } else {
            //console.log("No se encontraron usuarios");
          }
        }
      } catch (error) {
        console.log(error)
        swal({ title: 'Ha ocurrido un error',text:'Error en el servidor, por favor comuniquese con el equipo de soporte', icon: 'error' }) //mensaje generico
      }
    }
    fetchUsers()
    return () => {
      isMounted = false;
    };
  }, [])
  const columns = [
    {
      accessorKey: 'referencia',
      header: 'Referencia'
    },
    {
      accessorKey: 'usuario',
      header: 'Usuario'
    },
    {
      accessorKey: 'fecha',
      header: 'Fecha',
      Cell:({row}) =>(row.original.fecha.substring(0,10))
    },
    {
      accessorKey: 'tipo',
      header: 'Tipo'
    },
    {
      accessorKey: 'estatus',
      header: 'Estatus'
    }
  ]
  const muiTopToolbarProps = {
    sx: {
      height: '55px',
      '& .MuiIconButton-root svg': {//dentro del div seleccione MuiIconButton-root y dentro de ese componente traiga los svg y le agregue estilos
        fontSize: '29px'
      },
      '& .MuiInputBase-root': {//search
        fontSize: '17px'
      },
      '& .MuiInputBase-root svg': {//icono de lupa con una x
        fontSize: '22px'
      },
      '& .MuiButtonBase-root': {
        fontSize: '34px'
      },
    },
  };
  const muiBottomToolbarProps = {
    sx: {
      height: '60px',
      '& .MuiIconButton-root svg': {//iconos del header de la tabla
        fontSize: '22px'
      },
      '& .MuiTablePagination-selectLabel': {//Rows per page
        fontSize: '15px'
      },
      '& .MuiSelect-standard': {//arreglo
        fontSize: '15px',
        alignItems: 'center',
        display: 'flex',
      },
      '& .MuiSvgIcon-fontSizeMedium': {//icono del arreglo
        fontSize: '15px'
      },
      '& .MuiTablePagination-displayedRows': {//1-10 of 28
        fontSize: '15px'
      },
    }
  }
  const muiTableProps = {
    sx: {
      '& .MuiTableCell-root svg': {
        fontSize: '25px',
      },
      '& .MuiTableCell-root': {

      }
    }
  }
  const tableTheme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.5rem', //override to make tooltip font size larger, tooltips
              },
            },
          },
          MuiTableCell: {//celdas de la tabla
            styleOverrides: {
              root: {
                fontSize: '15px', // Ajusta el tamaño de fuente de las celdas de la tabla
              },
            },
          },
        },
        typography: {//edicion de enableEditing, la lista del arreglo(despues del "Rows per page") y el Show/Hide columns(tercer icono parte superior)
          button: {//"botones" de enableEditing
            fontSize: '12px',
          },
          body1: {//formulario y lista del arreglo, si se coloca "body2" no se aplican los estilos
            fontSize: '14px'
          },
          h6: {//titulo de enableEditing(dentro de la clase de MaterialReactTable la clase del titulo de enableEditing esta definido como 'MuiTypography-h6' por ende y segun mi logica se coloca 'h6')
            fontSize: '20px'
          }
        }
      }),
    [],
  );
  return (
    <>
      <AppBarReciclable></AppBarReciclable>
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) => (<h3 style={{ marginTop: '7px' }}>Mis Solicitudes</h3>)}
          localization={MRT_Localization_ES}
          enableColumnFilters={false} //filtering does not work with memoized table body
          enableGlobalFilter={false} //searching does not work with memoized table body
          data={data}
          columns={columns}
          initialState={{ pagination: { pageSize: 40 } }}
          muiTopToolbarProps={muiTopToolbarProps}
          muiBottomToolbarProps={muiBottomToolbarProps}
          muiTableProps={muiTableProps}
          muiTablePaperProps={{
            sx: {
              '& .MuiTypography-root': {
                fontSize: '64px'
              }
            }
          }}
          muiTableHeadCellProps={{//encabezado de la tabla
            //easier way to create media queries, no useMediaQuery hook needed.
            sx: {
              fontSize: {
                xs: '20px',
                sm: '11px',
                md: '12px',
                lg: '15px',
                xl: '18px',
              },
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between',//espacio entre los texto y los iconos
              },
              '& .Mui-TableHeadCell-Content svg': {
                fontSize: '24px',
              },
              '& .MuiInputBase-input': {
                fontSize: '17px'
              },
              color:
                '#5b95cf',
            },
          }}
        />
      </ThemeProvider>
    </>
  )
}

export default MisSolicitudes