import React, {Component} from 'react';
import {HttpNode} from '../../../axiosInstances';
import Chart from "chart.js/auto";
import zoomPlugin from 'chartjs-plugin-zoom';
//import 'chartjs-adapter-date-fns';
let chartPrueba;



class PriceChart extends Component {// Componente y/o estructura principal de grafica
    chart = null;
    state = {
        mobile: false// Configuracion de la grafica para dispositivos mobiles 
    }

    render() {
        return (
            <div className="panel-body">
                <div style={{width: "100%", height: '350px', padding: '0 ' + this.state.mobile ? '0' : '20px'}}>
                    <canvas
                        id="chartPrueba"
                    />
                </div>
            </div>
        )
    }
//----------------------------CONFIGURACION DE LA API----------------------------------//
    componentDidMount() {
        if (window.innerWidth <= 600) {
            this.setState({mobile: true})
        }
        this.mountChart();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.period !== this.props.period || prevProps.market !== this.props.market) {
            this.mountChart();
        }
    }    
    mountChart() {
        let market = this.props.market ? this.props.market : 71; // default 71
        let period = this.props.period ? this.props.period : '5d'; // default 5 days
        HttpNode.post(`seticap/api/graficos/graficoPrecios`, {
            mercado: market,
            moneda: 'USD/COP',
            periodo: period
        }).then(
            response => {
                const responsedata = response.data.data.data.data;  // this is hilarious
                const data1 = responsedata.datasets.length ? responsedata.datasets[0].data : []// Arreglo con datos de los precios del dolar 
                const labels1 = responsedata.datasets.length ? responsedata.labels : []// Arreglo con los datos de las fechas y horas     
                Chart.register(zoomPlugin)// Registro del plugin del zoom dentro de la grafica
                let ctx = document.getElementById("chartPrueba").getContext("2d");
                const gradientBg = ctx.createLinearGradient(0,0,0,300)// Constante para crear el relleno degradado de la grafica
                gradientBg.addColorStop(1, 'rgba(75, 192, 192, 0)');// Funcion para establecer desde que color comienza el degradado 
                gradientBg.addColorStop(0, 'rgba(75, 192, 192, 1)');// Funcion para establecer hasta que color finaliza el degradado 
                const data = {// Configuracion del eje y dela grafica
                    labels: labels1,// invocacion de la constante para mostrar las marcas en el eje X
                    datasets: [// objeto en el que se especifican los datos que se utilizarán para el gráfico
                        {
                            label: "Cotización USD/COP",// Nombre de la linea de la grafica
                            data: data1,// Invocacion de datos que se utilizaran para graficar la linea
                            borderWidth: 1.2,// Grosor de la linea
                            pointRadius: 0,// puntos de las lineas 
                            yAxisID: "y-axis-1",
                            tension: 0,// curvatura de la lineas
                            pointHitRadius: 10,// Punto alrededor de la linea a la hora de visualizar los precios
                            pointHoverRadius: 5,
                            type: "line",// Tipo de linea 
                            opacity: 0.3,// opacidad de la linea
                            fill: true,// relleno acitivado 
                            backgroundColor: gradientBg,// invocacion de la constante anteriormente creada 
                            borderColor: 'rgba(75, 192, 192, 1)',// Color de la linea de la grafica
                        }
                    ]
                };

                const config = {// Configuracion del eje X
                    type: "line",// Tipo de linea 
                    data,// invocacion de la configuracion anterior creada 
                    options: {//objeto en el que se especifican los datos que se utilizarán para el gráfico
                        layout: {// Configuracion de los bordes de la grafica
                            padding: {// bordes
                                left: 10, // Porcentaje de los bordes a la izquierda
                                right: 5// Porcentaje de los bordes a la izquierda
                            }
                        },
                        animation: true,// animacion de la grafica 
                        maintainAspectRatio: false,
                        scales: {// Escalas del eje X, configuracion mas precisa del eje X
                          x: {
                                                        beginAtZero: false,
                                                        max: labels1[labels1.length],// Se muestra la penultima fecha
                                                        grid: {// lineas del fondo de la grafica
                                                            display: false,// configuracion anterior desactivada
                                                            drawOnChartArea: false,
                                                            drawTicks: true,
                                                            drawBorder: false,
                                                            offset: false,
                                                        },
                                                        ticks: {// marcas que se muestran en el eje X
                                                            maxRotation: 90,// orientacion de las marcas
                                                            minRotation: 90,// minima orientacion de las marcas
                                                            maxTicksLimit: 20,// maxima cantidad de marcas que se muestran en la grafica
                                                            autoSkip: true,// Ajuste automatico de la cantidad de las marcas
                                                        },

                                                        label: {// TItulos
                                                            title: 'precios',// titulos asignado
                                                            display: true,// configuracion de titulos activados 
                                                        },
                                                      

                                                    },
                                                       y:[ //Eje Y de la grafica 
                                                       {
                                                       beginAtZero: false,
                                                       min: Math.min(...data1) - 5,// minimo de precios que se muestran 
                                                       max: Math.max(...data1) + 5,// maximo de precios que se muestra
                                                       grid:{// lineas del fondo de la grafica 
                                                       display: false// configuracion anterior desactivada 
                                                       },

                                                        }],


                                                     },



                        plugins: {// Configuracion de plugins de la grafica
                            zoom: {// plugin del zoom en la grafica 
                                pan: {// desplazamiento e intereacion de la grafica 
                                    enabled: true,// configuracion anterior activada
                                    mode: 'x',// aqui se establece en que eje se desplazara la grafica 
                                    speed: 3,// velocidad del movimiento 

                                },
                                zoom: {// configuracion especifica del zoom
                                    wheel: {// tipo de zoom, con la rueda del mouse
                                        enabled: true,// configuracion anterior activada 
                                    },
                                    limits: {// configuracion de los limites del zoom
                                        x: {min: labels1[0], max: labels1[labels1.length - 1]},

                                    },
                                    mode: 'x',// aqui se establece en que eje se desplazara la grafica 
                                   rangeMax: {
                                   				x: 20000
                                   			},
                                   			rangeMin: {
                                   				x: 1000
                                   			},
                                    speed: 0.1,
                                    sensitivity: 6
                                },

                            },
                            legend: {//
                                display: false,
                            },
                            tooltip: { // OPCION DERIVADA DE LA ANTERIOR
                                enabled: true
                            },
                            title: {
                                display: true,
                                text: 'Cotización USD/COP'
                            }


                        },

                    }, 
                   
                }


                if (chartPrueba) {
                    chartPrueba.destroy()
                }

                // render init block
                chartPrueba = new Chart(
                    document.getElementById('chartPrueba'),
                    config
                );

            })
    }
}

export default PriceChart;








