import React, { useState, useEffect, useRef } from "react";
import Footer from "../../../components/shared/Footer/Footer";
import { Formik, Form, Field } from "formik";
import Modal from "react-modal";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { HttpNode } from "../../../axiosInstances";
import { useHistory } from "react-router-dom";
import PreFooter from "../../../components/shared/PreFooter/PreFooter";
import swal from "sweetalert";
import HomeHeaders from "./Header/header"

//propiedades de la modal
const customStyles = {
  content: {
    width: "80%", // Cambiar el valor a un porcentaje más alto
    maxWidth: "600px", // Establecer un ancho máximo en píxeles
    height: "50%", // Cambiar el valor a un porcentaje más alto
    margin: "auto",
    borderRadius: "20px",
    alingItems: "auto"
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 999,
  },
};

const ImcSetfx = () => {
  const history = useHistory();
  // Estado de la modal iniciar sesión
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // Estado de la visibilidad de la contraseña
  const [showPassword, setShowPassword] = useState(false);
  // Credenciales inválidas
  const [authError, setAuthError] = useState(null);
  // Estado de entidadId
  //const [entidadId, setEntidadId] = useState(null);
  // Variable para verificar si el componente está montado
  const isMounted = useRef(true);//esto probablemente cuando se recargue la pagina el componente no esta montado y se redirige hacia el imc/login

  const handleLoginSubmit = async (values, { setSubmitting, setFieldError }) => {
    // Desestructura los valores de inicio de sesión del formulario
    const { entidad, usr_name, usr_pwd } = values;
    // Envía una solicitud POST a la API con los detalles de inicio de sesión
    try {
      const response = await HttpNode.post('/setfx/usuarios/login', {
        entidad,
        usr_name,
        usr_pwd,
      });



      console.log(response);
      if (isMounted.current) {
        // Si la autenticación fue exitosa, permita que el usuario acceda a la aplicación
        if (response.status === 200 && response.data.data) {
          console.log('Los datos son correctos', response.data.data);
          // Almacena el ID de entidad en el estado del componente
          //setEntidadId(response.data.data[0].entidad_id);

          // Imprime el valor de entidadId en la consola
          console.log('entidadId:', response.data.data[0].entidad_id);

          // Guarda el ID de entidad en el Local Storage
          localStorage.setItem('entidadId', response.data.data[0].entidad_id);
          console.log('entidadId guardado en Local Storage:', response.data.data[0].entidad_id);

          // Guarda el Nombre de entidad en el Local Storage y lo usamos en el archivo de AppBar.js
          localStorage.setItem('Nombre', response.data.data[0].nombre)
          console.log(response.data.data[0].nombre)

          // Verifica el campo cambiar_pwd
          const cambiarPwd = response.data.data[0].cambiar_pwd;
          console.log("Respuesta del api del login ", response.data.data[0].cambiar_pwd)
          if (cambiarPwd) {
            const idUsuario = response.data.data[0].id;
            localStorage.setItem("id_usr", response.data.data[0].id)
            //history.push(`/imc/ChangePassword/${idUsuario}/${nombreUsuario}/${correoUsuario}`);
            history.push(`/imc/ChangePassword/${idUsuario}`);
          } else {
            localStorage.setItem("id_usr", response.data.data[0].id)
            localStorage.setItem("entidad", response.data.data[0].entidad_nombre)

            // Resto del código para guardar en el Local Storage y manejar el flujo normal
            // ...

            history.push('/imc/MisUsuarios');
          }
        }
        else if (response.data.message) {
          console.log('Los datos son incorrectos', response.data.message);
          // Si la respuesta contiene un mensaje de error, actualiza el estado authError con ese mensaje
          setAuthError(response.data.message);
        } else {
          // De lo contrario, muestra un mensaje de error genérico
          setAuthError('Autenticación fallida. Por favor, verifique sus credenciales.');
        }
      }
    } catch (error) {
      if (isMounted.current) {
        if (error.response && error.response.data.message) {
          // Si hay un mensaje de error en la respuesta, utilízalo
          setAuthError(error.response.data.message);
          swal({ title: '!!!', text: 'Usuario no encontrado o inactivo', icon: 'warning' })//no es necesario el boton
          //console.log(error.response.data.message)
        } else {
          // De lo contrario, muestra un mensaje de error genérico
          setFieldError('usr_name', 'Se produjo un error al iniciar sesión');
        }
      }
    }
    setSubmitting(false);
  };
  // Función que cambia el estado de "showPassword" por "true" y "false"
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // Agregar useEffect para configurar el elemento de la aplicación
  useEffect(() => {
    Modal.setAppElement('body');

    // Función de limpieza para desmontar el componente
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div>
      <HomeHeaders />
      <div id="content-container">
        <div id="page-content">
          <div className="row" style={{ justifyContent: 'center' }}>{/*centrar boton */}
            <div className="col-6">
              <button
                className="btn btn-info btn-lg btn-block"
                type="button"
                onClick={() => setModalIsOpen(true)}
              >
                Iniciar Sesión
              </button>
            </div>
          </div>
          <Modal
            isOpen={modalIsOpen}
            contentLabel="Iniciar Sesión"
            style={customStyles}
            onRequestClose={() => setModalIsOpen(false)}
            shouldCloseOnEsc={true}
            overlayClickClose={true}
          >
            <h2>Iniciar Sesión</h2>
            <Formik
              initialValues={{ entidad: "", usr_name: "", usr_pwd: "" }}
              onSubmit={handleLoginSubmit}
            >
              {() => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="entidad">Entidad</label>
                    <Field
                      type="text"
                      name="entidad"
                      placeholder="Ingrese su entidad"
                      className="form-control"
                      minLength={2}
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="username">Nombre de usuario</label>
                    <Field
                      type="text"
                      name="usr_name"
                      placeholder="Ingrese su usuario"
                      className="form-control"
                      autoComplete="off"
                      minLength={3}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Clave</label>
                    <div className="password-input ">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="usr_pwd"
                        placeholder="Ingrese su clave"
                        className="form-control"
                        autoComplete="off"
                        minLength={3}
                        required
                      />
                      {showPassword ? (
                        <FaEyeSlash
                          className="password-icon"
                          onClick={toggleShowPassword}
                        />
                      ) : (
                        <FaEye
                          className="password-icon"
                          onClick={toggleShowPassword}
                        />
                      )}
                    </div>
                  </div>
                  <button
                    className="btn btn-success btn-lg btn-block"
                    type="submit"
                  >
                    Entrar
                  </button>
                  {/* mensaje que sale en pantalla cunado no coinciden los datos diligenciados con los de la base de datos */}
                  {authError && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "1rem",
                        border: "1px solid red",
                        padding: "0.5rem",
                      }}
                    >
                      {authError}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </Modal>
        </div>
        <PreFooter />
        <Footer />
      </div>
    </div>
  );
};
export default ImcSetfx;
