import React, { Component } from 'react';
import Layout from '../../../../../components/Auth/Layout';
import { Divider } from '@mui/material';
import Button from '../../../../../components/Nifty/UI/Button/Button';
import { Link } from 'react-router-dom';
import classes from '../../../../Auth/ChangePassword/ChangePassword.css';
import { HttpNode } from '../../../../../axiosInstances';
import swal from 'sweetalert'; // Asegúrate de importar el módulo swal

class ChangePasswordIMC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            repassword: '',
        };
        this.handleChangeV = this.handleChangeV.bind(this);
        this.handleChangeP = this.handleChangeP.bind(this);
    }

    MedidorSeguridad() {
        // ... (Tu función MedidorSeguridad aquí)
    }

    handleChangeV(event) {
        this.setState({ password: event.target.value });
    }

    handleChangeP(event) {
        this.setState({ repassword: event.target.value });
    }

    validate(values) {
        var validCharsPattern = /^[a-zA-Z0-9$#¡!¿?=&()*]+$/;
        var specialCharPattern = /[$#¡!¿?=&()*]/;
        var upperCasePattern = /[A-Z]/;

        if (!values.value || !values.revalue) {
            swal({
                title: "Campo Vacío",
                text: "Por favor, no dejar campo vacío de contraseña",
                icon: "warning"
            });
            return false;
        } else if (values.revalue === '' || values.value === '') {
            swal({
                title: "Contraseña Vacía",
                text: "El campo contraseña no puede estar vacío",
                icon: "warning"
            });
            return false;
        } else if (values.value.length < 12 || values.revalue.length < 12) {
            swal({
                title: "Contraseña muy Corta",
                text: "La contraseña no puede ser menor de 12 caracteres",
                icon: "error"
            });
            return false;
        } else if (values.value !== values.revalue) {
            swal({
                title: "Contraseñas Incompatibles",
                text: "Las contraseñas que ingresaste no coinciden. Por favor, inténtalo de nuevo.",
                icon: "error"
            });
            return false;
        } else if (!validCharsPattern.test(values.value)) {
            swal({
                title: "Caracteres no válidos",
                text: "La contraseña solo puede contener letras, números y los siguientes caracteres especiales: $ # ¡ ! ¿ ? % = & ( ) *",
                icon: "error"
            });
            return false;
        } else if (!specialCharPattern.test(values.value)) {
            swal({
                title: "Falta Caracter Especial",
                text: "La contraseña debe contener al menos uno de los siguientes caracteres especiales: $ # ¡ ! ¿ ? % = & ( ) *",
                icon: "error"
            });
            return false;
        } else if (!upperCasePattern.test(values.value)) {
            swal({
                title: "Falta una letra mayúscula",
                text: "La contraseña debe contener al menos una letra mayúscula",
                icon: "error"
            });
            return false;
        }
        return true;
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        // Acceder a los parámetros de la URL a través de match.params
        const { match } = this.props;
        const { id } = match.params;

        // Validar usando la función validate
        if (!this.validate({ value: this.state.password, revalue: this.state.repassword })) {
            // Si la validación no pasa, no continúes con el envío de la solicitud
            return;
        }

        // Construir el objeto de datos para enviar al servidor
        const data = {
            user_id: id,
            user_pwd: this.state.password,
        };

        try {
            // Enviar la solicitud al servidor
            const response = await HttpNode.post('/setfx/usuarios/renewpwd', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Manejar la respuesta del servidor
            console.log(response.data);

            // Redirigir al usuario a la página de usuarios
            window.location = "/imc/MisUsuarios";
        } catch (error) {
            console.error('Error al cambiar la contraseña', error);

            // Manejar diferentes tipos de errores si es posible
            if (error.response) {
                console.error('Respuesta del servidor:', error.response.data);
            } else if (error.request) {
                console.error('No se recibió respuesta del servidor');
            } else {
                console.error('Error durante la solicitud:', error.message);
            }
        }
    };

    render() {
        return (
            <Layout>
                <div className="panel-body">
                    <div className={['mar-ver', 'pad-btm', classes.TitleSection].join(' ')}>
                        <h1 className="h3">Tu contraseña ha expirado</h1>
                        <p className="pad-btm">Por favor, ingrese su nueva contraseña</p>
                    </div>
                    <form encType="multipart/form-data">
                        <div className="form-group ">
                            <input id="password" name="password" type="password" onKeyUp={this.MedidorSeguridad} onChange={this.handleChangeV} placeholder="Escriba Contraseña" className="form-control" />
                        </div>
                        <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                            <Divider id='hr1' sx={{ width: { xs: '34px', sm: '47px', md: "52px" }, display: "inline-block", margin: "0 5px", marginBottom: "32px" }} />
                            <Divider id='hr2' sx={{ width: { xs: '34px', sm: '47px', md: "52px" }, display: "inline-block", margin: "0 5px", marginBottom: "32px" }} />
                            <Divider id='hr3' sx={{ width: { xs: '34px', sm: '47px', md: "52px" }, display: "inline-block", margin: "0 5px", marginBottom: "32px" }} />
                            <Divider id='hr4' sx={{ width: { xs: '34px', sm: '47px', md: "52px" }, display: "inline-block", margin: "0 5px", marginBottom: "32px" }} />
                            <p id='p' style={{ marginTop: '-23px' }}></p>
                        </div>
                        <div className="form-group ">
                            <input id="repassword" name="repassword" type="password" onChange={this.handleChangeP} placeholder="Re-escriba Contraseña" className="form-control" />
                            <small id="passwordHelp" className="form-text text-muted">
                                *Doce caracteres como mínimo combinados con letras, números, sin espacios y los siguientes símbolos $ # ¡ ! ¿ ? % = & ( ) * + -.</small>
                        </div>
                        <div className="form-group text-right">
                            <Button type="danger" onClick={this.handleSubmit}>Cambiar contraseña</Button>
                        </div>
                    </form>
                    <div className="pad-top" style={{ textAlign: 'center' }}>
                        <Link to="/auth/login/" className={['btn-link', 'text-bold'].join(' ')}>Regresar al inicio de sesión</Link>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default ChangePasswordIMC;
