import React, {Component} from 'react';
import swal from 'sweetalert';
import Layout from '../../../components/Auth/Layout';
import classes from './ChangePassword.css';
import Button from '../../../components/Nifty/UI/Button/Button';
import {Link} from 'react-router-dom';
import {Httpphp} from '../../../axiosInstances';
import { Divider } from '@mui/material';
class ChangePassword extends Component{

    constructor(props) 
    {
        super(props);

        this.state = {value: '', revalue: '',token:'',token2:'', 
            user_id: localStorage.getItem("user_id"), 
            user_name: localStorage.getItem("username"), 
            user_email: localStorage.getItem("email"), 
            user_pwd: ''};
        this.PassInput = React.createRef();
        this.RePassInput = React.createRef();
        this.focus = this.focus.bind(this);
        this.handleChangeV = this.handleChangeV.bind(this);
        this.handleChangeP = this.handleChangeP.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if(this.props.location.search!==''){
            if(this.props.location.search.split('?').length>1){
                //preguntar si llego algun dato
                var data = this.props.location.search.split('?')[1];
                if(data.split('&').length>1){
                    //preguntar si llego la division de los dos datos
                    if(data.split('&')[0].split('=').length>1 && data.split('&')[1].split('=').length>1){
                        //preguntar si los datos no sean nullos (que se tenga la division, pero un o los dos no tiene datos)
                        if(data.split('&')[0].split('=')[1]!=="" && data.split('&')[1].split('=')[1]!==""){
                            //preguntar si los datos que trae los tokens no son vacias, guardar y validar por php si son correctas
                            //si son correctas no cambia la pagina y al cambiar la clave correctamente crea la sesion
                            // y redirige
                            if(data.split('&')[0].split('=')[0]==='token' && data.split('&')[1].split('=')[0]==='token2'){
                                //aca post
                                this.setState({token:data.split('&')[0].split('=')[1]});
                                this.setState({token2:data.split('&')[1].split('=')[1]});
                                //console.log(data.split('&')[0].split('=')[1]+" "+data.split('&')[1].split('=')[1]);
                                //debugger;                             
                            }else{
                                //nombre datos diferentes
                                //console.log("nombre datos diferentes");
                                window.location="/"; 
                            }
                            
                        }else{
                            //uno o los dos tokens son vacios y redirigir al index
                            //console.log("no vino uno de los tokens");
                            window.location="/"; 
                        }
                        
                    }else{
                        //uno o los dos tokens estan null y redirigir al index
                        //console.log("no vino uno de los tokens");
                        window.location="/"; 
                    }
        
                }else{
                    //trae 1 token y y redirigir al index
                    //console.log("datos traidos incompletos falta");
                    window.location="/"; 
                }
            }
            else{
                //no trajo ningun dato y redirigir al index
                //console.log("ningun datos traidos ");
                window.location="/"; 
            }
            
        }else{
            //*** window.location="/"; 
        }     
    }

    MedidorSeguridad()
    {
        let $password = document.getElementById("password")
        let $p = document.getElementById("p")
        let $hr1 = document.getElementById("hr1")
        let $hr2 = document.getElementById("hr2")
        let $hr3 = document.getElementById("hr3")
        let $hr4 = document.getElementById("hr4")        
        let probar = $password.value

        if(probar.length<12)
        {
            $p.textContent = "Contraseña pequeña"
            $p.style.color="#FF5733"
            $p.style.fontFamily="Arial"
            if(window.screen.width <= 500){
                $p.style.fontSize="18px"
            }
            else{
                $p.style.fontSize="20px"
            }
            $password.style.borderColor="#FF5733"
            $hr1.style.setProperty("background-color","#FF5733")
            $hr2.style.setProperty("background-color","#eee")
        }
        else if(probar.length>=12 && probar.length <=15){
            $p.textContent = "Contraseña mediana"
            $p.style.color="#ffb224"
            $password.style.borderColor="#ffb224"
            $hr1.style.setProperty("background-color","#ffb224")
            $hr2.style.setProperty("background-color","#ffb224")
            $hr3.style.setProperty("background-color","#eee")
        }
        else if(probar.length>15 && probar.length<18){
            $p.textContent = "Contraseña grande"
            $p.style.color="#00C1C1"
            $password.style.borderColor="#00C1C1"
            $hr1.style.setProperty("background-color","#00C1C1")
            $hr2.style.setProperty("background-color","#00C1C1")
            $hr3.style.setProperty("background-color","#00C1C1")
            $hr4.style.setProperty("background-color","#eee")
        }
        else if(probar.length>=18){
            $p.textContent = "Contraseña Segura"
            $p.style.color="#12C100"
            $password.style.borderColor="#12C100"
            $hr1.style.setProperty("background-color","#12C100")
            $hr2.style.setProperty("background-color","#12C100")
            $hr3.style.setProperty("background-color","#12C100")
            $hr4.style.setProperty("background-color","#12C100")
        }
        //---------restaurar color "original"
        if(probar.length<=0){
            $hr1.style.setProperty("background-color","#eee")
            $hr2.style.setProperty("background-color","#eee")
            $hr3.style.setProperty("background-color","#eee")
            $hr4.style.setProperty("background-color","#eee")
            $password.style.borderColor="#ccc"
            $p.textContent = ""
        }
    }


    handleChangeV(event) 
    {
        this.setState({value: event.target.value});
    }
    
    handleChangeP(event) 
    {
        this.setState({revalue: event.target.value});
    }

    handleSubmit=(event) => 
    {
        event.preventDefault();
   	    //el evento cuando se unde el boton
        if(this.validate(this.state)){
            let user_id = this.state.user_id; // localStorage.getItem("user_id"); 
            let user_name = this.state.user_name;   // localStorage.getItem("username"); 
            let user_email = this.state.user_email; //localStorage.getItem("email"); 
            let user_pwd = this.state.value;
            try 
            {
                let formData = new FormData();
                formData.append('user_id', user_id);
                formData.append('user_pwd', user_pwd);
                formData.append('user_name', user_name);
                formData.append('user_email', user_email);

                Httpphp.post("/php-prueba/RenewPassword.php",
                    formData,
                    {
                        headers: {  /*"Content-type": "multipart/form-data", */ },
                    }
                )
                .then(res => {
                    if(res.data.toString().includes('OK')) {
                        /*
                        swal({
                            title: '¡El cambio ha sido exitoso!',
                            text: res.message,
                            closeOnClickOutside: false,
                            timer: 10000,
                            icon: "success",                                
                            button: 'Ok'}); 
                        */
                        window.location = "/dashboard/";
                    } else  
                    {
                        swal(res.message);
                    }
                })
                .catch(err => {
                    swal({
                        title: 'Pedimos disculpas',
                        text: err.message,
                        button: 'Acepto'});
                    })
            } 
            catch (error) 
            { }
        } 
        else {
           this.focus();
        }
    }

    focus() {
        this.PassInput.current.focus();
        this.RePassInput.current.focus();
    }

    validate(values) {
    var validCharsPattern = /^[a-zA-Z0-9$#¡!¿?=&()*]+$/;
    var specialCharPattern = /[$#¡!¿?=&()*]/;
    var upperCasePattern = /[A-Z]/;

    if (!values.value || !values.revalue) {
        swal({
            title: "Campo Vacío",
            text: "Por favor no dejar campo vacio de contraseña",
            icon: "warning"
        });
        return false;
    } else if (values.revalue==='' || values.value===''){
        swal({
            title: "Contraseña Vacía",
            text: "El campo contraseña no puede estar vacio",
            icon: "warning"
        });
        return false;
    } else if(values.value.length<12 || values.revalue.length<12){
        swal({
            title: "Contraseña muy Corta",
            text: "La contraseña no puede ser menor de 12 caracteres",
            icon: "error"
        });
        return false;
    } else if(values.value!==values.revalue){
        swal({
            title: "Contraseñas Incompatibles",
            text: "Las contraseñas que ingresaste no coinciden. Por favor, inténtalo de nuevo.",
            icon: "error"
        });
        return false;
    } else if (!validCharsPattern.test(values.value)){
        swal({
            title: "Caracteres no válidos",
            text: "La contraseña solo puede contener letras, números y los siguientes caracteres especiales: $ # ¡ ! ¿ ? % = & ( ) *",
            icon: "error"
        });
        return false;
    } else if (!specialCharPattern.test(values.value)){
        swal({
            title: "Falta Caracter Especial",
            text: "La contraseña debe contener al menos uno de los siguientes caracteres especiales: $ # ¡ ! ¿ ? % = & ( ) *",
            icon: "error"
        });
        return false;
    } else if (!upperCasePattern.test(values.value)){
        swal({
            title: "Falta una letra mayúscula",
            text: "La contraseña debe contener al menos una letra mayúscula",
            icon: "error"
        });
        return false;
    }
    return true;
}

    render(){

        return(
            <Layout>
                <div className="panel-body">
               <div className={['mar-ver', 'pad-btm', classes.TitleSection].join(' ')}>
                  <h1 className="h3">Tu contraseña ha expirado</h1>
                  <p className="pad-btm">Por favor, ingrese su nueva contraseña</p>
               </div>
                  <form encType="multipart/form-data">
                      <div className="form-group ">
                          <input id="password" name="password" type="password" ref={this.PassInput} value={this.state.value} onKeyUp={this.MedidorSeguridad} onChange={this.handleChangeV} placeholder="Escriba Contraseña"className="form-control" required/> 
                      </div>
                      <div style={{whiteSpace:"nowrap",textAlign:"center"}}>
                            <Divider id='hr1' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <Divider id='hr2' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <Divider id='hr3' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <Divider id='hr4' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <p id='p' style={{marginTop:'-23px'}}></p> 
                      </div>
                      <div className="form-group ">
                          <input id="repassword" name="repassword" type="password" ref={this.RePassInput} value={this.state.revalue} onChange={this.handleChangeP} placeholder="Re-escriba Contraseña"className="form-control" required/>
                          <small id="passwordHelp" class="form-text text-muted">*Doce caracteres como mínimo combinados con letras, números, sin espacios y los siguientes simbolos $ # ¡ ! ¿ ? % = & ( ) * + -.</small>
                      </div>
                      <div className="form-group text-right">
                          <Button onClick={this.handleSubmit} type="danger">Cambiar contraseña</Button>
                      </div>
                  </form>
                  <div className="pad-top" style={{textAlign: 'center'}}>
                  <Link to="/auth/login/" className={['btn-link', 'text-bold'].join(' ')}>Regresar al inicio de sesión</Link>
                  </div>
              </div>
            </Layout>
        )
    }
}

export default ChangePassword;