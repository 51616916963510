import React, { Component } from 'react';
import swal from 'sweetalert';
import Layout from '../../../components/Auth/Layout';
import classes from './CreateAccount.css';
import { Link } from 'react-router-dom';
import { withFormik, Field } from 'formik';
import axios from 'axios';

class CreateAccount extends Component {

	validarCampo = (e, campo) => {
		const inputValue = e.target.value;
		const regex = /^[^$#¡!¿?%=&()*\\/,;'"0-9]+$/; // Expresión regular que permite letras mayúsculas, letras minúsculas y números

		if (inputValue === '') {
			// Si el valor del campo es una cadena vacía, borra el campo
			this.props.setFieldValue(campo, inputValue, true);
		} else if (!regex.test(inputValue)) {
			this.props.setFieldError(campo, 'El campo no debe contener caracteres especiales');
		} else {
			this.props.setFieldError(campo, ''); // Eliminar el error si el valor es válido
			// Actualiza el valor de entrada del campo sólo si pasa la validación
			this.props.setFieldValue(campo, inputValue, true);
		}
	}

//validacion para el numero de documento 

validarNumeroIdentificacion = (e, campo) => {
    const inputValue = e.target.value;
    const regex = /^[0-9]+$/; // Expresión regular que sólo permite números

    if (inputValue === '') {
        // Si el valor del campo es una cadena vacía, borra el campo
        this.props.setFieldValue(campo, inputValue, true);
    } else if (!regex.test(inputValue)) {
        this.props.setFieldError(campo, 'El campo sólo debe contener números');
    } else {
        this.props.setFieldError(campo, ''); // Eliminar el error si el valor es válido
        // Actualiza el valor de entrada del campo sólo si pasa la validación
        this.props.setFieldValue(campo, inputValue, true);
    }
}



	render() {
		return (
			<Layout register>
				<div className="panel-body">
					<div className={["mar-ver", "pad-btm", classes.TitleSection].join(' ')}>
						<h1 className="h3">Crear una nueva cuenta</h1>
						<p>¡Únete a SET-ICAP | FX!. Vamos a configurar tu cuenta</p>
					</div>
					<form onSubmit={this.props.handleSubmit}>
						<div className="row">
							<div className="col-sm-6">
								<div className="form-group">
									<Field
										type="text"
										name="firstName"
										placeholder="Nombre completo"
										className="form-control"
										minLength={4}
										maxLength="50"
										onChange={(e) => this.validarCampo(e, 'firstName')}
										required></Field>
								</div>
								<div className="form-group">
									<Field
										type="text"
										name="lastName"
										placeholder="Apellidos"
										className="form-control"
										minLength={3}
										maxLength="50"
										onChange={(e) => this.validarCampo(e, 'lastName')}
										required></Field>
								</div>
								<div className="form-group">
									<Field
										type="text"
										name="enterprise"
										placeholder="Empresa"
										className="form-control"
										minLength={3}
										maxLength="25"
										onChange={(e) => this.validarCampo(e, 'enterprise')}
										required></Field>
								</div>
								<div className="form-group">
									<Field component="select" name="legalPerson" className={["form-control", classes.tamañoForm].join(' ')} required>
										<option value="natural">Persona natural</option>
										<option value="juridica">Persona jurídica</option>
									</Field>
								</div>
								<div className="form-group">
									<Field name="identification" component="select" className={["form-control", classes.tamañoForm].join(' ')} required>
										<option value="C">Cédula</option>
										<option value="N">Nit</option>
									</Field>
								</div>
								<div className="form-group">
									<Field name="product" component="select" className={["form-control", classes.tamañoForm].join(' ')} required>
										<option value="dolar">Dolar</option>
										<option value="analytics">Analytics</option>
										<option value="analisisTecnico">Análisis técnico</option>
									</Field>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="form-group">
									<Field
										name="identificationNumber"
										type="text" 
										min='11111'
										maxLength="25"
										placeholder="Número de identificación"
										className={["form-control",
											classes.ident].join(' ')}
											onChange={(e) => this.validarNumeroIdentificacion(e, 'identificationNumber')}
										required></Field>
								</div>
								<div className="form-group">
									<Field name="email" 
									placeholder="Email" 
									className="form-control" 
									maxLength="50"
									type='email' minLength={11} 
									required>

									</Field>
								</div>
								<div className="form-group">
									<Field name="country" component="select" className={["form-control", classes.tamañoForm].join(' ')} required>
										<option value="">País</option>
										<option value="Argentina">Argentina</option>
										<option value="Alemania">Alemania</option>
										<option value="Aruba">Aruba</option>
										<option value="Australia">Australia</option>
										<option value="Bolivia">Bolivia</option>
										<option value="Brasil">Brasil</option>
										<option value="Islas Virgenes Britanicas">Islas Virgenes Britanicas</option>
										<option value="Canada">Canada</option>
										<option value="Chile">Chile</option>
										<option value="China">China</option>
										<option value="Colombia">Colombia</option>
										<option value="Costa Rica">Costa Rica</option>
										<option value="Republica Dominicana">Republica Dominicana</option>
										<option value="Ecuador">Ecuador</option>
										<option value="Emiratos Arabes Unidos">Emiratos Arabes Unidos</option>
										<option value="España">España</option>
										<option value="Estados Unidos">Estados Unidos</option>
										<option value="Francia">Francia</option>
										<option value="Guatemala">Guatemala</option>
										<option value="Hungria">Hungria</option>
										<option value="Italia">Italia</option>
										<option value="Mexico">Mexico</option>
										<option value="Nueva Zelanda">Nueva Zelanda</option>
										<option value="Paises Bajos">Paises Bajos</option>
										<option value="Panama">Panama</option>
										<option value="Peru">Peru</option>
										<option value="Puerto Rico">Puerto Rico</option>
										<option value="Uruguay">Uruguay</option>
										<option value="Venezuela">Venezuela</option>
										<option value="Otro">Otro</option>
									</Field>
								</div>
								<div className="form-group">
									<Field 
									name="city" 
									type="text" 
									placeholder="Ciudad" 
									className="form-control" 
									maxLength="25"
									minLength={4} 
									onChange={(e) => this.validarCampo(e, 'city')}
									required></Field>
								</div>
								<div className="form-group">
									<Field 
									name="telephone" 
									type="number" 
									placeholder="Teléfono" 
									min='1111111' 
									maxLength="25"
									className={["form-control", classes.ident].join(' ')} 
									required>

									</Field>
								</div>
							</div>
						</div>
						<div className="checkbox pad-btm text-left">
							<label>
								<Field type="checkbox" name="termsandconds" checked={this.props.values.termsandconds} required></Field>
								Acepto <a href="https://set-icap.com/terminos-y-condiciones.pdf" className="btn-link text-bold">Terminos y Condiciones.</a>
							</label>
						</div>
						<div className="checkbox pad-btm text-left">
							<label>
								<Field type="checkbox" name="termsandconds2" checked={this.props.values.termsandconds2} required></Field>
								Acepto las  <a href="https://set-icap.com/Descargas/Autorización Tratamiento de Datos personales Set-Icap Fx.pdf" className="btn-link text-bold">Políticas Tratamiento de Datos personales SET-ICAP FX</a>
							</label>
						</div>
						<div className="checkbox pad-btm text-left">
							<label>
								<Field type="checkbox" name="termsandconds2" checked={this.props.values.termsandconds2} required></Field>
								Acepto las  <a href="https://set-icap.com/Descargas/Autorización Tratamiento de Datos personales Set-Icap Securities.pdf" className="btn-link text-bold">Políticas Tratamiento de Datos personales SET-ICAP SECURITIES</a>
							</label>
						</div>
						<button className="btn btn-primary btn-lg btn-block" type="submit">Registrarse</button>
					</form>
				</div>
				<div className="pad-all">
					<p>¿Ya tienes una cuenta? <Link to="/auth/login/" className="btn-link">Inicia sesión</Link></p>
				</div>
			</Layout>
		)
	}
}

const handleSubmit = (values, resetForm) => {
	var day = new Date().toISOString().slice(0, 10);
	var d = new Date();
	var convert_day = day + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}
	axios.post('https://hooks.zapier.com/hooks/catch/5088624/o3mrvn8/silent/', {
		"full_name": values.firstName,
		"last_name": values.lastName,
		"identification": values.identificationNumber,
		"email": values.email,
		"enterprise": values.enterprise,
		"country": values.country,
		"type": values.legalPerson,
		"city": values.city,
		"identification_type": values.identification == 'C' ? 'Cédula' : 'Nit',
		"product": values.product,
		"cellphone": values.telephone,
		"date": convert_day
	}, config).then(() => {
		swal({
			title: "¡Gracias!",
			text: "Hemos recibido sus datos",
			icon: "success",
		})
		resetForm();
	});
}

const mapPropsToValues = (props) => {
	return {
		firstName: props.firstName || '',
		lastName: props.lastName || '',
		enterprise: props.enterprise || '',
		legalPerson: props.legalPerson || 'natural',
		identification: props.identification || 'C',
		identificationNumber: props.identificationNumber || '',
		email: props.email || '',
		country: props.country || '',
		city: props.city || '',
		telephone: props.telephone || '',
		product: props.product || 'dolar',
		termsandconds: props.termsandconds || true,
		termsandconds2: props.termsandconds2 || true,
		handleSubmit: handleSubmit
	}
}

export default withFormik({ mapPropsToValues: mapPropsToValues, handleSubmit: (values, { resetForm }) => handleSubmit(values, resetForm) })(CreateAccount)
