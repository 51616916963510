import axios from 'axios';

var TOKEN_DELAY = "U2FsdGVkX19DSC/UgOTmKKFT71EmflbBX3tiljxmNpmMcLRZSwNlQCKxUXoN3QpJQ/f7lOA8X41400fnR5G7wA==";
var TOKEN_APP = TOKEN_DELAY;

export function getAppToken(ambiente) {
    if (TOKEN_APP != TOKEN_DELAY) {
        return TOKEN_APP;
    }

    var useUrl = "https://proxy.set-icap.com/seticap/api/users/login";

    switch (ambiente)
    {
        case 1:
            useUrl = "https://proxy.icap.com.co/seticap/api/users/login";
            break;
        case 2:
            useUrl = "https://proxy.setfx.co/seticap/api/users/login";
            break;
    }

    try {
        const response = axios.create({
            baseURL: useUrl,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        response.post(useUrl, {
            usr: process.env.DOLAR_USER,
            pwd: process.env.DOLAR_PWD,
        }).then (response => { 
            if(response.data.status === 'success') {
                TOKEN_APP = response.data.user.token;
                localStorage.setItem("token_app", response.data.user.token);
                navigator.clipboard.writeText(TOKEN_APP);
                return TOKEN_APP;
            }            
        });            
    } catch (err) { }

    var lToken = localStorage.getItem("token_app");
    lToken = ((typeof lToken !== "undefined") && (lToken !== null)) ? lToken : TOKEN_APP;
    return lToken;
 }


// produccion
// -------------------------------------------------
/*
export const Http = axios.create({
    baseURL: "https://proxy.set-icap.com/",
    headers: {
        "Authorization": getAppToken(0), 
    },
    timeout: 10000
});

export const AuthHttp = axios.create({
    baseURL: "https://proxy.set-icap.com/",
    headers: {
        "Authorization": getAppToken(0), 
    },
});

export const HttpNode = axios.create({
    baseURL: "https://proxy.set-icap.com/",
    headers: {
        "Authorization": getAppToken(0), 
    },
    timeout: 10000
})

export const Httpphp = axios.create({
    baseURL: "https://dolar.set-icap.com/",
    headers: {
        "Authorization": getAppToken(0), 
        "Accept": "application/json, text/plain, /",
        "Content-type": "multipart/form-data"
    },
})
*/

// contigencia
// -------------------------------------------------
export const Http = axios.create({
    baseURL: "https://proxy.icap.com.co/",
    headers: {
        "Authorization": getAppToken(1), 
    },
    timeout: 10000
});

export const AuthHttp = axios.create({
    baseURL: "https://proxy.icap.com.co/",
    headers: {
        "Authorization": getAppToken(1), 
    },
});

export const HttpNode = axios.create({
    baseURL: "https://proxy.icap.com.co/",
    headers: {
        "Authorization": getAppToken(1), 
    },
    timeout: 10000
})

export const Httpphp = axios.create({
    baseURL: "https://dolar.icap.com.co/",
    headers: {
        "Authorization": getAppToken(1), 
        "Accept": "application/json, text/plain, /",
        "Content-type": "multipart/form-data"
    },
})

// pruebas
// -------------------------------------------------
/*
export const Http = axios.create({
    baseURL: "https://proxy.setfx.co/",
    headers: {
        "Authorization": getAppToken(2), 
    },
    timeout: 10000
});

export const AuthHttp = axios.create({
    baseURL: "https://proxy.setfx.co/",
    headers: {
        "Authorization": getAppToken(2), 
    },
});

export const HttpNode = axios.create({
    baseURL: "https://proxy.setfx.co/",
    headers: {
        "Authorization": getAppToken(2), 
    },
    timeout: 10000
})

export const Httpphp = axios.create({
    baseURL: "https://dolar.setfx.co/",
    headers: {
        "Authorization": getAppToken(2), 
        "Accept": "application/json, text/plain, /",
        "Content-type": "multipart/form-data"
    },
})
*/
// -------------------------------------------------


AuthHttp.interceptors.request.use( config => {
    const token = localStorage.getItem('token');
    if(token !== ''){
        config.headers.Authorization = `${token}`;
    }
    return config;
})

AuthHttp.interceptors.response.use( config => {
    return config;
})

// APP TOKEN
export default {
    getAppToken: getAppToken
};
