import React from 'react';
import Sidebar from 'react-sidebar';
import { FaBars } from 'react-icons/fa';
import {FaUserFriends} from 'react-icons/fa';
import {FaClipboardList} from 'react-icons/fa';
import {FaToolbox} from 'react-icons/fa';
import Divider from '@material-ui/core/Divider';
import { Link } from "react-router-dom";


class MySidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  render() {
    return (
        <Sidebar sx={{backgoroundColor:"#25476a"}}
        sidebar={
          <div>
           <center style={{ backgoroundColor: "#cfe2f3"}}>
                
              <h1 style={{ fontSize: "22px",padding:"0.5cm", color:"#0078ac"}}>Menu</h1>
                
            </center>
            <Divider/>
           {/* <h4 style={{ fontSize: "17px", fontWeight:"inherit", padding: "0.3cm" }}> <FaAddressBook/> Mis datos personales</h4><Divider/> */}
           {/* definimos la ruta por medio del archivo App.js y en este archivo esceibimos la ruta que definimos y lo colocamos en la etiqueta "LINK" */}
            <Link to="/imc/MisUsuarios">
              <h4 style={{ fontSize: "17px", fontWeight: "inherit", padding: "0.3cm" }}> <FaUserFriends/> Mis usuarios</h4><Divider/>
            </Link>
            <Link to="/imc/MisProductos">
              <h4 style={{ fontSize: "17px", fontWeight:"inherit", padding: "0.3cm" }}> <FaToolbox/> Mis productos</h4><Divider/>
            </Link>
            <Link to="/imc/MisSolicitudes">
              <h4 style={{ fontSize: "17px", fontWeight:"inherit", padding: "0.3cm" }}> <FaClipboardList/> Mis solicitudes</h4><Divider/>
            </Link>


          {/* <Link to='/imc/login'></Link> */}
          </div>
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{ sidebar: { background: "white", width:"6cm",position:'fixed'} }}
      >
        <button style={{ width:"5%", height:"6.8%", color:"white" , backgroundColor:"transparent", borderColor:"transparent", padding:"0.5cm"}}onClick={() => this.onSetSidebarOpen(true)}>
        <FaBars/>
        </button>
      </Sidebar>
    );
  }
}


export default MySidebar;