import React from "react";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min"

const PrivateRoute=({component: Component, ...rest})=>{
    // let auht;
    // auht = null //retorna al login
    // auht = true //retorna al componente

    //si el valor de la variable "entityId" es null este retorna a la ruta especificada dentro de la etiqueta "Redirect" que en este caso es "/imc/login" pero si el valor es true retorna hacia el componente
    const entityId = localStorage.getItem('entidadId');
    return(
        <Route {...rest}>{entityId?<Component/>:<Redirect to="/imc/login"/>}</Route>
    )
}
//este componente se exporta hacia el archivo App.js

export default PrivateRoute