import { Box, } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@mui/material';
import { Button } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useState } from 'react'
import { HttpNode } from '../../../../../axiosInstances'
import { useParams, Link } from 'react-router-dom';
import swal from 'sweetalert';
import { MRT_Localization_ES } from 'material-react-table/locales/es'; //lenguaje


function Detalles() {
  const { productId } = useParams();
  console.log('Product ID from URL:', productId);
  const [details, setDetails] = useState([]);
  useEffect(() => {
    let isMounted = true;
  
    async function fetchDetails() {
      try {
        const entityId = localStorage.getItem('entidadId');
        const response = await HttpNode.get(`/setfx/entidades/${entityId}/suscripciones?paquete=${productId}`);
        const detailsData = response.data.data;
  
        if (isMounted) {
          if (detailsData && detailsData.length > 0) {
            // Formatear el precio antes de establecerlo en el estado
         const formattedDetails = detailsData.map(detail => ({
          ...detail,
          precio: parseFloat(detail.precio).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        }));
        setDetails(formattedDetails);
            console.log("datos de los detalles por producto ", formattedDetails);
            // Guardamos paquete_id en el localStorage
            localStorage.setItem("paquete_id", formattedDetails[0].paquete_id); // Este dato se utiliza en subdetalles en la función de envío
            console.log("id del producto: ", formattedDetails[0].paquete_id);
            console.log("precio TEFA: ", formattedDetails[0].precio);
            localStorage.setItem("numero", formattedDetails[0].numero); // Este dato se utiliza en subdetalles en la función de envío
            console.log("numero: ", formattedDetails[0].numero);
          } else {
            console.log("No se encontraron detalles");
          }
        }
      } catch (error) {
        console.error(error);
        return swal({ title: 'Ha ocurrido un error', text: 'Error en el servidor, por favor comuníquese con el equipo de soporte', icon: 'error' });
      }
    }
  
    fetchDetails();
  
    return () => {
      isMounted = false;
    };
  }, [productId]);
  
  function numeroL(rowData) {
    console.log("Numero de fila seleccionada", rowData.numero)
    localStorage.setItem("NumFila", rowData.numero)//este dato se utiliza en subdetalles en la funcion de envio
  }
  const [search, setSearch] = useState('');
  const handleChangeFiltro = (event) => {
    setSearch(event.target.value);
  };
  const filtro = details.filter((buscar) => {
    if (buscar.total_disponibles.toUpperCase().includes(search.toUpperCase())) {
      return true;
    }
    return false;
  });

  // const formatPrecio = (precio) => {
  //   // Verificar si el precio es un número válido
  //   const precioNumber = parseFloat(precio);
  //  console.log("este es el precio que esta trayendo el api ",precio);
  //   if (!isNaN(precioNumber)) {
  //     // Formatear el precio con separadores de miles
  //     const precioFormateado = precioNumber.toLocaleString('en-US');
   
  //     // Devolver el precio formateado
  //     return precioFormateado;
  //   }
   
  //   // Si el precio no es válido, devolver el valor original
  //   return precio;
  //  };
   
  
  
  const columns = [
    {
      accessorKey: 'codigo',
      header: 'Paquete',
    },
    {
      accessorKey: 'numero',
      header: 'Suscripción'
    },
    {
      accessorKey: 'total_pantallas',
      header: 'Pantallas'
    },
    {
      accessorKey: 'total_disponibles',
      header: 'Disponibles'
    },
    {
      accessorKey: 'precio',
      header: 'Precio Mensual',
      //Cell: ({ value }) => formatPrecio(value),
    },
    {
      header: 'Administrar',
      Cell: ({ row }) =>
      (<div>
        <Link to="/imc/MisProductos/Detalles/SubDetalles/:userId">
          <Button variant='contained' color='success' onClick={() => numeroL(row.original)}>Administrar</Button>
        </Link>
      </div>)
    }
  ]
  const muiTopToolbarProps = {
    sx: {
      height: '55px',
      '& .MuiIconButton-root svg': {//dentro del div seleccione MuiIconButton-root y dentro de ese componente traiga los svg y le agregue estilos
        fontSize: '29px'
      },
      '& .MuiInputBase-root': {//search
        fontSize: '17px'
      },
      '& .MuiInputBase-root svg': {//icono de lupa con una x
        fontSize: '22px'
      },
      '& .MuiButtonBase-root': {
        fontSize: '34px'
      },
    },
  };
  const muiBottomToolbarProps = {
    sx: {
      height: '60px',
      '& .MuiIconButton-root svg': {//iconos del header de la tabla
        fontSize: '22px'
      },
      '& .MuiTablePagination-selectLabel': {//Rows per page
        fontSize: '15px'
      },
      '& .MuiSelect-standard': {//arreglo
        fontSize: '15px',
        alignItems: 'center',
        display: 'flex',
      },
      '& .MuiSvgIcon-fontSizeMedium': {//icono del arreglo
        fontSize: '15px'
      },
      '& .MuiTablePagination-displayedRows': {//1-10 of 28
        fontSize: '15px'
      },
    }
  }
  const muiTableProps = {
    sx: {
      '& .MuiTableCell-root svg': {
        fontSize: '25px',
      },
      '& .MuiTableCell-root': {

      }
    }
  }
  const tableTheme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.5rem', //override to make tooltip font size larger, tooltips
              },
            },
          },
          MuiTableCell: {//celdas de la tabla
            styleOverrides: {
              root: {
                fontSize: '15px', // Ajusta el tamaño de fuente de las celdas de la tabla
              },
            },
          },
        },
        typography: {//edicion de enableEditing, la lista del arreglo(despues del "Rows per page") y el Show/Hide columns(tercer icono parte superior)
          button: {//"botones" de enableEditing
            fontSize: '12px',
          },
          body1: {//formulario y lista del arreglo, si se coloca "body2" no se aplican los estilos
            fontSize: '14px'
          },
          h6: {//titulo de enableEditing(dentro de la clase de MaterialReactTable la clase del titulo de enableEditing esta definido como 'MuiTypography-h6' por ende y segun mi logica se coloca 'h6')
            fontSize: '20px'
          }
        }
      }),
    [],
  );
  return (
    <>
      <Box sx={{ m: 8 }} /> {/*esto es para separar las tablas y no queden tan pegadas pero tambien se puede colocar en tableTheme las propiedades y/o lineas de codigo para modificar el posicionamiendo de esta tabla*/}
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) => (<h3 style={{ marginTop: '7px' }}>Detalles</h3>)}
          enableColumnFilters={false} //filtering does not work with memoized table body
          //enableGlobalFilter={false} //searching does not work with memoized table body
          data={filtro}
          muiSearchTextFieldProps={{
            placeholder: `Buscar por Pantallas Disponibles`,
            sx: { minWidth: '370px' },
            variant: 'outlined',
            size: 'small',
            value: search,
            type:'number',//solo dejar escrbir numeros
            onChange: handleChangeFiltro,
           //onClick: jeje,
          }}
          columns={columns}
          muiTopToolbarProps={muiTopToolbarProps}
          muiBottomToolbarProps={muiBottomToolbarProps}
          muiTableProps={muiTableProps}
          positionActionsColumn='last'
          initialState={{ pagination: { pageSize: 5 } }}
          localization={MRT_Localization_ES}
          enableColumnOrdering={{
            color: '#5b95cf',
          }}
          muiTablePaperProps={{
            sx: {
              '& .MuiTypography-root': {
                fontSize: '64px'
              }
            }
          }}
          muiTableHeadCellProps={{//encabezado de la tabla
            //easier way to create media queries, no useMediaQuery hook needed.
            sx: {
              fontSize: {
                xs: '20px',
                sm: '11px',
                md: '12px',
                lg: '15px',
                xl: '18px',
              },
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between',//espacio entre los texto y los iconos
              },
              '& .Mui-TableHeadCell-Content svg': {
                fontSize: '24px',
              },
              '& .MuiInputBase-input': {
                fontSize: '17px'
              },
              color:
                '#5b95cf',
            },
          }}
        >
        </MaterialReactTable>
      </ThemeProvider>
    </>
  )
}
export default Detalles

