import React, {Component} from 'react';
import {HttpNode} from '../../../axiosInstances';
import ApexChart from 'react-apexcharts'

class BollingerChart extends React.Component{
  constructor(props){//Metodo principal del componente de la grafica
  super(props);//invocacion del metodo anterior 
  this.state = {// Estado del metodo el cual se evocará cuando se renderize la grafica 
    series: [{// Configuracion de el eje Y con los datos denominados 'series'
      name: 'Cotización USD/COP',// Nombre de la linea principal de la grafica 
      data: [],//Arreglo de los datos del eje Y
    }, {
      name: 'Media móvil (20)',// Nombre de la linea media movil (20) de la grafica 
      data: [],//Arreglo de los datos del eje Y
  }, {
      name: 'Media móvil (20)-2 Desv Est',// Nombre de la linea media movil (20)-2 de la grafica 
      data: [],//Arreglo de los datos del eje Y
  },
  {
    name: 'Media móvil (20)+2 Desv Est',// Nombre de la linea media movil (20)+2 de la grafica 
    data: [],//Arreglo de los datos del eje Y
},
    ],
    options : {// Configuracion del eje X y demas datos de la grafica
      series: [],// Es necesario invocar la anterior configuracion
      chart: {// Configuracion general de la grafica
      height: 350,//Tamaño de la grafica
      type: 'line', // Relleno de las lineas de la grafica
      dropShadow: { // Configuracion de la sombra 
        enabled: true,//Desactivar o Activar la anterior configuracion
        color: '#000',// Color de la sombra
        top: 16,// Porcentaje de la sombra en la parte superior
        left: 5,// Porcentaje de la sombra en la parte izquierda
        blur: 8,// Porcentaje del difumidado de la sombra
        opacity: 0.2// opacidad de la sombra
      },
      toolbar: {// Configuracion de la barra de herramientas que se ubican en la parte superior de derecha de la grafica 
        show: true,// Desactivar o Activar la anterior configuracion
        tools: {// Configuracion de cada herramienta de la barra
          download: false // desactiva el botón de descarga
        }
      },
    },
    yaxis: {// Eje Y de la grafica
      grid: {// Lineas del fondo de la grafica 
        show: false  // aquí establecemos la propiedad "show" en "false" para ocultar las líneas del fondo del eje Y
      }
    },
    xaxis:{//Configuracion del eje X de la grafica
      type: 'category',// Tipo de configuracion de las marcas del eje X, en este caso es de agrupacion 
            labels: {// Marcas del eje X
              rotate: -90// Orientacion de las marcas del eje X, para este caso se ubican de forma completamente horizontal 
            },
            tickAmount: 15// Cantidad de marcas que se muestran en la grafica 
      },
    colors: ['rgba(75, 192, 192, 1)',"#0077AC", "#C82B2B","#000000"],// Colores de las lineas de la grafica

    dataLabels: {// Subtitulos del eje X
      enabled: false// desactiva la anterior configuracion 
    },
    stroke: {// Configuracion de las lineas de la grafica
      curve: ['straight','straight','straight','straight'],// Curvatura de la lineas en este caso es rigido
      width:  [1.5,0.5,1,1],// Grosor de la lineas de la grafica 
      color: ['#a68537','#a68537','#a68537']// Colores las lineas de la grafica
    },
    yaxis: {//Configuracion del eje Y de la grafica
      tooltip: {// Caja que muestra el precio y las fechas desplegable
        enabled: true// Activar la anterior configuracion 
      }
    }, 
    tooltip: {// Caja que muestra el precio y las fechas desplegable
      yx: { //Eje X y Y de la grafica
        format: 'dd/MM/yy '// Formato de fecha en la que se mostrara en la grafica 
      },
      style: {// Estilos de la caja desplegable 
        fontSize: '12px',// Tamaño de la letra de la caja 
        width: '100px',// Altura de la caja 
        height: '50px'// Ancho de la caja 
      }
    },
    }
}
  }
  
//-----------------------------------------CONFIGURACION DE LA API-------------------------------------------//

  componentDidMount(){
    if(window.innerWidth <= 600){
      this.setState({mobile: true})
    }
      this.mountChart();
  }
  componentDidUpdate(prevProps){
      if(prevProps.period !== this.props.period || prevProps.market !== this.props.market){
          this.mountChart();
      }
  }
  mountChart(){
    if(this.chart != null){
        this.chart.destroy();
    }
    let market = this.props.market ? this.props.market : 71; // default 71
    let period = this.props.period ? this.props.period : '5d'; // default 5 days
    let sma = this.state.sma ? this.state.sma : 20;  // default 20
    let desv = this.state.desv ? this.state.desv : 2; // default 2

    HttpNode.post(`seticap/api/graficos/graficoBollinger`, {
            mercado: market, 
            moneda: 'USD/COP', 
            periodo: period,
            sma: sma,                           
            desv: desv
        }).then(
        response => {
    

            const responsedata = response.data.data.data.data;  // this is hilarious
            
            const quotedata = responsedata.datasets.length ? responsedata.datasets[0].data : [] //Arreglo con los datos del precio 
            const average_low = responsedata.datasets.length > 1 ? responsedata.datasets[1].data : []// Arreglo con los datos de la media movil (8)
            const average_hight = responsedata.datasets.length > 2 ? responsedata.datasets[2].data : []// Arreglo con los datos de la 'Media móvil (20)-2 Desv Est'
            const average_hight_two = responsedata.datasets.length > 2 ? responsedata.datasets[3].data : []// Arreglo con los datos de la 'Media móvil (20)+2 Desv Est'        
            const average_low_label = responsedata.datasets.length > 1 ? responsedata.datasets[1].label : '';
            const average_hight_label = responsedata.datasets.length > 2 ? responsedata.datasets[2].label : '';
            const average_hight_label_two = responsedata.datasets.length > 2 ? responsedata.datasets[3].label : '';
            const labels = responsedata.datasets.length ? responsedata.labels: []// Arreglo con las Fechas/horas    
            const uniqueData = labels.map((value, index) => `${value}:${index+1}`);// Nueva constante con el arreglo de fechas/horas mapeado con un dato unico para hacerlo distinto 

//--------------------NOTA-------------------------//
//                                                  |
//  Es importante tener en cuenta que para realizar |
//  la configuracion de estas graficas con la       |
//  libreria de ApexChart se debe manejar el        |
//  formato X y Y, en el cual se ubicaran en la     |
//  variable X las marcas, fechas, horas y en la    |
//  variable Y los datos que se desean graficar     | 
//  adicionalmente se debe manejar la estructura    |
//  de mapeo (.map) para agregar los datos a las    |
//  nuevas constantes a utilizar, como en el        |
//  siguiente ejemplo:                              |
//                                                  |
//-------------------------------------------------//

const quotedataDATA = labels.map((label, index) => ({//Configuracion de la constante para crear las nuevas Fechas/horas
  x: labels[index],// Configuracion del eje X con los datos del arreglo fechar/horas
  y: quotedata[index]// Configuracion del eje Y con los datos del precio de dolar 

}));

const average_lowDATA = labels.map((label, index) => ({//Configuracion de la constante para crear las nuevas Fechas/horas
  x: labels[index],// Configuracion del eje X con los datos del arreglo fechar/horas
  y: average_low[index]// Configuracion del eje Y con los datos de la media movil (8)

}));
const average_hightDATA = labels.map((label, index) => ({//Configuracion de la constante para crear las nuevas Fechas/horas
  x: labels[index],// Configuracion del eje X con los datos del arreglo fechar/horas
  y: average_hight[index]// Configuracion del eje Y con los datos de la Media móvil (20)-2 Desv Est

}));
const average_hight_twoDATA = labels.map((label, index) => ({//Configuracion de la constante para crear las nuevas Fechas/horas
  x: uniqueData[index],// Configuracion del eje X con los datos del arreglo fechar/horas
  y: average_hight_two[index]// Configuracion del eje Y con los datos de la Media móvil (20)+2 Desv Est

}));
       
            this.setState({// Configuracion del estado anteriormente establecido en el constructor
              chart: {//Configuracion general de la grafica
                height: 350,// Tamaño de la grafica 
                type: 'line',// tipo de grafico para la linea principal del precio del dolar              
                yaxis: {// Configuracion del eje Y
                  gridLines: {// Lineas del fondo de la grafica
                    show: false  // aquí establecemos la propiedad "show" en "false" para ocultar las líneas del fondo del eje Y
                  }
                },
                grid: {
                  borderColor: '#e7e7e7',
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                  },
                },
              },
                series: [{// Configuracion de los datos principales de la grafica
                    name: 'Cotización USD/COP',// Nombre la linea del precio del dolar 
                    data: quotedataDATA// Invocacion de la constante anteriormente declarada para la cotizacion de dolar 
                  },{
                    name:  'Media móvil (8)',// Nombre la line la de la Media móvil (8)
                    data: average_lowDATA// Invocacion de la constante anteriormente declarada para la cotizacion de la Media móvil (8) 
                  },{
                    name: 'Media móvil (20)-2 Desv Est',// Nombre la linea de la Media móvil (20)-2 Desv Est
                    data: average_hightDATA// Invocacion de la constante anteriormente declarada para la Media móvil (20)-2 Desv Est
                  },
                  {
                    name: 'Media móvil (20)+2 Desv Est',// Nombre la linea del precio de la Media móvil (20)+2 Desv Est
                    data: average_hight_twoDATA // Invocacion de la constante anteriormente declarada para la Media móvil (20)+2 Desv Est
                  }, 
                  ],
                  xaxis:{//Eje X de la grafica
                    type: 'category',// Tipo de configuracion de las marcas del eje X, en este caso es de agrupacion 
                    labels: {// Marcas del eje X
                      rotate: -90//Orientacion de las marcas del eje X, para este caso se ubican de forma completamente horizontal 
                    },
                    tickAmount: 12// Cantidad de marcas que se muestran en la grafica 
                    },
             })  
        }
    )
}

//--------------------NOTA-------------------------//
//                                                  |
//  Para renderizar la grafica es necesario usar un |
//  contendedor con la libreria Apexchart           |
//  posteriormente usar los estados que declaramos  |
//  al incio del codigo 'this.state', y usar las    |
//  dos principales configuraciones de la grafica   |
//  series y options, adicionalmente si se desea    | 
//  realizar configuraciones de estilo.             |
//                                                  |
//-------------------------------------------------//

render() {
  return (
<div id="BollingerChart">
<ApexChart options={this.state.options} 
series={this.state.series} 
type="line"  
height={375} />
</div>
  );
}
}
export default BollingerChart;