import React, { useState, Component } from 'react';
import ReactApexChart from 'react-apexcharts'
import {HttpNode} from '../../../axiosInstances';
require('../../../utils/FinantialChart');

//-----------------------------------------------------------------------------//

class CandleStick extends React.Component {                     
    constructor(props){//Metodo principal del componente de la grafica
    super(props);//invocacion del metodo anterior 
    this.state = {// Estado del metodo el cual se evocará cuando se renderize la grafica 
        series: [{// Configuracion de el eje Y con los datos denominados 'series'
            name: 'candle',// Nombre de la linea principal de la grafica 
            data:[]//Arreglo de los datos del eje Y
          }],
        options: {// Configuracion del eje X y demas datos de la grafica
          plotOptions: {
            _candlestick: {
              colors: {// Colores de la velas de la grafica
             upward: '#0077ac',// Color de las velas alcistas
             downward: '#c82b2b',// Color de las velas bajistas
              },
              shadow: {// Configuracion de la sombra 
                enabled: true,//Desactivar o Activar la anterior configuracion
                color: '#000',// Color de la sombra
                top: 0,// Porcentaje de la sombra en la parte superior
                left: 0,// Porcentaje de la sombra en la parte izquierda
                blur: 2,// Porcentaje del difumidado de la sombra
                opacity: 0.2// opacidad de la sombra
              },
              wick: {//Color de las mechas de las velas
                useFillColor: true// Activar el color de velas 
              }
            },
            get candlestick() {
              return this._candlestick;
            },
            set candlestick(value) {
              this._candlestick = value;
            },
            chart: {// Configuracion general de la grafica 
              trackpadCompatible: true,// Compatibilidad con dispositivos mobiles 
              shadow: {// Sombra de las velas
                enabled: true,// 
                color: '#000',//
                top: 0,// Porcentaje de la sombra en la parte superior
                left: 0,// Porcentaje de la sombre en la parte izquierda
                blur: 2,//Porcentaje del difuminado 
                opacity: 0.2// Porcentaje de la opacidad de la grafica 
              },
              toolbar: {// Configuracion de la barra de herramientas que se ubican en la parte superior de derecha de la grafica 
                show: true,// Desactivar o Activar la anterior configuracion
                offsetX: 0,
                offsetY: 0,
                tools: {// Configuracion de cada herramienta de la barra
                  download: false,// desactiva el botón de descarga
                  selection: true,// activa el botón de seleccionar
                  zoom: true,// activa el botón de zoom
                  zoomin: true,// activa el botón de zoom 
                  zoomout: true,// activa el botón de zoom
                  pan: true,// activa el botón de movilidad
                  reset: true | '<img src="/static/icons/reset.png" width="20">',// iconos de reseteo
                  customIcons: []// arreglo con los iconos de la barra de herramientas
                },
              },
          }
        },
          series: [],// Es necesario invocar la anterior configuracion
          chart: {// Configuracion general de la grafica
            height: 400,//Tamaño de la grafica
            type: 'candlestick',// Tipo de configuracion de velas japonesas 
            toolbar: {// Configuracion de la barra de herramientas que se ubican en la parte superior de derecha de la grafica 
              show: true,// Desactivar o Activar la anterior configuracion
              tools: {// Configuracion de cada herramienta de la barra
                download: false // desactiva el botón de descarga
              }
            },
          },
          annotations: {
            xaxis: [// configuracion eje X de la grafica 
         
              {
                x: '14:00',// formato en el que se presentara las marcas de las horas
                type: 'datetime',// tipo de fecha
                borderColor: '#00E396',// color de los bordes
                label: {// marcas del eje X
                  borderColor: '#00E396',// color de los bordes
                  style: {// estilo de las marcas
                    fontSize: '12px',// tamaño de la fuente 
                    color: '#fff',// color de la fuente
                    background: '#00E396'// fondo de la fuente 
                  },
                  orientation: 'horizontal',// orientacion de la tipografica
                  offsetY: 7,// cantidad de marcas 
                  datetimeFormatter: {// formato en el que se presentara
                    hour: 'HH:mm'
                  }
                },
                
                type: 'datetime',// tipo de fecha
                
              }
            ]
          },
          xaxis: {// configuracion eje X de la grafica 
            type: 'category',// Tipo de configuracion de las marcas del eje X, en este caso es de agrupacion 
            labels: {// Marcas del eje X
              rotate: -90// Orientacion de las marcas del eje X, para este caso se ubican de forma completamente horizontal 
            }, 
            tickAmount: 12// Cantidad de marcas que se muestran en la grafica
          },
          tooltip: {// Caja que muestra el precio y las fechas desplegable
            enabled: true,// Activar la anterior configuracion 
          },
          
          yaxis: {//Configuracion del eje Y
            tooltip: {// Caja que muestra el precio y las fechas desplegable
              enabled: true// Activar la anterior configuracion 
            }
          },
          toolbar: {// Configuracion de la barra de herramientas que se ubican en la parte superior de derecha de la grafica 
            show: false,// Configuracion de cada herramienta de la barra
            tools:{// Configuracion de cada herramienta de la barra 
              download: false,// desactiva el botón de descarga
            }          
          },       
        },
      };
    }
//------------------------------------CONFIGURACION DE LA API------------------------------------------//
    componentDidMount(){
      if(window.innerWidth <= 600){
          this.setState({mobile: true})
      }
      this.mountChart();}
      
    componentDidUpdate(prevProps){
      if(prevProps.period !== this.props.period || prevProps.market !== this.props.market){
          this.mountChart();
      }
  }
    async mountChart(){
      if(this.chart != null){
        this.chart.destroy()
    }
 
    let market = this.props.market ? this.props.market : 71; // default 71
    let period = this.props.period ? this.props.period : '5d'; // default 5 days
    HttpNode.post(`seticap/api/graficos/graficoVelas`, {
      mercado: market,
      moneda: 'USD/COP',
      periodo: period,
      bandera: window.innerWidth <= 600 ? 1 : 0}).then(response =>{
      const responsedata= response.data.data.data.data

//--------------------NOTA-------------------------//
//                                                  |
//  Es importante tener en cuenta que para realizar |
//  la configuracion de estas graficas con la       |
//  libreria de ApexChart se debe manejar el        |
//  formato X y Y, en el cual se ubicaran en la     |
//  variable X las marcas, fechas, horas y en la    |
//  variable Y los datos que se desean graficar     | 
//  adicionalmente se debe manejar la estructura    |
//  de mapeo (.map) para agregar los datos a las    |
//  nuevas constantes a utilizar, como en el        |
//  siguiente ejemplo:                              |
//                                                  |
//-------------------------------------------------//

//---------------------NOTA------------------------//
//                                                  |
//  La API arroja un solo dato y teniendo en cuenta |
//  que este tipo de graficos requiere de cuatro    |
//  datos para graficar la vela (O.H.C.L) se debe   |
//  tener conocimiento de el mapeo de datos, para   |
//  separar y sustraer cada uno de ellos.           |
//  Posteriormente se debe crear una constante la   | 
//  cual retornará una variable Y donde se estable  |
//  ceran los cuatro precios y en la variable X se  |
//  estableceran las fechas, a continuacion se mues-|
//  tra un ejemplo:                                 |
//                                                  |
//-------------------------------------------------//

      const data = responsedata.datasets[0].data.map((elem, index) => {// En este arreglo se encuentra los datos de los precios del dolar mapeado o con la fecha incluida
        elem.t = [(responsedata.labels[index]).valueOf()];
        return elem
    })
   

      const seriesData= data.map(item=>{// Declaracion de constante de los datos y fecha de las velas
      return{
        x: item.t,// variable X con las fechas mapeadas 
        y: [item.o, item.h, item.l, item.c]// variable Y con los datos que las velas requieren 
      };
      });

        this.setState({// Configuracion del estado anteriormente establecido en el constructor
          series: [{// Configuracion de los datos principales de la grafica
            name: 'candle',
            data: seriesData,
          }],
          xaxis: { //Eje X de la grafica 
            toolbar: {// Configuracion de la barra de herramientas que se ubican en la parte superior de derecha de la grafica 
              show: false,// Desactivar o Activar la anterior configuracion
              download: false,// desactiva el botón de descarga
            },  
            },
            chart: {// Configuracion general de la grafica
              trackpadCompatible: true, //Compatibilidad con dispositivos mobiles
              shadow: {// Sombra de las velas
                enabled: true,// 
                color: '#000',//
                top: 0,// Porcentaje de la sombra en la parte superior
                left: 0,// Porcentaje de la sombre en la parte izquierda
                blur: 2,//Porcentaje del difuminado 
                opacity: 0.2// Porcentaje de la opacidad de la grafica 
              },
              
          },
          xaxis: {// configuracion eje X de la grafica 
            type: 'category',// Tipo de configuracion de las marcas del eje X, en este caso es de agrupacion 
            labels: {// Marcas del eje X
              rotate: -90// Orientacion de las marcas del eje X, para este caso se ubican de forma completamente horizontal 
            }, 
          
          },
          }
        )
      })
      
    }  

//--------------------NOTA-------------------------//
//                                                  |
//  Para renderizar la grafica es necesario usar un |
//  contendedor con la libreria ReactApexchart      |
//  posteriormente usar los estados que declaramos  |
//  al incio del codigo 'this.state', y usar las    |
//  dos principales configuraciones de la grafica   |
//  series y options, adicionalmente si se desea    | 
//  realizar configuraciones de estilo.             |
//                                                  |
//-------------------------------------------------//

    render() {
        return (
    <div id="CandleStick">
  <ReactApexChart options={this.state.options} 
  series={this.state.series}
   type="candlestick" 
   height={375} />
  </div>
        );
      }
};

export default CandleStick;