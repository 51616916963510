import React, {Component} from "react";
import classes from "./DolarSpot.css";
import Chart from "chart.js/auto";
//import 'chartjs-adapter-date-fns';
import {HttpNode} from "../../../axiosInstances";
import {connect} from 'react-redux';
import zoomPlugin from 'chartjs-plugin-zoom';

let myChart;
let myChart2;

class DolarSpotChart extends Component {
    state = {
        labels: [],
        dolarValueData: [],
        mountUSD: [],
        loaded: false,
        mobile: false
    };


    //Con plugins
    buildChart = () => {
        const dataDelay = this.props.dataDelay ? this.props.dataDelay : '15';
        const today = new Date();
        const rt = parseInt(this.props.dataDelay);
        const url = parseInt(this.props.dataDelay) === 0 ? 'seticap/api/graficos/graficoMonedaRT/' : 'seticap/api/graficos/graficoMoneda/';
        HttpNode.post(url, {
            "fecha": `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${(today.getDate()).toString().padStart(2, '0')}`,
            "moneda": 1,
            "delay": dataDelay,
            "market": this.props ? this.props.market : 71
        }).then(
            response => {
                let datos = null;
                if (rt === 0) {
                    datos = response.data.result[0].datos_grafico_moneda_mercado_rt;
                } else {
                    datos = response.data.result[0].datos_grafico_moneda_mercado;
                }

                datos = datos
                    .replace(/'/g, '"')
                    .replace(/\d{2}:\d{2}(:\d{2})*/gi, function (x) {
                        return '"' + x + '"'
                    })
                    .replace(/data:/g, '"data":')
                    .replace(/label:/g, '"label":')
                    .replace(/type:/g, '"type":')
                    .replace(/labels:/g, '"labels":')
                    .replace(/datasets:/g, '"datasets":')

                datos = JSON.parse("{" + datos + "}").data;


                let dates = datos.labels; //horas//
                let numbers = datos.datasets[0].data; //precios//
                let volume = datos.datasets[1].data;



                if (dates.length < 1) {
                    dates = []
                    numbers = [0]
                    volume = []
                }

                Chart.register(zoomPlugin)

                //CONFIGURACION BASICA DE LA GRAFICA DIVIDIDA EN 2 PARTES

                // PARTE 1:
                // CONFIGURACION DE DATOS DENTRO DE LA GRAFICA Y SUS FUTURAS FUNCIONES

                const data = {
                    labels: dates, // DEFINICION DE DATOS QUE VAN SER USADOS COMO EJE X
                    datasets: [{
                        label: 'Precios', // TITULO QUE VAN A TENER DICHOS DATOS DENTRO DE LA GRAFICA
                        data: numbers, // DEFINICION DE DATOS QUE VAN A SER GRAFICADOS Y DEFINIDOS EL EJE Y
                        fill: { // PROPIEDADES QUE TENDRIA EL FONDO DE DICHOS GRAFICOS (con o sin color de fondo, propiedades de este mismo)
                            target: {
                                value: numbers[0]
                            },
                            below: (context) => { // FUNCION QUE DEFINE EL COLOR DE LA GRAFICA SEGUN SEA MAYOR O MENOR DEL PRIMER DATO DEL DIA
                                const chart = context.chart;
                                const {ctx, chartArea, data, scales} = chart; // DECLARACION DE PROPIEDADES QUE VAN SER USADAS EN LA FUNCION
                                if (!chartArea) {
                                    return null;
                                }
                                return belowGradient(ctx, chartArea, data, scales) // INVOCACION DE DICHA FUNCION
                            },
                            above: (context) => {  // FUNCION QUE DEFINE EL COLOR DE LA GRAFICA SEGUN SEA MAYOR O MENOR DEL PRIMER DATO DEL DIA
                                const chart = context.chart;
                                const {ctx, chartArea, data, scales} = chart; // DECLARACION DE PROPIEDADES QUE VAN A SER USADAS EN LA FUNCION
                                if (!chartArea) {
                                    return null;
                                }
                                return aboveGradient(ctx, chartArea, data, scales) // INVOCACION DE DICHA FUNCION
                            },
                        },
                        borderColor: (context) => {  // FUNCION QUE DEFINE EL COLOR DE LA LINEA SEGUN SEA MAYOR O MENOR DEL PRIMER DATO DEL DIA
                            const chart = context.chart;
                            const {ctx, chartArea, data, scales} = chart; // DECLARACION DE PROPIEDADES QUE VAN A SER USADAS EN LA FUNCION
                            if (!chartArea) {
                                return null;
                            }
                            return getGradient(ctx, chartArea, data, scales) // INVOCACION DE DICHA FUNCION
                        },
                        tension: 0, // CURVATURA DE LAS LINEAS DEL GRAFICO (ENTRE MAYOR SEA EL NUMERO MENOS RECTA SERA LA LINEA)
                        pointRadius: 0, // TAMAÑO DE PUNTO QUE SEÑALA CADA DATO GRAFICADO
                        pointHitRadius: 0, // TAMAÑO HOVER DEL PUNTO ANTES MENCIONADO QUE MUESTRA UN DATO ESPECIFICO DE LA GRAFICA
                        pointHoverRadius: 0, // RELACIONADO DIRECTAMENTE CON LA PROPIEDAD ANTERIOR, DEFINE EL LIMITE DE DICHA REACCION DEL HOVER
                        borderWidth: 1
                    }, {
                        //DEFINICION Y CONFIGURACION DE LA GRAFICA "SECUNDARIA" O GRAFICA DE FONDO
                        label: 'Montos', // // TITULO QUE VAN A TENER LOS DATOS GRAFICADOS EN ESTA
                        type: 'bar', // DEFINICION DEL TIPO DE GRAFICA QUE VA A SER ESTA (line,bar,area,etc)
                        data: volume, // DEFINICION DE DATOS QUE VAN A SER GRAFICADOS Y DEFINIDOS EL EJE Y
                        pointRadius: 0, // TAMAÑO DE PUNTO QUE SEÑALA CADA DATO GRAFICADO
                        pointHitRadius: 0, // TAMAÑO HOVER DEL PUNTO ANTES MENCIONADO QUE MUESTRA UN DATO ESPECIFICO DE LA GRAFICA
                        pointHoverRadius: 0, // RELACIONADO DIRECTAMENTE CON LA PROPIEDAD ANTERIOR, DEFINE EL LIMITE DE DICHA REACCION DEL HOVER
                        yAxisID: 'volume', // NOMBRE QUE VAN A TENER DICHO CONJUNTO DE DATOS PARA SUS FUTURAS INVOCACIONES
                        fill: false //PROPIEDADES QUE TENDRIA EL FONDO DE DICHOS GRAFICOS (con o sin color de fondo, propiedades de este mismo)
                    }]
                };

                // PLUGINS PERSONALIZADOS
                // LLAMADOS ASI YA QUE SON FUNCIONES QUE LA GRAFICA VA A RECIBIR COMO PROPIEDAD INICIAL O QUE SIN AYUDA DE CODIGO EXTERNO FUNCIONARIA
                // SE DIFERENCIA DE LAS FUNCIONES NORMALES YA QUE TIENEN PRIORIDAD A LA HORA DEL RENDERIZADO CON LA GRAFICA ADEMAS DE PODER DEFINIR SI
                // ESTA SE RENDERIZA ANTES O DESPUES DE LA MISMA


                // LINEA PUNTEADA QUE DEFINE EL PRIMER DATO O COMO ABRIO EL MERCADO ESE DIA
                const dottedLine = {
                    id: 'dottedLine', // DEFINICION DEL PLUGIN PARA SU INVOCACION FUTURA
                    beforeDatasetsDraw(chart, args, pluginsOptions) { // DECLARACION DE LAS PROPIEDADES QUE VA USAR LA FUNCION Y SI SE CARGA ANTES O DESPUES DE LA GRAFICA
                        const {ctx, data, chartArea: {left, right, width}, scales: {x, y}} = chart; // DECLARACION DE PROPIEDADES QUE VAN A SER USADAS EN LA FUNCION

                        const startingPoint = data.datasets[0].data[0];
                        // VARIABLE QUE DEFINE EL VALOR DEL PRIMER DATO PARA FUTUROS USOS. EN ESTE CASO INVOCA LA VARIABLE DATA
                        // Y A SU VEZ INGRESA A UNA ARRAY CREADO DENTRO DE ESTA.


                        // CTX SE REFIERE A UNA LIBRERIA QUE USA HTML PARA REALIZAR ALGUNOS GRAFICOS SENCILLOS DENTRO DE ALGUNAS CANVAS
                        // DISPONE DE UNA LIBRERIA UN POCO EXTENSA ADEMAS DE VARIOS FOROS DONDE SE DOCUMENTA SU FUNCIONAMIENTO Y
                        // ALGUNOS EJEMPLOS.

                        ctx.save(); // GUARDA LAS PROPIEDADES DEL CANVAS ANTES DE EMPEZAR A DIBUJAR, A SU VEZ SI ALGUN DATO A CAMBIADO, BORRAR EL ANTERIOR Y DEJA EL ACTUAL COMO PRINCIPAL
                        ctx.beginPath(); // DEFINE EL INICIO DE LAS PROPIEDADES DEL DIBUJO
                        ctx.lineWidth = 1; // TAMAÑO DE LA LINEA
                        ctx.setLineDash([1, 5]); // ESPACIO EN BLANCO O VACIO ENTRE CADA SEGMENTO DE LA LINEA
                        ctx.strokeStyle = 'rgba(102, 120, 120, 1)'; // COLOR DE LA LINEA
                        ctx.moveTo(left, y.getPixelForValue(startingPoint)); // POSICION EN LA QUE INICIA LA LINEA
                        ctx.lineTo(right, y.getPixelForValue(startingPoint)); // POSICION EN LA QUE TERMINA LA LINEA
                        ctx.stroke();
                        ctx.closePath(); // DEFINE EL FINAL DE LAS PROPIEDADES DEL DIBUJO

                        ctx.beginPath(); // DEFINE EL INICIO DE LAS PROPIEDADES DEL DIBUJO
                        ctx.fillStyle = 'rgba(102, 102, 102, 1)'; // AL SER GRAFICO "COMPLETO" COMO ES UN CUADRO, PERMITE DEFINIR EL COLO DEL FONDO
                        ctx.fillRect(0, y.getPixelForValue(startingPoint) - 10, left, 20) // TAMAÑO DEL DIBUJO DEFINIDO POR POSICIONES (left,top,right,bottom)
                        ctx.closePath(); // DEFINE EL FINAL DE LAS PROPIEDADES DEL DIBUJO

                        ctx.font = '12px sans-serif'; // TAMAÑO DEL TEXTO DENTRO DE LA CAJA
                        ctx.fillStyle = 'white'; // COLOR DEL TEXTO
                        ctx.textBaseline = 'middle'; // POSICION DEL TEXTO
                        ctx.textAlign = 'center'; // ALINEACION DE LOS TEXTOS DENTRO DE ESTA
                        ctx.fillText(startingPoint.toFixed(2), left / 2, y.getPixelForValue(startingPoint)); // CONTENIDO DE LA CAJA (contenido, posicion)

                    }
                }

                // PARTE 2
                //CONFIGURACION GENERAL DE LA GRAFICA Y FUNCIONES DE ESTA

                const config = {
                    type: 'line', // TIPO DE GRAFICA
                    data, // INVOCACION A LA CONFIGURACION DE LA PARTE 1
                    options: {
                        layout: { // PROPIEDADES DE POSICION DE LA GRAFICA DENTRO DEL CANVAS
                            padding: {
                                left: 10,
                                right: 5
                            }
                        },
                        animation: false, // ANIMACION DE LOS DATOS AL MOMENTO DE RENDERIZAR LA GRAFICA (se recimienda dejarlo en false para evitar la que la grafica se sobrecarge y reaccione de manera lenta)
                        maintainAspectRatio: false, // PROPIEDAD QUE PERMITE QUE LA GRAFICA RECIBA Y SE DEFINA POR LOS TAMAÑOS DATOS EN EL ARCHIVO CSS
                        scales: { // CONFIGURACION DE LOS 2 EJES DE LA GRAFICA
                            x: {
                                min: dates[0], // VALOR MINIMO DESDE EL QUE SE EMPIEZAN A VER LOS DATOS
                                max: dates[dates.length], // VALOR MAXIMO HASTA EL QUE SE VEN LOS DATOS
                                grid: { // CONFIGURACION  DE LA CUADRICULA QUE SE PUEDE VER AL TENER VARIOS DATOS EN LA TABLA
                                    drawOnChartArea: false, // ACTIVAR O DESACTIVAR LA CUADRICULA DE ESTE EJE
                                    drawTicks: true, // ACTIVAR O DESACTIVAR LOS TITULOS DE CADA DATO EN EL EJE X
                                    drawBorder: false,
                                    offset: false,
                                },
                                ticks:{ // CONFIGURACION DE TITULOS DEL EJE X
                                    maxRotation: 90, // ROTACION DEL TITULO DEPENDIENDO DEL TAMAÑO DE LA GRAFICA
                                    minRotation: 90  // ROTACION DEL TITULO DEPENDIENDO DEL TAMAÑO DE LA GRAFICA
                                }
                            },
                            y: {
                                beginAtZero: false, // VALOR INICIAL DEL EJE Y
                                min: Math.min(...numbers) - 5,  // VALOR MINIMO DESDE EL QUE SE EMPIEZAN A VER LOS DATOS
                                // EN ESTE CASO EL "MATH" VALIDA CUAL ES EL VALOR MAS BAJO DE TODO EL ARRAY
                                max: Math.max(...numbers) + 5 // VALOR MAXIMO HASTA EL QUE SE VEN LOS DATOS
                                // EN ESTE CASO EL "MATH" VALIDA CUAL ES EL VALOR MAS BAJO DE TODO EL ARRAY
                            },
                            // CONFIGURACION DE LA TABLA "SECUNDARIA"
                            volume: {
                                type: 'linear', // ORDEN DE LOS TITULOS
                                position: 'right', // POSICION DE LOS TITULOS DE LA TABLA SECUNDARIA
                                min : 0, // VALOR MINIMO DESDE EL QUE SE EMPIEZAN A VER LOS DATOS
                                max : Math.max(...volume)+ 3000, // VALOR MAXIMO HASTA EL QUE SE VEN LOS DATOS
                                grid: { // CONFIGURACION  DE LA CUADRICULA QUE SE PUEDE VER AL TENER VARIOS DATOS EN LA TABLA
                                    display: false // ACTIVAR O DESACTIVAR LOS TITULOS DE LA TABLA SECUNDARIA EN EL EJE Y
                                },
                                plugins: { // CONFIGURACION INICIAL DE PLUGINS PREDERTERMINADOS TABLA SECUNDARIA
                                    legend: { // BOTONES DE LA PARTE SUPERIOR PARA ACTIVAR O DESACTIVAR UN DATO EN ESPECIFICO
                                        display: false
                                    },
                                    tooltip: { // OPCION DERIVADA DE LA ANTERIOR
                                        enabled: false
                                    }
                                }
                            }
                        },
                        plugins: { // CONFIGURACION PLUGINS TABLA PRINCIPAL
                            zoom: { // INVOCACION DEL PLUGIN ZOOM IMPORTAN EN LA PARTE SUPERIOR DEL CODIGO
                                // DOCUMENTACION DISPLONIBLE EN LA PAGINA OFICIAL DEL PLUGIN ASI COMO EN DOCUMENTACION DE CHARTJS
                                pan:{
                                    enabled: true,
                                    mode: 'x',
                                    speed: 3,

                                },

                                zoom: {

                                    wheel: { // CONFIGURACION DEL ZOOM USANDO LA OPCION DE WHEEL
                                        enabled: true // ACTIVAR O DESACTIVAR EL ZOOM
                                    },
                                    limits: { // LIMITAR MINIMO Y MAXIMO DEL MISMO PARA EVITAR PROBLEMAS DE A LA HORA DE LA VISTA
                                        y: {min: dates[0], max: dates[dates.length - 1]}
                                    },
                                    mode: 'x', // ESPECIFICACION DEL EJE SOBRE EL QUE SE HACE EL ZOOM
                                    speed: 0.6, // VELOCIDAD A LA QUE SE REALIZA EL ZOOM
                                    sensitivity: 6,// INTENCIDAD DE LA ACCION DEL MOUSE PARA ACTIVAR EL ZOOM




                                }
                            },

                            legend: { // BOTONES DE LA PARTE SUPERIOR PARA ACTIVAR O DESACTIVAR UN DATO EN ESPECIFICO
                                display: false,
                            },
                            tooltip: { // OPCION DERIVADA DE LA ANTERIOR
                                enabled: false
                            }
                        },

                        plugins1: { // CONFIGURACION PLUGINS TABLA PRINCIPAL
                            zoom: { // INVOCACION DEL PLUGIN ZOOM IMPORTAN EN LA PARTE SUPERIOR DEL CODIGO
                                // DOCUMENTACION DISPLONIBLE EN LA PAGINA OFICIAL DEL PLUGIN ASI COMO EN DOCUMENTACION DE CHARTJS
                                zoom: {
                                    pointermove: { // CONFIGURACION DEL ZOOM USANDO LA OPCION DE WHEEL
                                        enabled: true // ACTIVAR O DESACTIVAR EL ZOOM
                                    },
                                    limits: { // LIMITAR MINIMO Y MAXIMO DEL MISMO PARA EVITAR PROBLEMAS DE A LA HORA DE LA VISTA
                                        y: {min: dates[0], max: dates[dates.length - 1]}
                                    },
                                    mode: 'x', // ESPECIFICACION DEL EJE SOBRE EL QUE SE HACE EL ZOOM
                                    speed: 0.1, // VELOCIDAD A LA QUE SE REALIZA EL ZOOM
                                    sensitivity: 6 // INTENCIDAD DE LA ACCION DEL MOUSE PARA ACTIVAR EL ZOOM
                                }
                            },

                            legend: { // BOTONES DE LA PARTE SUPERIOR PARA ACTIVAR O DESACTIVAR UN DATO EN ESPECIFICO
                                display: false,
                            },
                            tooltip: { // OPCION DERIVADA DE LA ANTERIOR
                                enabled: false
                            }
                        }
                    },
                    plugins: [dottedLine] // INVOCACION DE LOS PLUGINS PERSONALIZADOS CREADOS ANTERIORMENTE
                };

                // VALIDACION PARA LA HORA DE RENDERIZADO
                if (myChart) {
                    myChart.destroy()
                }

                // VALIDACION PARA REALIZAR LA RENDERIZACION DE LA GRAFICA DENTRO DEL CANVAS CREADO ANTERIORMENTE
                myChart = new Chart(
                    document.getElementById('myChart'),
                    config
                );

                // CREACION DE FUNCIONES

                // DEFINICION DEL COLOR SEGUN SEA MAYOR O MENOR DEL PRIMER DATO DEL DIA
                function getGradient(ctx, chartArea, data, scales) {

                    const {left, right, top, bottom, width, height} = chartArea;
                    const {x, y} = scales;
                    const gradientBorder = ctx.createLinearGradient(0, 0, 0, bottom);
                    let shift = y.getPixelForValue(data.datasets[0].data[0]) / bottom;

                    if (shift > 1) {
                        shift = 1;
                    }

                    if (shift < 0) {
                        shift = 0;
                    }

                    gradientBorder.addColorStop(0, 'rgba(75, 192, 192, 1)');
                    gradientBorder.addColorStop(shift, 'rgba(75, 192, 192, 1)');
                    gradientBorder.addColorStop(shift, 'rgba(255, 26, 104, 1)');
                    gradientBorder.addColorStop(1, 'rgba(255, 26, 104, 1)');
                    return gradientBorder
                }

                // DEFINICION DEL COLOR SEGUN SEA MAYOR O MENOR DEL PRIMER DATO DEL DIA
                function belowGradient(ctx, chartArea, data, scales) {
                    const {left, right, top, bottom, width, height} = chartArea;
                    const {x, y} = scales;
                    const gradientBackground = ctx.createLinearGradient(0, y.getPixelForValue(data.datasets[0].data[0]),
                        0, bottom);
                    gradientBackground.addColorStop(0, 'rgba(255, 26, 104, 0)')
                    gradientBackground.addColorStop(1, 'rgba(255, 26, 104, 0.5)')
                    return gradientBackground;
                }

                function aboveGradient(ctx, chartArea, data, scales) {
                    const {left, right, top, bottom, width, height} = chartArea;
                    const {x, y} = scales;
                    const gradientBackground = ctx.createLinearGradient(0, y.getPixelForValue(data.datasets[0].data[0]),
                        0, top);
                    gradientBackground.addColorStop(0, 'rgba(75, 192, 192, 0)')
                    gradientBackground.addColorStop(1, 'rgba(75, 192, 192, 1)')
                    return gradientBackground;
                }

                myChart.canvas.addEventListener('mousemove', (e) => {
                    crosshairLine(myChart, e)
                })


                function crosshairLine(chart, mousemove) {
                    const {canvas, ctx, chartArea: {left, right, top, bottom}} = chart;

                    const coorX = mousemove.offsetX;
                    const coorY = mousemove.offsetY;

                    chart.update('none');
                    ctx.restore();

                    if (dates.length > 1) {
                        if (coorX >= left && coorX <= right && coorY >= top
                            && coorY <= bottom) {
                            canvas.style.cursor = 'crosshair'
                        } else {
                            canvas.style.cursor = 'default'
                        }

                        ctx.lineWidth = 1;
                        ctx.strokeStyle = '#666';
                        ctx.setLineDash([3, 3]);

                        if (coorX >= left && coorX <= right && coorY >= top && coorY <= bottom) {

                            //Horizontal Line
                            ctx.beginPath();
                            ctx.moveTo(left, coorY);
                            ctx.lineTo(right, coorY);
                            ctx.stroke();
                            ctx.closePath();

                            //Vertical Line
                            ctx.beginPath();
                            ctx.moveTo(coorX, top);
                            ctx.lineTo(coorX, bottom);
                            ctx.stroke();
                            ctx.closePath();

                            crosshairLabel(chart, mousemove)
                            tooltipPosition(chart, mousemove)
                        }
                        ctx.restore();
                        ctx.setLineDash([]);
                    }
                }

                function crosshairLabel(chart, mousemove) {
                    const {ctx, data, chartArea: {top, bottom, left, right, width, height}, scales: {x, y}} = chart;

                    let coorY = mousemove.offsetY;
                    let coorX = mousemove.offsetX;
                    const textWidth = ctx.measureText(data.labels[x.getValueForPixel(coorX)]).width + 10;

                    if (dates.length > 1) {
                        ctx.font = '12px sans-serif';
                        ctx.textBaseline = 'middle';
                        ctx.textAlign = 'center';

                        //yLabel
                        ctx.beginPath();
                        ctx.fillStyle = 'rgba( 132, 132, 132, 1)';
                        ctx.fillRect(0, coorY - 10, left, 20);
                        ctx.closePath();
                        ctx.fillStyle = 'white';
                        ctx.fillText(y.getValueForPixel(coorY).toFixed(2), left / 2, coorY);

                        //xLabel
                        ctx.beginPath();
                        ctx.fillStyle = 'rgba( 132, 132, 132, 1)';
                        ctx.fillRect(coorX - (textWidth / 2), bottom, textWidth, 20);
                        ctx.closePath();
                        ctx.fillStyle = 'white';
                        ctx.fillText(data.labels[x.getValueForPixel(coorX)], coorX, bottom + 10);
                    }
                }

                function tooltipPosition(chart, mousemove) {
                    const {ctx, chartArea: {top, bottom, left, right, width, height}, scales: {x, y}} = chart;

                    let xTooltip;
                    let yTooltip;

                    const rightSide = right - mousemove.offsetX;

                    if (dates.length > 1) {
                        if (rightSide <= 170) {
                            xTooltip = mousemove.offsetX - 170;
                        } else {
                            xTooltip = mousemove.offsetX + 20;
                        }

                        if (mousemove.offsetY <= 100) {
                            yTooltip = mousemove.offsetY + 20;
                        } else {
                            yTooltip = mousemove.offsetY - 80;
                        }

                        let xleft;
                        let xright;

                        if (x.min == dates[0]) {
                            xleft = x.getPixelForValue(dates[0]);
                        } else {
                            xleft = left;
                        }
                        if (x.max == dates[dates.length - 1]) {
                            xright = x.getPixelForValue(dates[dates.length - 1]);
                        } else {
                            xright = right;
                        }
                        if (mousemove.offsetX >= xleft && mousemove.offsetX <= xright && mousemove.offsetY >= top
                            && mousemove.offsetY <= bottom) {
                            datesBox(mousemove)
                        }
                    }

                    datesBox(mousemove)

                    function datesBox(mousemove) {

                        ctx.beginPath();
                        ctx.fillStyle = 'rgba(102, 102, 102, 0.5)';
                        ctx.strokeStyle = 'rgba(102, 102, 102, 0.5)';
                        ctx.setLineDash([0, 0]);
                        ctx.lineJoin = 'round';
                        ctx.lineWidth = 3;
                        ctx.fillRect(xTooltip, yTooltip, 150, 60);
                        ctx.strokeRect(xTooltip, yTooltip, 150, 60);
                        ctx.closePath();
                        ctx.restore();

                        const dateCursor = x.getValueForPixel(mousemove.offsetX);

                        //text date
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'white';
                        ctx.font = 'bolder 12px sans-serif';
                        ctx.fillText(new Date().toLocaleDateString(), xTooltip + 5, yTooltip + 10);
                        ctx.restore();

                        //text time
                        ctx.textAlign = 'right';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'lightgrey';
                        ctx.font = 'bolder 10px sans-serif';
                        ctx.fillText(dates[dateCursor],
                            xTooltip + 150 - 5, yTooltip + 10)
                        ctx.restore();

                        //line 2 text price
                        const priceText = 'Precios: ';
                        const priceTextWidth = ctx.measureText(priceText).width;
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'lightgrey';
                        ctx.font = '12px sans-serif';
                        ctx.fillText(priceText, xTooltip + 5, yTooltip + 30);
                        ctx.restore();

                        //line 2 price value
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'white';
                        ctx.font = 'bolder 12px sans-serif';
                        ctx.fillText(' $' + numbers[dateCursor], xTooltip + 5 + priceTextWidth,
                            yTooltip + 30);
                        ctx.restore();

                        //line 3 text price
                        const valueText = 'Montos: ';
                        const valueTextWidth = ctx.measureText(valueText).width;
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'lightgrey';
                        ctx.font = '12px sans-serif';
                        ctx.fillText(valueText, xTooltip + 5, yTooltip + 50);
                        ctx.restore();

                        //line 3 price value
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'white';
                        ctx.font = 'bolder 12px sans-serif';
                        ctx.fillText(volume[dateCursor], xTooltip + 5 + valueTextWidth,
                            yTooltip + 50);
                        ctx.restore();

                    }
                }
                myChart.canvas.addEventListener('pointermove', (e) => {
                    crosshairLine1(myChart, e)
                })


                function crosshairLine1(chart, pointermove) {
                    const {canvas, ctx, chartArea: {left, right, top, bottom}} = chart;

                    const coorX = pointermove.offsetX;
                    const coorY = pointermove.offsetY;

                    chart.update('none');
                    ctx.restore();

                    if (dates.length > 1) {
                        if (coorX >= left && coorX <= right && coorY >= top
                            && coorY <= bottom) {
                            canvas.style.pointer = 'crosshair'
                        } else {
                            canvas.style.pointer = 'default'
                        }

                        ctx.lineWidth = 1;
                        ctx.strokeStyle = '#666';
                        ctx.setLineDash([3, 3]);

                        if (coorX >= left && coorX <= right && coorY >= top && coorY <= bottom) {

                            //Horizontal Line
                            ctx.beginPath();
                            ctx.moveTo(left, coorY);
                            ctx.lineTo(right, coorY);
                            ctx.stroke();
                            ctx.closePath();

                            //Vertical Line
                            ctx.beginPath();
                            ctx.moveTo(coorX, top);
                            ctx.lineTo(coorX, bottom);
                            ctx.stroke();
                            ctx.closePath();

                            crosshairLabel2(chart, pointermove)
                            tooltipPosition1(chart, pointermove)
                        }
                        ctx.restore();
                        ctx.setLineDash([]);
                    }
                }
                function crosshairLabel2(chart, pointermove) {
                    const {ctx, data, chartArea: {top, bottom, left, right, width, height}, scales: {x, y}} = chart;

                    let coorY = pointermove.offsetY;
                    let coorX = pointermove.offsetX;
                    const textWidth = ctx.measureText(data.labels[x.getValueForPixel(coorX)]).width + 10;

                    if (dates.length > 1) {
                        ctx.font = '12px sans-serif';
                        ctx.textBaseline = 'middle';
                        ctx.textAlign = 'center';

                        //yLabel
                        ctx.beginPath();
                        ctx.fillStyle = 'rgba( 132, 132, 132, 1)';
                        ctx.fillRect(0, coorY - 10, left, 20);
                        ctx.closePath();
                        ctx.fillStyle = 'white';
                        ctx.fillText(y.getValueForPixel(coorY).toFixed(2), left / 2, coorY);

                        //xLabel
                        ctx.beginPath();
                        ctx.fillStyle = 'rgba( 132, 132, 132, 1)';
                        ctx.fillRect(coorX - (textWidth / 2), bottom, textWidth, 20);
                        ctx.closePath();
                        ctx.fillStyle = 'white';
                        ctx.fillText(data.labels[x.getValueForPixel(coorX)], coorX, bottom + 10);
                    }
                }

                function tooltipPosition1(chart, pointermove) {
                    const {ctx, chartArea: {top, bottom, left, right, width, height}, scales: {x, y}} = chart;

                    let xTooltip;
                    let yTooltip;

                    const rightSide = right - pointermove.offsetX;

                    if (dates.length > 1) {
                        if (rightSide <= 170) {
                            xTooltip = pointermove.offsetX - 170;
                        } else {
                            xTooltip = pointermove.offsetX + 20;
                        }

                        if (pointermove.offsetY <= 100) {
                            yTooltip = pointermove.offsetY + 20;
                        } else {
                            yTooltip = pointermove.offsetY - 80;
                        }

                        let xleft;
                        let xright;

                        if (x.min == dates[0]) {
                            xleft = x.getPixelForValue(dates[0]);
                        } else {
                            xleft = left;
                        }
                        if (x.max == dates[dates.length - 1]) {
                            xright = x.getPixelForValue(dates[dates.length - 1]);
                        } else {
                            xright = right;
                        }
                        if (pointermove.offsetX >= xleft && pointermove.offsetX <= xright && pointermove.offsetY >= top
                            && pointermove.offsetY <= bottom) {
                            datesBox1(pointermove)
                        }
                    }

                    datesBox1(pointermove)

                    function datesBox1(pointermove) {

                        ctx.beginPath();
                        ctx.fillStyle = 'rgba(102, 102, 102, 0.5)';
                        ctx.strokeStyle = 'rgba(102, 102, 102, 0.5)';
                        ctx.setLineDash([0, 0]);
                        ctx.lineJoin = 'round';
                        ctx.lineWidth = 3;
                        ctx.fillRect(xTooltip, yTooltip, 150, 60);
                        ctx.strokeRect(xTooltip, yTooltip, 150, 60);
                        ctx.closePath();
                        ctx.restore();

                        const Pointermove = x.getValueForPixel(pointermove.offsetX);

                        //text date
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'white';
                        ctx.font = 'bolder 12px sans-serif';
                        ctx.fillText(new Date().toLocaleDateString(), xTooltip + 5, yTooltip + 10);
                        ctx.restore();

                        //text time
                        ctx.textAlign = 'right';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'lightgrey';
                        ctx.font = 'bolder 10px sans-serif';
                        ctx.fillText(dates[Pointermove],
                            xTooltip + 150 - 5, yTooltip + 10)
                        ctx.restore();

                        //line 2 text price
                        const priceText = 'Price: ';
                        const priceTextWidth = ctx.measureText(priceText).width;
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'lightgrey';
                        ctx.font = '12px sans-serif';
                        ctx.fillText(priceText, xTooltip + 5, yTooltip + 30);
                        ctx.restore();

                        //line 2 price value
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'white';
                        ctx.font = 'bolder 12px sans-serif';
                        ctx.fillText(' $' + numbers[Pointermove], xTooltip + 5 + priceTextWidth,
                            yTooltip + 30);
                        ctx.restore();

                        //line 3 text price
                        const valueText = 'Value: ';
                        const valueTextWidth = ctx.measureText(valueText).width;
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'lightgrey';
                        ctx.font = '12px sans-serif';
                        ctx.fillText(valueText, xTooltip + 5, yTooltip + 50);
                        ctx.restore();

                        //line 3 price value
                        ctx.textAlign = 'left';
                        ctx.textBaseline = 'middle';
                        ctx.fillStyle = 'white';
                        ctx.font = 'bolder 12px sans-serif';
                        ctx.fillText(volume[Pointermove], xTooltip + 5 + valueTextWidth,
                            yTooltip + 50);
                        ctx.restore();

                    }
                }


                const data2 = {
                    labels: dates,
                    datasets: [{
                        label: 'Weekly Sales',
                        data: numbers,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        fill: true,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        pointHitRadius: 0,
                        borderWidth: 1,
                    }]
                }

                const config2 = {
                    type: 'line',
                    data: data2,
                    options: {
                        animation: false,
                        maintainAspectRatio: false,
                        layout: {
                            padding: {
                                left: myChart.chartArea.left - myChart.config.options.layout.padding.left,
                                right: myChart.width - myChart.chartArea.right,
                            }
                        },
                        aspectRatio: 10,
                        scales: {
                            x: {
                                min: dates[0],
                                max: dates[dates.length - 1],
                                grid: {
                                    drawOnChartArea: false,
                                    drawTicks: false,
                                    drawBorder: false,
                                    offset: false,
                                },
                                ticks: {
                                    display: false
                                }
                            },
                            y: {
                                beginAtZero: false,
                                ticks: {
                                    display: false
                                },
                                grid: {
                                    display: false,
                                    drawBorder: false,
                                },
                            },
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: false,
                            }
                        }
                    }
                }

                if (myChart2) {
                    myChart2.destroy()
                }

                myChart2 = new Chart(
                    document.getElementById('myChart2'),
                    config2
                );
                myChart.canvas.addEventListener('wheel', (e) => {
                    zoomBox(myChart, e)

                })

                function zoomBox(myChart,) {
                    let min = myChart.config.options.scales.x.min;
                    let max = myChart.config.options.scales.x.max;

                    if (dates.length > 1) {
                        if (min < -1) {
                            min = dates.indexOf(dates[0])
                        }
                        if (max > dates.length) {
                            max = dates.length - 1
                        }

                        myChart.update('none')
                        myChart2.update('none');

                        const {
                            ctx,
                            canvas,
                            chartArea: {top, bottom, left, right, width, height},
                            scales: {x, y}
                        } = myChart2;

                        zoomBoxItem(min, max);

                        function zoomBoxItem(min, max) {

                            if (min < -1) {
                                min = dates.indexOf(dates[0])
                            }
                            if (max > dates.length) {
                                max = dates.length - 1
                            }

                            ctx.save();
                            ctx.beginPath();
                            ctx.fillStyle = 'rgba(54, 162, 235, 0.2)';
                            ctx.fillRect(x.getPixelForValue(min), top, x.getPixelForValue(max) - x.getPixelForValue(min), height);
                            ctx.closePath();

                            ctx.restore();
                            const angle = Math.PI / 180;
                            swiperButton(x.getPixelForValue(min));
                            swiperButton(x.getPixelForValue(max));

                            function swiperButton(position) {
                                ctx.beginPath();
                                ctx.strokeStyle = 'rgba(54, 162, 235, 1)';
                                ctx.lineWidth = 2;
                                ctx.fillStyle = ' #FFF';
                                ctx.sensitivity= 50;

                                ctx.arc(position, height / 2, 10, 0, angle * 360, false);
                                ctx.fill();
                                ctx.stroke();
                                ctx.closePath();

                                ctx.restore();
                                ctx.strokeStyle = 'rgba(54, 162, 235, 1)';
                                ctx.lineWidth = 1.5;
                                ctx.beginPath();
                                ctx.moveTo(position - 3, height / 2 - 5);
                                ctx.lineTo(position - 3, height / 2 + 5);
                                ctx.stroke();

                                ctx.restore();
                                ctx.beginPath();
                                ctx.moveTo(position + 3, height / 2 - 5);
                                ctx.lineTo(position + 3, height / 2 + 5);
                                ctx.stroke();
                                ctx.restore();
                            }
                        }

                        canvas.addEventListener('mousemove', (e) => {
                            mouseCursor(e)
                        })

                        function mouseCursor(mousemove) {

                            let minChart1 = myChart.config.options.scales.x.min;
                            let maxChart1 = myChart.config.options.scales.x.max;
                            if (minChart1 < 0) {
                                minChart1 = dates[0]
                            }
                            if (maxChart1 > dates.length - 1) {
                                maxChart1 = dates.length
                            }

                            if (mousemove.offsetX >= x.getPixelForValue(minChart1) - 10 && mousemove.offsetX
                                <= x.getPixelForValue(minChart1) + 10
                                || mousemove.offsetX >= x.getPixelForValue(maxChart1) - 10 && mousemove.offsetX
                                <= x.getPixelForValue(maxChart1) + 10 ||
                                mousemove.offsetX >= x.getPixelForValue(maxChart1) - 10 && mousemove.offsetX
                                <= x.getPixelForValue(maxChart1) + 10
                                || mousemove.offsetX >= x.getPixelForValue(maxChart1) - 10 && mousemove.offsetX
                                <= x.getPixelForValue(maxChart1) + 10
                            ) {
                                canvas.style.cursor = 'ew-resize';
                            } else if (mousemove.offsetX > x.getPixelForValue(minChart1) + 10 && mousemove.offsetX
                                < x.getPixelForValue(maxChart1) + 10 ||
                                mousemove.offsetX > x.getPixelForValue(maxChart1) + 10 && mousemove.offsetX
                                < x.getPixelForValue(maxChart1) + 10) {
                                canvas.style.cursor = 'move';
                            } else {
                                canvas.style.cursor = 'default';
                            }
                        }


                        window.addEventListener('mousedown', (e) => {
                            dragStart(e);
                        })

                        window.addEventListener('mouseup', (e) => {
                            canvas.onmousemove = null;
                        })


                        function dragStart(drag) {
                            let minChart1 = myChart.config.options.scales.x.min;
                            let maxChart1 = myChart.config.options.scales.x.max;

                            if (minChart1 === undefined || minChart1 < -1) {
                                minChart1 = 0;
                            }
                            if (maxChart1 > dates.length) {
                                maxChart1 = dates.length
                            }

                            if (drag.offsetX >= x.getPixelForValue(minChart1) - 10 && drag.offsetX
                                <= x.getPixelForValue(minChart1) + 10) {
                                canvas.onmousemove = (e) => {
                                    dragMove(myChart, e);
                                }
                                canvas.onpointermove = (e) => {
                                    dragMove(myChart, e);
                                }

                                //----------MIN-----------//

                                function dragMove(myChart, dragDelta) {
                                    const timestamp = data.labels[x.getValueForPixel(dragDelta.offsetX)];
                                    let scrollPoint = dates.indexOf(timestamp);

                                    if (scrollPoint < 0) {
                                        scrollPoint = 0
                                    }
                                    if (scrollPoint >= maxChart1) {
                                        scrollPoint = maxChart1;
                                    }
                                    if (dragDelta.offsetX > right) {
                                        scrollPoint = maxChart1;
                                    }

                                    myChart.config.options.scales.x.min = scrollPoint

                                    myChart.update('none');
                                    myChart2.update('none');

                                    zoomBoxItem(scrollPoint, maxChart1)
                                }
                            }
                            if (drag.offsetX >= x.getPixelForValue(maxChart1) - 10 && drag.offsetX
                                <= x.getPixelForValue(maxChart1) + 10) {
                                canvas.onmousemove = (e) => {
                                    dragMove(myChart, e);
                                }


                                //----------MAX-----------//

                                function dragMove(myChart, dragDelta) {
                                    const timestamp = data.labels[x.getValueForPixel(dragDelta.offsetX)]
                                    let scrollPoint = dates.indexOf(timestamp);

                                    if (scrollPoint < 0) {
                                        scrollPoint = dates.length - 1
                                    }
                                    if (scrollPoint <= minChart1) {
                                        scrollPoint = minChart1
                                    }
                                    if (dragDelta.offsetX < left) {
                                        scrollPoint = minChart1;
                                    }

                                    myChart.config.options.scales.x.max = scrollPoint;

                                    myChart.update('none');
                                    myChart2.update('none');
                                    zoomBoxItem(minChart1, scrollPoint);
                                }
                            }

                            //-----------zoomMove-----------//
                            if (drag.offsetX > x.getPixelForValue(myChart.config.options.scales.x.min) + 11 && drag.offsetX
                                < x.getPixelForValue(myChart.config.options.scales.x.max) - 11) {
                                canvas.onmousemove = (e) => {
                                    dragMoveCenter(myChart, e, minChart1, maxChart1);
                                }


                                function dragMoveCenter(myChart, dragDelta, staticScaleMin, staticScaleMax) {
                                    const startingPoint = data.labels[x.getValueForPixel(drag.offsetX)];
                                    let dayStart = dates.indexOf(startingPoint)

                                    const timestamp = data.labels[x.getValueForPixel(dragDelta.offsetX)];
                                    let scrollPoint = dates.indexOf(timestamp)

                                    const difference = scrollPoint - dayStart;

                                    if (scrollPoint === -1 && dragDelta.offsetX >= right) {
                                        scrollPoint = dates.length - 1
                                    }

                                    const range = staticScaleMax - staticScaleMin;

                                    let minVal = dates.indexOf(dates[staticScaleMax]) + difference - range;
                                    let maxVal = dates.indexOf(dates[staticScaleMax]) + difference;

                                    let minChart1;
                                    let maxChart1;

                                    if (minVal <= 0 && dragDelta.offsetX < right) {
                                        minChart1 = dates.indexOf(dates[0]);
                                        maxChart1 = dates.indexOf(dates[range - 1])
                                    } else if (maxVal >= dates.length - 1 || difference < 0 && dragDelta.offsetX >= right) {
                                        minChart1 = dates.indexOf(dates[dates.length - 1 - range]);
                                        maxChart1 = dates.indexOf(dates[dates.length - 1]);
                                    } else {
                                        minChart1 = dates.indexOf(dates[staticScaleMin]) + difference;
                                        maxChart1 = dates.indexOf(dates[staticScaleMax]) + difference;
                                    }
                                    if (minChart1 === undefined) {
                                        minChart1 = dates[0];
                                    }
                                    if (maxChart1 === undefined) {
                                        maxChart1 = dates[dates.length - 1]
                                    }
                                    if (minChart1 === dates[0]) {
                                        myChart.config.options.scales.x.min = dates[0];
                                        myChart.config.options.scales.x.max = myChart.config.options.scales.x.max;
                                    } else if (maxChart1 === dates[dates.length - 1]) {
                                        myChart.config.options.scales.x.min = myChart.config.options.scales.x.min;
                                        myChart.config.options.scales.x.max = dates[dates.length - 1];
                                    } else if (myChart.config.options.scales.x.min >= dates[0] && myChart.config.options.scales.x.max <= dates[dates.length - 1]) {
                                        myChart.config.options.scales.x.min = minChart1;
                                        myChart.config.options.scales.x.max = maxChart1;
                                    }

                                    myChart.config.options.scales.x.min = minChart1;
                                    myChart.config.options.scales.x.max = maxChart1;

                                    myChart.update('none');
                                    myChart2.update('none');
                                    zoomBoxItem(minChart1, maxChart1);
                                }
                            }
                        }
                    }
                }



                //----------------------------------------- FUNCION TACTIL------------------------------------------//




                myChart.canvas.addEventListener('pointermove', (e) => {
                    zoomBox1(myChart, e)
                 
                })
                function zoomBox1(myChart1, ) {
                    let min = myChart1.config.options.scales.x.min;
                    let max = myChart1.config.options.scales.x.max;

                    if (dates.length > 1) {
                        if (min < -1) {
                            min = dates.indexOf(dates[0])
                        }
                        if (max > dates.length) {
                            max = dates.length - 1
                        }
                        myChart2.update('none');
                        const {
                            ctx,
                            canvas,
                            chartArea: {top, bottom, left, right, width, height},
                            scales: {x, y}
                        } = myChart2;

                        zoomBoxItem1(min, max);

                        function zoomBoxItem1(min, max) {

                            if (min < -1) {
                                min = dates.indexOf(dates[0])
                            }
                            if (max > dates.length) {
                                max = dates.length - 1
                            }

                            ctx.save();
                            ctx.beginPath();
                            ctx.fillStyle = 'rgba(54, 162, 235, 0.2)';
                            ctx.fillRect(x.getPixelForValue(min), top, x.getPixelForValue(max) - x.getPixelForValue(min), height);
                            ctx.closePath();



                            ctx.restore();
                            const angle = Math.PI / 180;
                            swiperButton(x.getPixelForValue(min));
                            swiperButton(x.getPixelForValue(max));

                            function swiperButton(position) {
                                ctx.beginPath();
                                ctx.strokeStyle = 'rgba(54, 162, 235, 1)';
                                ctx.lineWidth = 2;
                                ctx.fillStyle = ' #FFF';
                                // ctx.sensitivity= 2000;

                                ctx.arc(position, height / 2, 10, 0, angle * 360, false);ctx.fill();
                                ctx.stroke();
                                ctx.closePath();

                                ctx.restore();
                                ctx.strokeStyle = 'rgba(54, 162, 235, 1)';
                                ctx.lineWidth = 1.5;
                                ctx.beginPath();
                                ctx.moveTo(position - 3, height / 2 - 5);
                                ctx.lineTo(position - 3, height / 2 + 5);
                                ctx.stroke();

                                ctx.restore();
                                ctx.beginPath();
                                ctx.moveTo(position + 3, height / 2 - 5);
                                ctx.lineTo(position + 3, height / 2 + 5);
                                ctx.stroke();
                                ctx.restore();
                            }
                        }

                        canvas.addEventListener('pointermove',  (e) => {
                            PointerMove(e);
                        })

                        function PointerMove(pointermove) {

                            let minChart1 = myChart.config.options.scales.x.min;
                            let maxChart1 = myChart.config.options.scales.x.max;
                            if (minChart1 < 0) {
                                minChart1 = dates[0]
                            }
                            if (maxChart1 > dates.length - 1) {
                                maxChart1 = dates.length
                            }

                            if (pointermove.offsetX >= x.getPixelForValue(minChart1) - 10 && pointermove.offsetX
                                <= x.getPixelForValue(minChart1) + 10


                        || pointermove.offsetX >= x.getPixelForValue(maxChart1) - 10 && pointermove.offsetX
                                <= x.getPixelForValue(maxChart1) + 10 ||
                                pointermove.offsetX >= x.getPixelForValue(maxChart1) - 10 && pointermove.offsetX
                                <= x.getPixelForValue(maxChart1) + 10
                                || pointermove.offsetX >= x.getPixelForValue(maxChart1) - 10 && pointermove.offsetX
                                <= x.getPixelForValue(maxChart1) + 10
                            ) {
                                canvas.style.pointer = 'ew-resize';
                            } else if (pointermove.offsetX > x.getPixelForValue(minChart1) + 10 && pointermove.offsetX
                                < x.getPixelForValue(maxChart1) + 10 ||
                                pointermove.offsetX > x.getPixelForValue(maxChart1) + 10 && pointermove.offsetX
                                < x.getPixelForValue(maxChart1) + 10) {
                                canvas.style.pointer = 'move';
                            } else {
                                canvas.style.pointer = 'default';


                            }
                        }


                        window.addEventListener('pointerdown', (e) => {
                            dragStart1(e);
                        })

                        window.addEventListener('pointerup', (e) => {
                            canvas.onpointermove = null;
                        })
                        
                        function dragStart1(drag) {
                            let minChart1 = myChart.config.options.scales.x.min;
                            let maxChart1 = myChart.config.options.scales.x.max;

                            if (minChart1 === undefined || minChart1 < -1) {
                                minChart1 = 0;
                            }
                            if (maxChart1 > dates.length) {
                                maxChart1 = dates.length

                            }

                            if (drag.offsetX >= x.getPixelForValue(minChart1) - 10 && drag.offsetX
                                <= x.getPixelForValue(minChart1) + 10) {

                                canvas.onpointermove = (e) => {
                                    dragMove1(myChart, e);
                                }

                                //----------MIN-----------//

                                function dragMove1(myChart, dragDelta) {
                                    const timestamp = data.labels[x.getValueForPixel(dragDelta.offsetX)];
                                    let scrollPoint1 = dates.indexOf(timestamp);

                                    if (scrollPoint1 < 0) {
                                        scrollPoint1 = 0
                                    }
                                    if (scrollPoint1 >= maxChart1) {
                                        scrollPoint1 = maxChart1;
                                    }
                                    if (dragDelta.offsetX > right) {
                                        scrollPoint1 = maxChart1;
                                    }

                                    myChart.config.options.scales.x.min = scrollPoint1

                                    myChart.update('none');
                                    myChart2.update('none');

                                    zoomBoxItem1(scrollPoint1, maxChart1)
                                }
                            }
                            if (drag.offsetX >= x.getPixelForValue(maxChart1) - 10 && drag.offsetX
                                <= x.getPixelForValue(maxChart1) + 10) {

                                canvas.onpointermove = (e) => {
                                    dragMove1(myChart, e);
                                }

                                //----------MAX-----------//

                                function dragMove1(myChart, dragDelta) {
                                    const timestamp = data.labels[x.getValueForPixel(dragDelta.offsetX)]
                                    let scrollPoint = dates.indexOf(timestamp);

                                    if (scrollPoint < 0) {
                                        scrollPoint = dates.length - 1
                                    }
                                    if (scrollPoint <= minChart1) {
                                        scrollPoint = minChart1
                                    }
                                    if (dragDelta.offsetX < left) {
                                        scrollPoint = minChart1;
                                    }

                                    myChart.config.options.scales.x.max = scrollPoint;

                                    myChart.update('none');
                                    myChart2.update('none');
                                    zoomBoxItem1(minChart1, scrollPoint);
                                }
                            }

                            //-----------zoomMove-----------//
                            if (drag.offsetX > x.getPixelForValue(myChart.config.options.scales.x.min) + 11 && drag.offsetX
                                < x.getPixelForValue(myChart.config.options.scales.x.max) - 11) {

                                canvas.onpointermove = (e) => {
                                    dragMoveCenter1(myChart, e, minChart1, maxChart1);
                                }

                                function dragMoveCenter1(myChart, dragDelta, staticScaleMin, staticScaleMax) {
                                    const startingPoint = data.labels[x.getValueForPixel(drag.offsetX)];
                                    let dayStart = dates.indexOf(startingPoint)

                                    const timestamp = data.labels[x.getValueForPixel(dragDelta.offsetX)];
                                    let scrollPoint = dates.indexOf(timestamp)

                                    const difference = scrollPoint - dayStart;

                                    if (scrollPoint === -1 && dragDelta.offsetX >= right) {
                                        scrollPoint = dates.length - 1
                                    }

                                    const range = staticScaleMax - staticScaleMin;

                                    let minVal = dates.indexOf(dates[staticScaleMax]) + difference - range;
                                    let maxVal = dates.indexOf(dates[staticScaleMax]) + difference;

                                    let minChart1;
                                    let maxChart1;

                                    if (minVal <= 0 && dragDelta.offsetX < right) {
                                        minChart1 = dates.indexOf(dates[0]);
                                        maxChart1 = dates.indexOf(dates[range - 1])
                                    } else if (maxVal >= dates.length - 1 || difference < 0 && dragDelta.offsetX >= right) {
                                        minChart1 = dates.indexOf(dates[dates.length - 1 - range]);
                                        maxChart1 = dates.indexOf(dates[dates.length - 1]);
                                    } else {
                                        minChart1 = dates.indexOf(dates[staticScaleMin]) + difference;
                                        maxChart1 = dates.indexOf(dates[staticScaleMax]) + difference;
                                    }
                                    if (minChart1 === undefined) {
                                        minChart1 = dates[0];
                                    }
                                    if (maxChart1 === undefined) {
                                        maxChart1 = dates[dates.length - 1]
                                    }
                                    if (minChart1 === dates[0]) {
                                        myChart.config.options.scales.x.min = dates[0];
                                        myChart.config.options.scales.x.max = myChart.config.options.scales.x.max;
                                    } else if (maxChart1 === dates[dates.length - 1]) {
                                        myChart.config.options.scales.x.min = myChart.config.options.scales.x.min;
                                        myChart.config.options.scales.x.max = dates[dates.length - 1];
                                    } else if (myChart.config.options.scales.x.min >= dates[0] && myChart.config.options.scales.x.max <= dates[dates.length - 1]) {
                                        myChart.config.options.scales.x.min = minChart1;
                                        myChart.config.options.scales.x.max = maxChart1;
                                    }

                                    myChart.config.options.scales.x.min = minChart1;
                                    myChart.config.options.scales.x.max = maxChart1;

                                    myChart.update('none');
                                    myChart2.update('none');
                                    zoomBoxItem1(minChart1, maxChart1);
                                }
                            }
                        }
                    }
                }
            })
    }

    componentDidMount() {

        if (window.innerWidth <= 600) {
            this.setState({mobile: true})
        }
        /*this.getChartData()*/
        this.buildChart()
        if (this.props.delay !== undefined) {
            let delay = this.props.delay === 0 ? 1 : this.props.delay;

            this.interval = setInterval(this./*getChartData*/buildChart.bind(this), delay * 4000 * 15)
            this.interval2 = setInterval("location.reload()", 540000)
        }
    }


    componentDidUpdate(prevProps) {

        if (
            (!this.state.loaded &&
                this.state.labels.length &&
                this.state.dolarValueData.length || (this.props.market !== prevProps.market))
        ) {
            this.setState({loaded: false})

            /*this.getChartData()*/
            this.buildChart()
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }/*
        if (myChart !== null) {
            myChart.destroy()
            myChart2.destroy();
        }*/
    }

    render() {
        return (
            <div className={classes.chartCard}>
                <h3 className="panel-title">{this.props.market === 76 ? 'Next day' : 'Dólar Spot'}</h3>
                <div className={classes.chartBox}>
                    <canvas
                        id="myChart"
                    />
                </div>
                <div className={classes.chartBox2}>
                    <canvas
                        id="myChart2"
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(DolarSpotChart);
