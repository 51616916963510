import React from 'react'
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AppBar from '@mui/material/AppBar';
import MySidebar from './HomeIMC';


export function AppBarReciclable() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const nombre = localStorage.getItem("Nombre")
  const settings = [nombre, 'Cerrar Sesión'];
  const entidad = localStorage.getItem("entidad") 
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  function CerrarSesion(){
    localStorage.clear()//eliminar todos los datos que estan guardados en la "memoria" del proyecto
    /*
      si tienen problemas con este localStorage.clear() pueden utilizar lo siguiente:
      localStorage.removeItem("entidadId") este es el valor que obtiene la api para ingresar a la ruta privada
      pero si eliminamos "entidadId" no se eliminara el resto entonces los eliminamos manualmente:
      localStorage.removeItem("Nombre")
      localStorage.removeItem("id_usr")
      localStorage.removeItem("entidad")
      localStorage.removeItem("datos") hay 2 veces que se estan utilizando con el metodo setItem() pero no se estan utilizando en ningun lado
      localStorage.removeItem("paquete_id")
      localStorage.removeItem("numero")
      localStorage.removeItem("NumFila")
    */
  }
  // function Prueba(){
  //   console.log('Hola mundo')
  // }

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#25476a', height: "57px" }}>
        <Toolbar variant="dense">
          <MySidebar />
          <Typography variant="h6" color="inherit" component="div" sx={{ fontSize: { xs: '18px', md: "24px" }, padding:"50px", margin:"0px 0px 0px 23px" }}> 
            {entidad} {/*aqui traemos el nombre de la entidad donde se encuentra el usuario */}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'block', md: 'flex' } }}>{/*Esto separa el banco Banco Bogotá S.A con el avatar*/}

          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Abrir Ajustes" componentsProps={{ tooltip: { sx: { fontSize: "1.5rem" } } }} >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ fontFamily: 'arial', fontSize: '16px' }}>{settings[0].substring(0, 2).toUpperCase()}</Avatar>{/*traemos el primer valor del arreglo llamado "settings"luego que subtraiga las 2 primeras letras del valor por ultimo lo convierte a texto en mayusculas*/}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <a href='/imc/login'> {settings.map((setting) => (
                <MenuItem key={setting} onClick={CerrarSesion}>
                  <Typography textAlign="center" style={{ fontSize: '14px' }} >{setting}</Typography>
                </MenuItem>
              ))}</a>
                {/* <MenuItem onClick={Prueba}>
                  <Typography textAlign="center" style={{ fontSize: '14px', color:'gray' }}>Hola</Typography>
                </MenuItem> */}
              {/* https://mui.com/material-ui/react-menu/ aqui pueden ver las opciones de menus desplegables, sobre todo este https://mui.com/material-ui/react-menu/#positioned-menu es el mas "facil" por el momento */}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}