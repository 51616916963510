import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import authActions from "./store/actions/auth.actions";
import Login from "./containers/Auth/Login/Login";
import ForgotPassword from "./containers/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword/ResetPassword";
import ChangePassword from "./containers/Auth/ChangePassword/ChangePassword";
import CreateAccount from "./containers/Auth/CreateAccount/CreateAccount";
import HomePage from "./containers/HomePage/HomePage";
import Dashboard from "./containers/Dashboard/Dashboard";
import PricingHome from "./containers/PricingHome/PricingHome";
import HomeStatistics from "./containers/HomeStatistics/HomeStatistics";

import ImcSetfx from "./containers/IMC_SetFX/Login/Imc_setfx";
// import HomeIMC from "./containers/IMC_SetFX/HomePage/AppBar";
import { connect } from "react-redux";
import "./App.css";
import MisUsuarios from "./containers/IMC_SetFX/HomePage/Paginas/Usuarios/MisUsuarios";
import MisProductos from "./containers/IMC_SetFX/HomePage/Paginas/Productos/MisProductos";
import MisSolicitudes from "./containers/IMC_SetFX/HomePage/Paginas/Solicitudes/MisSolicitudes";
import SubDetalles from "./containers/IMC_SetFX/HomePage/Paginas/Productos/SubDetalles";
import PrivateRoute from "./containers/IMC_SetFX/HomePage/PrivateRoute/PrivateRoute";
import ChangePasswordIMC from "./containers/IMC_SetFX/HomePage/Paginas/ResetPwdImc/ChangePwdImc";

//import Detalles from "./containers/IMC_SetFX/HomePage/Paginas/Productos/Detalles";
class App extends Component {
    componentDidMount() {      
        if (localStorage.length === 0){
            authActions.logout()
        }
        this.props.checkLogin();  
    }
    render() {     
        return (
            <Switch>
                <Route path="/auth/login/" component={Login} />
                <Route path="/auth/recuperar-contrasena/" component={ForgotPassword}/>
                <Route path="/auth/change-contrasena/" component={ChangePassword}/>
                <Route path="/auth/reset-contrasena/" component={ResetPassword}/>
                <Route path="/auth/registro/" component={CreateAccount} />           
                <Route path="/dashboard/" component={Dashboard} />
                {this.props.auth.token !== "" ? (
                <Route path="/dashboard/" component={Dashboard} />) : null}
                <Route path="/precios/" component={PricingHome} />
                <Route path='/estadisticas/' component={HomeStatistics}/>
                <Route path='/imc/login' component={ImcSetfx}/>
                {/* <Route path='/imc/homepage' component={HomeIMC}/> */}
                <Route path="/" exact component={HomePage}/>
                {/*<Route path="/imc/MisProductos/Detalles/:productId" component={Detalles} />*/}

                <PrivateRoute path="/imc/MisUsuarios" component={MisUsuarios}/>
                <PrivateRoute path="/imc/MisProductos/Detalles/SubDetalles/:userId" component={SubDetalles}/>
                <PrivateRoute path="/imc/MisProductos" component={MisProductos}/>
                <PrivateRoute path="/imc/MisSolicitudes" component={MisSolicitudes}/>
               {/* <Route path="/imc/ChangePassword/:id/:nombre/:email" component={ChangePasswordIMC}/> */}
               <Route path="/imc/ChangePassword/:id" component={ChangePasswordIMC}/>
                <Redirect to="/"/>
            </Switch>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkLogin: () => dispatch(authActions.checkLogin())
    };
};


export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);


