import React, {useState,Component} from 'react';
import {HttpNode} from '../../../axiosInstances';
import ReactApexChart from 'react-apexcharts'

class AverageChart extends React.Component{
    constructor(props){ //Metodo principal del componente de la grafica
        super(props);  //invocacion del metodo anterior 
        this.state = {  // Estado del metodo el cual se evocará cuando se renderize la grafica 
          series: [{ // Configuracion de el eje Y con los datos denominados 'series'
            name: 'Cotización USD/COP',// Nombre de la linea princiapl de la grafica 
            data: [], //Arreglo de los datos del eje Y
            stroke: {// la línea que se dibuja alrededor de los bordes
                show: true, //Desactivar o Activar la anterior funcion
                colors: ['#008FFB']// Coolor de la linea
              }
          },
          ],
          options : {// Configuracion del eje X y demas datos de la grafica
            series: [],// Es necesario invocar la anterior configuracion
            chart: {// Configuracion general de la grafica
            height: 350,//Tamaño de la grafica
            type: 'line', // Tipo de grafico en este caso es de lineas
              fill: {// Relleno de las lineas de la grafica
                type: 'gradient',// En este caso se busca que el relleno sea degradado
                gradient: {// Configuracion del degradado
                  shadeIntensity: 1,// Intensidad del degradado
                  inverseColors: false,// Invertir colores en este caso esta desactivada dicha funcion
                  opacityFrom: 0.5,// Porcentaje desde donde comienza el degradado
                  opacityTo: 0,// Porcentaje hasta donde llega el degradado
                  stops: [0, 90, 100]// Porcentaje de los puntos donde se realizan los cambios de color
                },
              },
              toolbar: {// Configuracion de la barra de herramientas que se ubican en la parte superior de derecha de la grafica 
      show: true,// Desactivar o Activar la anterior configuracion
      tools: {// Configuracion de cada herramienta de la barra
        download: false // f
      }
    },
    zoom: {// Configuracion de la herramienta de zoom
        enabled: true//Activa el botón de descarga
      },
      pan: {// Configuracion de la herramienta de movimiento y/o interaccion de la grafica
        enabled: true//Activa el botón de descarga
      }
    }, 
          yaxis: { //Configuracion del eje Y de la grafica
            grid: {// Lineas del fondo de la grafica 
              show: false  // aquí establecemos la propiedad "show" en "false" para ocultar las líneas del fondo del eje Y
            }
          },
          xaxis:{//Configuracion del eje X de la grafica
            type: 'category',// Tipo de configuracion de las marcas del eje X, en este caso es de agrupacion 
            labels: {// Marcas del eje X
              rotate: -90// Orientacion de las marcas del eje X, para este caso se ubican de forma completamente horizontal 
            },
            tickAmount: 15// Cantidad de marcas que se muestran en la grafica 
            },
          colors: ['rgba(75, 192, 192, 0.4)',"#0077ac", "#c82b2b"],// Colores de las lineas de la grafica
          dataLabels: {// Subtitulos del eje X
            enabled: false// desactiva la anterior configuracion 
          },
          stroke: {// Configuracion de las lineas de la grafica
            curve: ['straight','straight','straight'],// Curvatura de la lineas en este caso es rigido
            width:  [1.5,1.2,1.2],// Grosor de la lineas de la grafica 
            color: ['#a68537','#a68537','#a68537']// Colores las lineas de la grafica
          },
          yaxis: {// Configuracion del eje Y
            tooltip: {// Caja que muestra el precio y las fechas desplegable
              enabled: true// Activar la anterior configuracion 
            }
          },
          tooltip: {// Caja que muestra el precio y las fechas desplegable
            yx: { //Eje X y Y de la grafica
              format: 'dd/MM/yy '// Formato de fecha en la que se mostrara en la grafica 
            },
            style: {// Estilos de la caja desplegable 
              fontSize: '12px',// Tamaño de la letra de la caja 
              width: '100px',// Altura de la caja 
              height: '50px'// Ancho de la caja 
            }
          },
          },
      }
        }

//-----------------------------------------CONFIGURACION DE LA API-------------------------------------------//
        componentDidMount(){
            if(window.innerWidth <= 600){
              this.setState({mobile: true})
            }
            this.mountChart();
          }
          componentDidUpdate(prevProps){
              if(prevProps.period !== this.props.period || prevProps.market !== this.props.market){
                  this.mountChart();
              }
          }
          mountChart(){  
              if(this.chart != null){
                  this.chart.destroy();
              }
              let market = this.props.market ? this.props.market : 71; // default 71
              let period = this.props.period ? this.props.period : '5d'; // default 5 days
              HttpNode.post(`seticap/api/graficos/graficoPromedios `, {mercado: market, moneda: 'USD/COP', periodo: period}).then(
                  response => {
                      const responsedata = response.data.data.data.data;  // this is hilarious
                      
                      const quotedata = responsedata.datasets.length ? responsedata.datasets[0].data : []// Arreglo con los datos del precio 
                      const average_eight = responsedata.datasets.length > 1 ? responsedata.datasets[1].data : []// Arreglo con los datos de la media movil (8)
                      const average_thirteen = responsedata.datasets.length > 2 ? responsedata.datasets[2].data : []// Arreglo con los datos de la media movil (13)
                      const labels = responsedata.datasets.length ? responsedata.labels : []// Arreglo con las Fechas/horas    
                      const uniqueData = labels.map((value, index) => `${value}- ${index+1}`);// Nueva constante con el arreglo de fechas/horas mapeado con un dato unico para hacerlo distinto 

//--------------------NOTA-------------------------//
//                                                  |
//  Es importante tener en cuenta que para realizar |
//  la configuracion de estas graficas con la       |
//  libreria de ApexChart se debe manejar el        |
//  formato X y Y, en el cual se ubicaran en la     |
//  variable X las marcas, fechas, horas y en la    |
//  variable Y los datos que se desean graficar     | 
//  adicionalmente se debe manejar la estructura    |
//  de mapeo (.map) para agregar los datos a las    |
//  nuevas constantes a utilizar, como en el        |
//  siguiente ejemplo:                              |
//                                                  |
//-------------------------------------------------//
                      
                      const quotedataDATA = labels.map((label, index) => ({//Configuracion de la constante para crear las nuevas Fechas/horas
                        x: labels[index],// Configuracion del eje X con los datos del arreglo fechar/horas
                        y: quotedata[index]// Configuracion del eje Y con los datos del precio de dolar 
                      }));
                      const average_eightDATA = labels.map((label, index) => ({//Configuracion de la constante para crear las datos de la media movil (8)
                        x: labels[index],//Configuracion del eje X con los datos del arreglo fechar/horas
                        y: average_eight[index]// Configuracion del eje Y con los datos del precio de dolar 
                      }));
                      const average_thirteenDATA = labels.map((label, index) => ({//Configuracion de la constante para crear las datos de la media movil (13)
                        x: uniqueData[index],//Configuracion del eje X con los datos del arreglo fechar/horas
                        y: average_thirteen[index]// Configuracion del eje Y con los datos del precio de dolar 
                      }));

                      this.setState({// Configuracion del estado anteriormente establecido en el constructor
                        chart: {//Configuracion general de la grafica
                          height: 350,// Tamaño de la grafica 
                          type: 'area', // tipo de grafico para la linea principal del precio del dolar
                          yaxis: {// Configuracion del eje Y
                            gridLines: {// Lineas del fondo de la grafica
                              show: false  // aquí establecemos la propiedad "show" en "false" para ocultar las líneas del fondo del eje Y
                            }
                          },
                      
                        },
                          series: [{// Configuracion de los datos principales de la grafica
                              name: 'Cotización USD/COP',// Nombre la linea del precio del dolar 
                              data: quotedataDATA,// Invocacion de la constante anteriormente declarada para la cotizacion de dolar 
                              type: "area",
                            },{
                              name:  'Media móvil (8)',// Nombre la linea de la media movil
                              data: average_eightDATA ,// Invocacion de la constante anteriormente declarada para la media movil (8)
                              type: "line"
                            },{
                              name: 'Media móvil (13)',// Nombre la linea de la media movil
                              data: average_thirteenDATA ,// Invocacion de la constante anteriormente declarada para la media movil (13)
                              type: "line"//
                            },                            
                            ],
                            xaxis:{ //Eje X de la grafica
                              type: 'category',// Tipo de configuracion de las marcas del eje X, en este caso es de agrupacion 
                              labels: {// Marcas del eje X
                                rotate: -90//Orientacion de las marcas del eje X, para este caso se ubican de forma completamente horizontal 
                              },
                              tickAmount: 12,// Cantidad de marcas que se muestran en la grafica 
                              
                              },
                       })
                  }
              )
          }

//--------------------NOTA-------------------------//
//                                                  |
//  Para renderizar la grafica es necesario usar un |
//  contendedor con la libreria ReactApexchart      |
//  posteriormente usar los estados que declaramos  |
//  al incio del codigo 'this.state', y usar las    |
//  dos principales configuraciones de la grafica   |
//  series y options, adicionalmente si se desea    | 
//  realizar configuraciones de estilo.             |
//                                                  |
//-------------------------------------------------//

          render() {
            return (
          <div id="AverageChart">
          <ReactApexChart 
          options={this.state.options} 
          series={this.state.series} 
          type="line"   
          height={375} />
          </div>
            );
          }
}
export default AverageChart;



