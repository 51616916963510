import React, { useEffect, useState } from "react"
import { AppBarReciclable } from "../../AppBar"
import { MaterialReactTable } from "material-react-table"
import { Box, Button, Fade, Modal, Stack, TextField, ThemeProvider, Typography, createTheme } from "@mui/material"
import { HttpNode } from "../../../../../axiosInstances"
import swal from "sweetalert"
import { useHistory } from "react-router-dom";
import { MRT_Localization_ES } from 'material-react-table/locales/es'; //lenguaje
import { useParams } from 'react-router-dom';



const SubDetalles = () => {

  const history = useHistory();
  const { userId } = useParams();
  const [profileId, setProfileId] = useState(null);
  const [pantallasDisponibles, setPantallasDisponibles] = useState([]);
  // Define el estado para los requisitos del perfil
  const [dataRequisitosPerfil, setDataRequisitosPerfil] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [ENVIOCAMBIODATOS, setENVIOCAMBIODATOS] = useState('')
  const [entidadCambio, setEntidadCambio] = useState(null);
  const [paqueteCambio, setPaqueteCambio] = useState(null);
  const [numeroCambio, setNumeroCambio] = useState(null);
  const [perfilCambio, setPerfilCambio] = useState(null);
  const [pantallaCambio, setPantallaCambio] = useState(null);


  console.log("Este es el id del usuario para señalar la fila", userId);
  const [open, setOpen] = useState(false);




  const [PERFILID, setPERFILID] = useState('')
  const [PANTALLA, setPANTALLA] = useState('')
  const handleOpen = (rowData) => {
    fetchProfile(rowData.perfil_id)
    setOpen(true)
    //Vincular(rowData)//en este deberiamos ponerlo en la modal 
    console.log(rowData)
    //localStorage.setItem("perfil_id",rowData.perfil_id)
    setPERFILID(rowData.perfil_id)
    console.log(rowData.perfil_id)
    //localStorage.setItem("pantalla",rowData.pantalla)
    setPANTALLA(rowData.pantalla)
    console.log(rowData.pantalla)
  }

  const handleClose = () => {
    setOpen(false)
  }

  //abir y cerrar la modal en cambiar de pantalla
  const [openCambio, setOpenCambio] = useState(false);
  const [USUARIOCAMBIOID, setUSUARIOCAMBIOID] = useState(null)
  const handleOpenCambio = (rowData) => {

    const entityId = localStorage.getItem('entidadId');

    fetchProfile(rowData.perfil_id)

    const perfilId = rowData.perfil_id
    setOpenCambio(true)

    //Vincular(rowData)//en este deberiamos ponerlo en la modal 
    console.log(rowData)
    //localStorage.setItem("perfil_id",rowData.perfil_id)
    setPERFILID(rowData.perfil_id)
    console.log(rowData.perfil_id)
    fetchPantallasDisponibles(entityId, perfilId);
    //localStorage.setItem("pantalla",rowData.pantalla)
    setUSUARIOCAMBIOID(rowData.usuario_id);
    setSelectedScreen(rowData.pantalla)
    console.log(rowData.pantalla)
  }
  const handleCloseCambio = () => {
    setOpenCambio(false);

  };

  const [SubDetails1, setSubDetails] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1250,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
  };



  useEffect(() => {
    let isMounted = true
    async function fecthSubDetails() {
      try {
        const entityId = localStorage.getItem('entidadId');
        const NumFila = localStorage.getItem("NumFila")//dato que proviene de Detalles

        // Si NumFila no existe en localStorage, redirige al usuario a la página de detalles
        if (!NumFila) {
          history.push('/imc/MisProductos');
          return;
        }
        const response = await HttpNode.get(`/setfx/entidades/${entityId}/suscripciones/${NumFila}`)
        const SubDetails = response.data.data


        if (isMounted) {
          if (SubDetails && SubDetails.length > 0) {
            setSubDetails(SubDetails)
            //setUsuarioId(SubDetails[0].usuario_id);
            console.log("HOLAAAAAA pude extraer el id del usuario: ", SubDetails[0].usuario_id)
            const perfilId = SubDetails[0].perfil_id;
            setProfileId(perfilId); // Almacenar el perfilId en el estado
            //fetchProfile(perfilId);

            console.log('estos son los datos de los SUBDETALLES', SubDetails, "Y este es el id del PERFIL", perfilId);
            //console.log("datos de los detalles por producto ", SubDetails);
          } else {
            console.log("No se encontraron detalles");
          }
        }
      } catch (error) {
        console.log(error)
        console.error(error.message);
        return swal({ title: 'Ha ocurrido un error', text: error.message, icon: 'error' })
      }
    }
    fecthSubDetails();
    return () => {
      isMounted = false;
    };
  }, [])




  //Obtener requsitos del perfil



  const [dataFormat, setDataFormat] = useState([]);

  async function fetchProfile(profileId) {

    try {
      const response = await HttpNode.get(`/setfx/requisitos/perfil/${profileId}`);
      const data = response.data.data;
      setDataFormat(data);
      //localStorage.setItem("datos de cada PERFIL: ", JSON.stringify(data));
      console.log('datos de los perfiles: ', data);
      console.log('CURRENT perfil_id: ', profileId);

    } catch (error) {
      console.error(error);
      if (error.response.status >= 500 && error.response.status <= 599) {
        console.error(error.message);
        return swal({ title: 'Ha ocurrido un error', text: 'Error en el servidor, por favor comuniquese con el equipo de soporte', icon: 'error' })
      }
      else if (error.response.status >= 400 && error.response.status <= 499) {
        return swal({ title: 'Ha ocurrido un error', text: error.message, icon: 'error' })
      }
    }
  }
  //Obtener las pantallas disponibles 

  async function fetchPantallasDisponibles(entidadId, perfilActual) {
    try {
      const response = await HttpNode.get(`/setfx/entidades/${entidadId}/pantallas/disponibles?perfilActual=${perfilActual}`,);
      const pantallasDisponibles = response.data.data;
      console.log('Pantallas disponibles:', pantallasDisponibles);

      // if (pantallasDisponibles.length === 0) {
      //   // Si el array está vacío, mostrar un mensaje y detener el flujo de la función
      //   return swal({
      //     title: 'No es posible cambiar la pantalla',
      //     text: 'La pantalla ya tiene un proceso pendiente.',
      //     icon: 'warning',
      //   });
      // }
      console.log('CURRENT CURRENT PERFIL', perfilActual);
      setPantallasDisponibles(pantallasDisponibles);
      const entidad = response.data.data[0].entidad_id;
      setEntidadCambio(entidad)
      const paquete = response.data.data[0].paquete_id;
      setPaqueteCambio(paquete)
      const numero = response.data.data[0].numero;
      setNumeroCambio(numero)
      console.log("el numero del paquete: ", numero);
      const perfil = response.data.data[0].perfil_id;

      setSelectedRow(perfil)
      console.log("El perfil es uuuuuuuuuuuuu:", perfil);
      const pantalla = response.data.data[0].pantalla;
      setPantallaCambio(pantalla)

      // Puedes hacer lo que necesites con las pantallas disponibles, por ejemplo, almacenarlas en el estado.
    } catch (error) {
      console.error(error);
      if (error.response.status >= 500 && error.response.status <= 599) {
        console.error(error.message);
        return swal({ title: 'Ha ocurrido un error', text: 'Error en el servidor, por favor comuníquese con el equipo de soporte', icon: 'error' });
      } else if (error.response.status >= 400 && error.response.status <= 499) {
        return swal({ title: 'Ha ocurrido un error', text: error.message, icon: 'error' });
      }
    }
  }


  // Función para obtener requisitos de un perfil específico
  async function fetchRequisitosPerfil(perfil_id) {
    try {
      const response = await HttpNode.get(`/setfx/requisitos/perfil/${perfil_id}`);
      const requisitosPerfil = response.data.data;
      console.log('Requisitos del perfil:', requisitosPerfil);
      // Ahora puedes almacenar requisitosPerfil en el estado o mostrarlo en la tabla MaterialReactTable
      setDataRequisitosPerfil(requisitosPerfil);
    } catch (error) {
      console.error(error);
      // Manejo de errores...
    }
  }

  //obtener requisistos del perfil cuando se quiere cambiar de pantalla
  function handleRowClick(perfil_id, pantalla, numero, paquete_id) {
    fetchRequisitosPerfil(perfil_id);
    setSelectedRow(perfil_id);
    setSelectedScreen(pantalla);
    setNumeroCambio(numero)
    setPaqueteCambio(paquete_id)

  }
  //INICIO FUNCIONES DE LOS BOTONES
  function Desvincular(perfil_id, pantalla) {//estos valores pribienen de la fila que se selecciona
    swal({ title: 'Solicitud de desvinculación de pantalla.', text: `¿esta seguro de continuar?.`, icon: 'warning', buttons: ['No', 'Si'] })
      .then(DesvinculacionPantalla => {
        if (DesvinculacionPantalla) {
          async function Desvinculacion() {
            let entidad_id = localStorage.getItem("entidadId")//este dato biene de imc_setfx.js
            console.log("Entidad: ", entidad_id)
            let paquete_id = localStorage.getItem("paquete_id")//este dato biene de Detalles.js
            console.log("el id del paquete: ", paquete_id)
            let numero = localStorage.getItem("numero")//este dato biene de Detalles.js
            console.log("el numero del paquete: ", numero)
            console.log("El pefil id del paquete: ", perfil_id)
            console.log("La pantalla del paquete: ", pantalla)
            let administrador_id = localStorage.getItem("id_usr")//este dato biene de imc_setfx.js
            console.log("id: ", administrador_id)
            const desvincular = {
              entidad_id: parseFloat(entidad_id),
              paquete_id: parseFloat(paquete_id),
              numero: parseFloat(numero),
              perfil_id: perfil_id, //no utilizamos parseFloat en estos valores ya que bienen desde la funcion que lo llama y no lo obtenemos en el localStorage
              pantalla: pantalla, //no utilizamos parseFloat en estos valores ya que bienen desde la funcion que lo llama y no lo obtenemos en el localStorage
              administrador_id: parseFloat(administrador_id),
            }
            console.log("Datos a desvincular: ", desvincular)
            try {
              const response = await HttpNode.post('/setfx/entidades/solicitud/liberar', {
                ...desvincular
              })
              if (response) {
                console.log('Valor de response: ', response);
              }
              swal({
                title: 'Solicitud Enviada.',
                text: 'Solicitud de desvinculación enviada con éxito.',
                icon: 'success',
                button: 'OK'
              })
                .then(() => {
                  history.push('/imc/MisSolicitudes');
                })
            } catch (error) {
              console.error(error);
              if (error.response && error.response.status === 500 && error.response.data && error.response.data.message) {
                // Si la respuesta es un error 500 y tiene un mensaje en la propiedad 'message', muestra ese mensaje
                const errorMessage = error.response.data.message;
                console.error(errorMessage);
                return swal({
                  title: 'Error!...',
                  text: errorMessage,
                  icon: 'error',
                  button: 'OK'
                });
              } else {
                // Si no se cumple la condición anterior, simplemente muestra el mensaje de error genérico
                console.error(error.message);
                return swal({
                  title: 'Error!...',
                  text: error.message,
                  icon: 'error',
                  button: 'OK'
                });
              }
            }
          }
          Desvinculacion()
        }
      })
  }

  function Tramitacion() {
    swal({
      title: "Tramitando",
      text: "La solicitud de esta pantalla esta en curso.",
      icon: "warning",
    })

  }//FIN FUNCIONES DE LOS BOTONES

  function Botones(data) {
    if (data.estatus === "disponible") {
      return <Button variant='contained' onClick={() => handleOpen(data)}>Vincular</Button>
    }
    else if (data.estatus === "asignada") {
      return <Button variant='contained' sx={{ bgcolor: '#ff3d00', '&:hover': { bgcolor: '#b22a00' } }} onClick={() => Desvincular(data.perfil_id, data.pantalla)}>Desvincular</Button>
    }
    else if (data.estatus === "transito") {
      return <Button variant='contained' sx={{ bgcolor: '#a7a7a7', '&:hover': { bgcolor: '#666666' } }} onClick={Tramitacion}>Tramitando</Button>
    }
  }



  //Usuarios seleccionables

  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    async function selectableUsers() {

      try {
        const entityId = localStorage.getItem('entidadId');
        const response = await HttpNode.get(`/setfx/entidades/${entityId}/usuarios/asignables`);
        const data = response.data.data;
        localStorage.setItem("datos", JSON.stringify(data)); // Convertir a JSON antes de almacenar //no se esta utilizado alpaarecer
        console.log('datos de usar > ios asignables', data);
        if (isMounted) {
          if (data && data.length) {
            console.log('ESTOS SON LOS DATOSSSS: ', data)
            setData(data);
          } else {
            console.log("No se encontraron usuarios seleccionables");
          }
        }
      } catch (error) {
        console.log(error);
        return swal({
          title: 'UpsPS!!!',
          text: 'Ha ocurrido un error en el servidor, intentalo mas tarde',
          icon: 'error'
        })
      }
    }



    if (isMounted) {
      selectableUsers();
    }

    return () => {
      isMounted = false;
    };
  }, []);// <- Arreglo de dependencias vacío para ejecutar el efecto solo una vez
  //Inicio fondo color fila
  const [clickedRow, setClickedRow] = useState(null)
  const handleClick = (id) => {
    console.info("linea 245 id:", id)
    setClickedRow(id)
    //setUsuarioId(id); 
  }
  //Fin fondo color fila


  // Lógica para resaltar la fila




  const columns = [
    {
      accessorKey: 'pantalla',
      header: 'Pantalla',
    },
    {
      accessorKey: 'perfil',
      header: 'Perfil'

    },
    {
      accessorKey: 'username',
      header: 'Usuario',
      // Supongamos que 'id' es el identificador único de cada fila

    },
    {
      accessorKey: 'usuario',
      header: 'Nombre',
      size: 400 //tamaño de la columna
    },
    {
      accessorKey: 'estatus_pantalla',
      header: 'Estatus'
    },
    {
      accessorKey: 'cell',
      header: 'Acción',
      Cell: ({ row }) => (Botones(row.original)) //Enviamos el contenido y/o informacion de la fila seleccionada
    },
    {
      header: 'Cambiar',
      Cell: ({ row }) => {
        if (row.original.estatus === "asignada") {
          return (
            <>
              <Button
                sx={{ fontSize: "11px" }}
                variant='contained'
                color='success'
                onClick={() => handleOpenCambio(row.original)}
              >
                Cambiar
              </Button>
            </>
          );
        } else {
          return null; // Si no es "asignada", no mostrar el botón Cambiar
        }
      },
    },
  ]

  const muiTopToolbarProps = {
    sx: {
      height: '55px',
      '& .MuiIconButton-root svg': {//dentro del div seleccione MuiIconButton-root y dentro de ese componente traiga los svg y le agregue estilos
        fontSize: '29px'
      },
      '& .MuiInputBase-root': {//search
        fontSize: '17px'
      },
      '& .MuiInputBase-root svg': {//icono de lupa con una x
        fontSize: '22px'
      },
      '& .MuiButtonBase-root': {
        fontSize: '34px'
      },
    },
  };
  const muiBottomToolbarProps = {
    sx: {
      height: '60px',
      '& .MuiIconButton-root svg': {//iconos del header de la tabla
        fontSize: '22px'
      },
      '& .MuiTablePagination-selectLabel': {//Rows per page
        fontSize: '15px'
      },
      '& .MuiSelect-standard': {//arreglo
        fontSize: '15px',
        alignItems: 'center',
        display: 'flex',
      },
      '& .MuiSvgIcon-fontSizeMedium': {//icono del arreglo
        fontSize: '15px'
      },
      '& .MuiTablePagination-displayedRows': {//1-10 of 28
        fontSize: '15px'
      },
    }
  }

  const muiTableProps = {
    sx: {
      '& .MuiTableCell-root svg': {
        fontSize: '25px',
      },
    }
  }
  const muiTableProps2 = {
    sx: {
      '& .MuiTableCell-root svg': {
        fontSize: '25px',
      },
      paddingBottom: '10rem'
    }
  }
  const tableTheme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.5rem', //override to make tooltip font size larger, tooltips
              },
            },
          },
          MuiTableCell: {//celdas de la tabla
            styleOverrides: {
              root: {
                fontSize: '15px', // Ajusta el tamaño de fuente de las celdas de la tabla
              },
            },
          },
        },
        typography: {//edicion de enableEditing, la lista del arreglo(despues del "Rows per page") y el Show/Hide columns(tercer icono parte superior)
          button: {//"botones" de enableEditing
            fontSize: '12px',
          },
          body1: {//formulario y lista del arreglo, si se coloca "body2" no se aplican los estilos
            fontSize: '14px'
          },
          h6: {//titulo de enableEditing(dentro de la clase de MaterialReactTable la clase del titulo de enableEditing esta definido como 'MuiTypography-h6' por ende y segun mi logica se coloca 'h6')
            fontSize: '20px'
          }
        }
      }),
    [],
  );


  //Inicio filtro buscar
  const [search, setSearch] = useState('');
  const handleChange = (event) => {
    setSearch(event.target.value);
  };
  const filtroSecundario = data.filter((buscar) => {
    if (buscar.nombre.toUpperCase().includes(search.toUpperCase())) {
      return true;
    }
    return false;
  });
  //Fin filtro buscar
  const [REQUISITOID, setREQUISITOID] = useState([])
  const [VALORES, setVALORES] = useState([])
  const [OBJETO, setOBJETO] = useState([])
  //const [REQUERIDO, setREQUERIDO] = useState('')
  function validar(inputElement, rowData) { //inputElement es el elemento input que se está validando, y rowData contiene los datos de la fila asociada a ese elemento
    const archivoValor = inputElement.value  // Obtiene el valor del elemento input, que es el nombre del archivo seleccionado.

    console.log("id de requisito_id: ", rowData.requisito_id)
    setREQUISITOID((prevID) => [...prevID, rowData.requisito_id])
    console.log("Valor del input: ", archivoValor)

    const extension = archivoValor.substring(archivoValor.lastIndexOf('.'), archivoValor.length); // Obtiene la extensión del archivo seleccionado.
    //const formato_requerido = rowData.formato; // Obtiene el formato requerido desde los datos de la fila.
    console.log("La extensión es: ", extension);
    if (extension !== ".pdf" && extension !== ".xls" && extension !== ".xlsx" && extension !== ".csv" && extension !== ".doc" && extension !== ".docx" && extension !== ".png" && extension !== ".bmp" && extension !== ".jgp" && extension !== ".jpeg" && extension !== ".docm" && extension !== ".dot" && extension !== ".dotm" && extension !== ".dotx" && extension !== ".acrobatplugin" && extension !== ".pdfa" && extension !== ".pdfx" && extension !== ".fdf" && extension !== ".pdx" && extension !== ".xlsm" && extension !== ".xlsb" && extension !== ".xltx" && extension !== ".xltm" && extension !== ".xlt" && extension !== ".xml" && extension !== ".xlam" && extension !== ".xla" && extension !== ".xlw" && extension !== ".xlr") {
      inputElement.value = ''
      swal({
        title: "Archivo no valido!!",
        text: `Selecciona el tipo de formato que se requiere: PDF, WORD, EXCEL, IMAGEN`,
        icon: "error",
        button: "OK",
      })
      setVALORES((prevValues) => [...prevValues, archivoValor])
      const objeto = { requisito_id: rowData.requisito_id, archivo: '' }//podemos llamar esta funcion con el valor vacio para que el archivo o los datos queden vacios en el if
      setOBJETO((prevOBJETO) => [...prevOBJETO, objeto])
    }
    else {
      console.log("xd: ", inputElement.files[0])
      const archivo = inputElement.files[0]
      console.log('Tamaño de archivo: ', archivo.size)//el tamaño del archivo esta en byte(archivo.size trae el tamaño como byte asi que toca cambiarlo a megabyte)
      let tamanio = archivo.size / 1024 / 1024 //este es el tamaño del archivo
      console.log('prueba: ', tamanio)//de byte a megabyte
      if (tamanio > 0.5) {
        inputElement.value = "";
        swal({
          title: "Archivo muy grande!!",
          text: `El archivo excede el tamaño máximo permitido de 500 KB.`,
          icon: "warning",
          button: "OK",
        })
      }
      else {
        setVALORES((prevValues) => [...prevValues, archivoValor])
        const objeto = { requisito_id: rowData.requisito_id, archivo: archivo }//podemos llamar esta funcion con el valor vacio para que el archivo o los datos queden vacios en el if
        setOBJETO((prevOBJETO) => [...prevOBJETO, objeto])
        //console.log("requerido: ", rowData.requiere_archivo)
        //setREQUERIDO(rowData.requiere_archivo)
      }
    }

  }

  const columnasUsuarios = [

    {
      accessorKey: 'nombre',
      header: 'Usuario',
    }
  ]

  const columnasFormatos = [
    {
      accessorKey: 'nombre',
      header: 'Requisito',
    },

    {
      header: 'Data',
      Cell: ({ row }) => <input multiple type="file" accept=".pdf,.docx,.xlsx" onChange={(event) => validar(event.target, row.original)} />, // pasamos directamente event.target como argumento a la función validar. Esto asegura que cada vez que se cambia el valor del elemento input, se pasa el elemento correcto a la función sin necesidad de clases o identificadores únicos, podemos utilizar la propiedad de value={requiere_archivo} este devuelve true o false que trae la informacion y la validamos en for con un console.log(input.defailtValue)
    }
  ]
  const [USUARIOID, setUSUARIOID] = useState('')
  const [ENVIODATOS, setENVIODATOS] = useState('')
  //Inicio funcion del envio de pantalla
  function Envio() {
    const inputs = document.querySelectorAll("input[type=file]")// seleccionamos todos los inputs que sean tipo file
    console.log(inputs)//mostramos por consola todos los inputs tipo file
    for (const input of inputs) {//aqui la constante input toma el valor de los inputs, es decir que ahora el input obtiene todos los atributos y valores individuales de los inputs
      console.log(input.value)//mostramos por consola los valores de input
      if (!input.value) {//si el valor de input.value es una vadena vacia '' -> (false) entonces con el operador ! de negacion es true(esto cambia el valor false a true), entonces si if(true){} se ejecutara el codigo que esta adentro de if
        swal({
          title: "Alto!!",
          text: 'Uno o mas campos requeridos estan vacios, por favor, selecciona un archivo',
          icon: "warning",
          button: "OK",
        })
        return; // Detiene la función si al menos un input está vacío, es decir que hasta aqui se ejecuta la funcion Envio si solo un input esta vacio.
      }
    }
    let TextField = document.getElementById("miTextFiel")//el text field de las observaciones 
    const usuario = ENVIODATOS//localStorage.getItem("datosEnvio")//linea 642, utilizamos el estado de setENVIODATOS
    //console.log(usuario)
    if (usuario === '') {//cuando quitamos el valor de localStorage para este caso pasa de ser null a un campo vacio ''
      swal({
        title: "Alto!!",
        text: 'No has asignado un usuario',
        icon: "warning",
        button: "OK",
      })
    }
    else {
      swal({
        title: "Estas seguro(a)?",
        text: `Estas a punto de elegir a ${usuario} para la asignacion de la pantalla`,
        icon: "warning",
        buttons: true,
      })
        .then((Envio) => {
          if (Envio) {
            async function post() {
              //hacer y/o crear un localStorage los requisitos que nos falta, (es decir este metodo post se requiera de barias partes de oras ap para funcionar)
              let entidad_id = localStorage.getItem("entidadId")//este dato biene de imc_setfx.js
              console.log("Entidad: ", entidad_id)
              let administrador_id = localStorage.getItem("id_usr")//este dato biene de imc_setfx.js
              console.log("id: ", administrador_id)
              let paquete_id = localStorage.getItem("paquete_id")//este dato biene de Detalles.js
              console.log("el id del paquete: ", paquete_id)
              let numero = localStorage.getItem("numero")//este dato biene de Detalles.js
              console.log("el numero del paquete: ", numero)
              let perfil_id = PERFILID //localStorage.getItem("perfil_id")//Este dato proviene de la linea 60
              console.log("El pefil id del paquete: ", perfil_id)
              let pantalla = PANTALLA//localStorage.getItem("pantalla") //Cambiamos el localStorage por el estado que contiene setPANTALLA
              console.log("La pantalla del paquete: ", pantalla)//Este dato proviene de la linea 62
              let usuario_id = USUARIOID //localStorage.getItem("usuario_id")
              console.log("Usuario al que se va asignar", usuario_id)//Este dato proviende de la linea 488
              console.log("Observaciones: ", TextField.value)
              //ahora creamos un objeto que contenga los valores de los localStorage que tenemos, pero el localStorage convierte cualquier objeto(numero, string, array, object, class) en texto
              //en esta ocacion utilizamos parseFloat para convertir los numero que estan en texto pasarlos a numero(es decir quitarle las comillas dobles y/o simples)
              const datosEnvio = {
                entidad_id: parseFloat(entidad_id),
                paquete_id: parseFloat(paquete_id),
                numero: parseFloat(numero),
                perfil_id: parseFloat(perfil_id),
                pantalla: parseFloat(pantalla),
                administrador_id: parseFloat(administrador_id),
                usuario_id: parseFloat(usuario_id),
                observaciones: TextField.value
              }
              console.log("datos para el post: ", datosEnvio)
              try {
                swal({ title: 'Cargando.......', text: 'Espera mientras se procesa la solicitud.', buttons: false })
                const response = await HttpNode.post(`/setfx/entidades/solicitud/asignar`,
                  { ...datosEnvio }
                )
                console.log("Utilizando el metodo await: ", response.data.id)//utilizar esta respuesta para hacer el envio de archivos
                // response.then(resultado =>{
                //  const id = resultado.data.id
                //  console.log('utilizando el metodo then: ',id)
                // })
                const id = response.data.id //el numero de la solicitud enviada
                console.log("---------------------------")//INICIO ENVIO DOCUMENTOS
                console.log('requisito id: ', REQUISITOID)
                console.log('valores de los inputs: ', VALORES)
                console.log('objeto: ', OBJETO);
                for (const input of OBJETO) {
                  console.log(input.requisito_id);
                  const requisitoId = input.requisito_id; // Actualizar el valor de requisitoId en cada iteración
                  console.log('esto son los valores', input.archivo);
                  const value = input.archivo;
                  const formData = new FormData();
                  formData.append('fupload', value);
                  console.log("este es el form-data", formData)
                  try {
                    const response = await HttpNode.post(`/setfx/solicitudes/${id}/requisito/${requisitoId}`, formData);
                    if (response.status === 200) {
                      console.log('Archivo cargado exitosamente ', response);
                    } else {
                      swal({
                        title: 'ERROR!!!',
                        text: 'El cargue falló',
                        icon: 'error'
                      });
                    }
                  } catch (error) {
                    console.log('error al envio de archivos: ', error.response);
                    console.error(error.message);
                    return swal({
                      title: 'ERROR!!!',
                      text: 'Error en el servidor, por favor comuniquese con el equipo de soporte',//`${error.response.data.message}`,
                      icon: 'error'
                    });
                  }
                }
                console.log("---------------------------")//FIN ENVIO DOCUMENTOS
                if (response) {
                  console.log('Valor de response: ', response);
                }
                swal({
                  title: 'Solicitud Enviada.',
                  text: 'Solicitud enviada con exito.',
                  icon: 'success',
                  button: 'OK'
                })
                  .then(() => {
                    history.push('/imc/MisSolicitudes');
                  })
              } catch (error) {
                console.log(error)
                console.log(error.response.data.message)
                console.error(error.message);
                return swal({
                  title: 'Error!...',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  button: 'OK'
                })
              }
            }
            post()
          } else {
            return swal({
              text: "Selecciona otro usuario",
              icon: 'info'
            });
          }
        });
    }
  }

  //cargar requsitos para cambio de pantalla



  //Inicio funcion del envio de pantalla
  function EnvioCambio() {
    const inputs = document.querySelectorAll("input[type=file]")// seleccionamos todos los inputs que sean tipo file
    console.log(inputs)//mostramos por consola todos los inputs tipo file
    for (const input of inputs) {//aqui la constante input toma el valor de los inputs, es decir que ahora el input obtiene todos los atributos y valores individuales de los inputs
      console.log(input.value)//mostramos por consola los valores de input
      if (!input.value) {//si el valor de input.value es una vadena vacia '' -> (false) entonces con el operador ! de negacion es true(esto cambia el valor false a true), entonces si if(true){} se ejecutara el codigo que esta adentro de if
        swal({
          title: "Alto!!",
          text: 'Uno o mas campos requeridos estan vacios, por favor, selecciona un archivo',
          icon: "warning",
          button: "OK",
        })
        return; // Detiene la función si al menos un input está vacío, es decir que hasta aqui se ejecuta la funcion Envio si solo un input esta vacio.
      }
    }
    let TextField = document.getElementById("miTextFiel")//el text field de las observaciones 
    const usuario = ENVIOCAMBIODATOS//localStorage.getItem("datosEnvio")//linea 642, utilizamos el estado de setENVIOCAMBIODATOS
    console.log("ESTE ES EL ENVIOCAMBIODATOS", usuario)
    if (usuario === '') {//cuando quitamos el valor de localStorage para este caso pasa de ser null a un campo vacio ''
      swal({
        title: "Alto!!",
        text: 'No has asignado un usuario',
        icon: "warning",
        button: "OK",
      })
    }
    else {
      swal({
        title: "Estas seguro(a)?",
        text: `Estas a punto de asignar el usuario a una nueva pantalla `,
        icon: "warning",
        buttons: true,
      })
        // let entidad_id = localStorage.getItem("entidadId")
        // fetchPantallasDisponibles(entidadId, perfilActual)
        .then((EnvioCambio) => {
          if (EnvioCambio) {
            async function post() {
              //hacer y/o crear un localStorage los requisitos que nos falta, (es decir este metodo post se requiera de barias partes de oras ap para funcionar)
              let entidad_id = entidadCambio//este dato biene de imc_setfx.js
              console.log("Entidad: ", entidad_id)
              let administrador_id = localStorage.getItem("id_usr")//este dato biene de imc_setfx.js
              console.log("id: ", administrador_id)
              let paquete_id = paqueteCambio//este dato biene de Detalles.js
              console.log("el id del paquete: ", paquete_id)

              let perfil_id = selectedRow//localStorage.getItem("perfil_id")//Este dato proviene de la linea 60
              console.log("El pefil id del paquete: ", perfil_id)
              // Utilizar await para obtener el número correctamente
              //let numero = await fetchPantallasDisponibles(entidad_id, perfil_id);
              let numero = numeroCambio
              console.log("el numero del paquete: ", numero);
              let pantalla = selectedScreen//localStorage.getItem("pantalla") //Cambiamos el localStorage por el estado que contiene setPANTALLA
              console.log("La pantalla del paquete: ", pantalla)//Este dato proviene de la linea 62
              let usuario_id = USUARIOCAMBIOID//localStorage.getItem("usuario_id")
              console.log("Usuario al que se va asignar", usuario_id)//Este dato proviende de la linea 488
              console.log("Observaciones: ", TextField.value)
              //ahora creamos un objeto que contenga los valores de los localStorage que tenemos, pero el localStorage convierte cualquier objeto(numero, string, array, object, class) en texto
              //en esta ocacion utilizamos parseFloat para convertir los numero que estan en texto pasarlos a numero(es decir quitarle las comillas dobles y/o simples)
              const datosEnvio = {
                entidad_id: parseFloat(entidad_id),
                paquete_id: parseFloat(paquete_id),
                numero: parseFloat(numero),
                perfil_id: parseFloat(perfil_id),
                pantalla: parseFloat(pantalla),
                administrador_id: parseFloat(administrador_id),
                usuario_id: parseFloat(usuario_id),
                observaciones: TextField.value
              }
              console.log("datos para el post: ", datosEnvio)
              try {
                swal({ title: 'Cargando.......', text: 'Espera mientras se procesa la solicitud.', buttons: false })
                const response = await HttpNode.post(`/setfx/entidades/solicitud/reasignar`,
                  { ...datosEnvio }
                )
                console.log("Utilizando el metodo await: ", response.data.id)//utilizar esta respuesta para hacer el envio de archivos
                // response.then(resultado =>{
                //  const id = resultado.data.id
                //  console.log('utilizando el metodo then: ',id)
                // })
                const id = response.data.id //el numero de la solicitud enviada
                console.log("---------------------------")//INICIO ENVIO DOCUMENTOS
                console.log('requisito id: ', REQUISITOID)
                console.log('valores de los inputs: ', VALORES)
                console.log('objeto: ', OBJETO);
                for (const input of OBJETO) {
                  console.log(input.requisito_id);
                  const requisitoId = input.requisito_id; // Actualizar el valor de requisitoId en cada iteración
                  console.log('esto son los valores', input.archivo);
                  const value = input.archivo;
                  const formData = new FormData();
                  formData.append('fupload', value);
                  console.log("este es el form-data", formData)
                  try {
                    const response = await HttpNode.post(`/setfx/solicitudes/${id}/requisito/${requisitoId}`, formData);
                    if (response.status === 200) {
                      console.log('Archivo cargado exitosamente ', response);
                    } else {
                      swal({
                        title: 'ERROR!!!',
                        text: 'El cargue falló',
                        icon: 'error'
                      });
                    }
                  } catch (error) {
                    console.log('error al envio de archivos: ', error.response);
                    console.error(error.message);
                    return swal({
                      title: 'ERROR!!!',
                      text: 'Error en el servidor, por favor comuniquese con el equipo de soporte',//`${error.response.data.message}`,
                      icon: 'error'
                    });
                  }
                }
                console.log("---------------------------")//FIN ENVIO DOCUMENTOS
                if (response) {
                  console.log('Valor de response: ', response);
                }

                swal({
                  title: 'Solicitud Enviada.',
                  text: 'Solicitud enviada con exito.',
                  icon: 'success',
                  button: 'OK'
                })
                  .then(() => {
                    history.push('/imc/MisSolicitudes');
                  });

              } catch (error) {
                console.log(error)
                console.log(error.response.data.message)
                console.error(error.message);



                return swal({
                  title: 'Error!...',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  button: 'OK'
                })
              }
            }
            post()
          } else {
            return swal({
              text: "Selecciona otro usuario",
              icon: 'info'
            });
          }
        });
    }
  }
  //Inicio filtro buscar
  const [search2, setSearch2] = useState('');
  const handleChangeFiltro2 = (event) => {
    setSearch2(event.target.value);
  };
  const filtroPrincipal = SubDetails1.filter((buscar) => {
    if (buscar.usuario.toUpperCase().includes(search2.toUpperCase())) {
      return true;
    }
    return false;
  });
  //Fin filtro buscar



  //inicio de columnas de pantallas disponibles 


  const columnasPantallasDisponibles = [

    {
      accessorKey: 'pantalla',
      header: 'Pantalla',
      size: 100
    },
    {
      accessorKey: 'codigo',
      header: 'Codigo',
      size: 100
    },
    {
      accessorKey: 'numero',
      header: 'Suscripción',
      size: 105
    },
    {
      accessorKey: 'perfil',
      header: 'Perfil',
    }
  ]
  return (
    <>
      <AppBarReciclable />
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) => (<h3 style={{ marginTop: '7px' }}>Sub Detalles</h3>)}
          localization={MRT_Localization_ES}
          data={filtroPrincipal}
          columns={columns}
          initialState={{ pagination: { pageSize: 40 } }}

          muiTopToolbarProps={muiTopToolbarProps}
          muiBottomToolbarProps={muiBottomToolbarProps}
          muiTableProps={muiTableProps}
          muiTableBodyRowProps={({ row }) => ({
            sx: {


              backgroundColor: row.original.usuario_id == userId ? '#eee' : 'white'
            },
          })}
          enableColumnFilters={false} //filtering does not work with memoized table body
          muiSearchTextFieldProps={{
            placeholder: `Buscar por Usuario`,
            sx: { minWidth: '300px' },
            variant: 'outlined',
            size: 'small',
            value: search2,
            onChange: handleChangeFiltro2,
          }}
          enableColumnOrdering={{
            color: '#5b95cf',
          }}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'Editar',//nombre de la columna enableEditing
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 60,
            },
          }}
          muiTablePaperProps={{
            sx: {
              '& .MuiTypography-root': {
                fontSize: '64px'
              }
            }
          }}
          muiTableHeadCellProps={{//encabezado de la tabla
            //easier way to create media queries, no useMediaQuery hook needed.
            sx: {
              fontSize: {
                xs: '20px',
                sm: '11px',
                md: '12px',
                lg: '15px',
                xl: '18px',
              },
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between',//espacio entre los texto y los iconos
              },
              '& .Mui-TableHeadCell-Content svg': {
                fontSize: '24px',
              },
              '& .MuiInputBase-input': {
                fontSize: '17px'
              },
              color:
                '#5b95cf',
            }
          }}
        >
        </MaterialReactTable>
        {/* INICIO MODAL PARA LA VINCULACION */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
        >
          <Fade in={open}>
            <Box sx={style}>
              <h5 style={{ margin: '0px 0px 20px 65px' }}>Usuarios asignables</h5>
              <div style={{ paddingBottom: 35, textAlign: 'center' }}>
                <div style={{ whiteSpace: "nowrap", display: "inline-block", paddingRight: 24 }}>
                  <MaterialReactTable
                    renderTopToolbarCustomActions={({ table }) => (<TextField size="small" value={search} onChange={handleChange} placeholder="Buscar Usuario" />)}
                    initialState={{
                      pagination: { pageSize: 5 }
                    }}
                    muiTableBodyRowProps={({ row }) => ({ //aqui seleccionamos la fila y traemos el usuario_id de data
                      onClick: () => {

                        setUSUARIOID(row.original.usuario_id)
                        console.log('id de la fila segun la API: ', row.original.usuario_id)
                        //localStorage.setItem("datosEnvio",row.original.nombre)//aqui enviamos el nombre para el swal
                        setENVIODATOS(row.original.nombre)
                        //localStorage.setItem("TodoLosDatos",row.original)//aqui enviamos todos los datos para la solicitud(esto era una prueba)
                        console.log(row.original) //aqui comprobamos los datos que estamos mandando
                        handleClick(row.original.usuario_id)
                      },
                      sx: {
                        cursor: 'pointer',
                        backgroundColor: row.original.usuario_id === clickedRow ? '#eee' : 'white'
                      },
                    })}
                    data={filtroSecundario}
                    columns={columnasUsuarios}
                    muiTopToolbarProps={muiTopToolbarProps}
                    muiBottomToolbarProps={muiBottomToolbarProps}
                    localization={MRT_Localization_ES}
                    muiTableProps={muiTableProps}
                    enableColumnFilters={false} //filtering does not work with memoized table body
                    enableDensityToggle={false} //density does not work with memoized table body
                    enableGlobalFilter={false} //searching does not work with memoized table body
                    enableHiding={false} //column hiding does not work with memoized table body
                    enableSorting={false} //sorting does not work with memoized table body
                    enableRowSelection={false}
                    enableFullScreenToggle={false}
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Editar',//nombre de la columna enableEditing
                        muiTableHeadCellProps: {
                          align: 'center',
                        },
                        size: 60,
                      },
                    }}
                    muiTablePaperProps={{
                      sx: {
                        '& .MuiTypography-root': {
                          fontSize: '64px'
                        }
                      }
                    }}
                    muiTableHeadCellProps={{//encabezado de la tabla
                      //easier way to create media queries, no useMediaQuery hook needed.
                      sx: {
                        fontSize: {
                          xs: '20px',
                          sm: '11px',
                          md: '12px',
                          lg: '15px',
                          xl: '18px',
                        },
                        '& .Mui-TableHeadCell-Content': {
                          justifyContent: 'space-between',//espacio entre los texto y los iconos
                        },
                        '& .Mui-TableHeadCell-Content svg': {
                          fontSize: '24px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '17px'
                        },
                        color:
                          '#5b95cf',
                        width: '520px',
                      },
                    }}

                  />
                </div>
                <div style={{ whiteSpace: "nowrap", display: "inline-block", }}>
                  <MaterialReactTable
                    data={dataFormat}
                    columns={columnasFormatos}
                    enableColumnFilters={false} //filtering does not work with memoized table body
                    enableDensityToggle={false} //density does not work with memoized table body
                    enableGlobalFilter={false} //searching does not work with memoized table body
                    enableHiding={false} //column hiding does not work with memoized table body
                    enableSorting={false} //sorting does not work with memoized table body
                    enableFullScreenToggle={false}
                    muiTopToolbarProps={muiTopToolbarProps}
                    muiBottomToolbarProps={muiBottomToolbarProps}
                    muiTableProps={muiTableProps2}
                    initialState={{ pagination: { pageSize: 5 } }}
                    localization={MRT_Localization_ES}
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Editar',//nombre de la columna enableEditing
                        muiTableHeadCellProps: {
                          align: 'center',
                        },
                        size: 60,
                      },
                    }}
                    muiTablePaperProps={{
                      sx: {
                        '& .MuiTypography-root': {
                          fontSize: '64px'
                        }
                      }
                    }}
                    muiTableHeadCellProps={{//encabezado de la tabla
                      //easier way to create media queries, no useMediaQuery hook needed.
                      sx: {
                        fontSize: {
                          xs: '20px',
                          sm: '11px',
                          md: '12px',
                          lg: '15px',
                          xl: '18px',
                        },
                        '& .Mui-TableHeadCell-Content': {
                          justifyContent: 'space-between',//espacio entre los texto y los iconos
                        },
                        '& .Mui-TableHeadCell-Content svg': {
                          fontSize: '24px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '17px'
                        },
                        color:
                          '#5b95cf',
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <div style={{ whiteSpace: "nowrap", display: "inline-block", }}>
                  <Typography variant="h4" sx={{ display: 'inline-block', paddingRight: 3, paddingTop: 1.5 }}>Observación</Typography>
                  <TextField sx={{ paddingRight: 15, width: '700px' }} id="miTextFiel" />
                  <Stack spacing={2} sx={{ top: '32px', display: 'inline-block' }} direction="row">
                    <Button variant='contained' color='success' onClick={Envio}>Enviar</Button>
                    <Button variant='contained' sx={{ bgcolor: '#ff3d00', '&:hover': { bgcolor: '#b22a00' } }} onClick={handleClose}>Cancelar</Button>
                  </Stack>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
        {/* FIN MODAL PARA LA VINCULACION */}


        {/**INICIO DE LA MODAL DE CAMBIAR USUARIO DE PANTALLA*/}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openCambio}
          onClose={handleCloseCambio}
        >
          <Fade in={openCambio}>
            <Box sx={style}>
              <h5 style={{ margin: '0px 0px 20px 60px' }}>Cambio de pantalla</h5>
              <div style={{ paddingBottom: 35, textAlign: 'center' }}>
                <div style={{ whiteSpace: "nowrap", display: "inline-block", paddingRight: 24 }}>
                  <MaterialReactTable
                    renderTopToolbarCustomActions={({ table }) => (<TextField size="small" value={search} onChange={handleChange} placeholder="Buscar Usuario" />)}
                    initialState={{
                      pagination: { pageSize: 5 }
                    }}
                    muiTableBodyRowProps={({ row }) => ({ //aqui seleccionamos la fila y traemos el usuario_id de data
                      onClick: () => {
                        handleRowClick(row.original.perfil_id, row.original.pantalla, row.original.numero, row.original.paquete_id)
                        console.log('ESTA ES LA PANTALLAAAAAA', row.original.pantalla)
                        setENVIOCAMBIODATOS()

                      },
                      sx: {
                        cursor: 'pointer',
                        backgroundColor: row.original.pantalla === selectedScreen && row.original.perfil_id === selectedRow && row.original.numero === numeroCambio ? '#eee' : 'white',
                      },
                    })}
                    data={pantallasDisponibles}
                    columns={columnasPantallasDisponibles}
                    muiTopToolbarProps={muiTopToolbarProps}
                    muiBottomToolbarProps={muiBottomToolbarProps}
                    localization={MRT_Localization_ES}
                    muiTableProps={muiTableProps}
                    enableColumnFilters={false} //filtering does not work with memoized table body
                    enableDensityToggle={false} //density does not work with memoized table body
                    enableGlobalFilter={false} //searching does not work with memoized table body
                    enableHiding={false} //column hiding does not work with memoized table body
                    enableSorting={false} //sorting does not work with memoized table body
                    enableRowSelection={false}
                    enableFullScreenToggle={false}
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Editar',//nombre de la columna enableEditing
                        muiTableHeadCellProps: {
                          align: 'center',
                        },
                        size: 60,
                      },
                    }}
                    muiTablePaperProps={{
                      sx: {
                        '& .MuiTypography-root': {
                          fontSize: '64px'
                        }
                      }
                    }}
                    muiTableHeadCellProps={{//encabezado de la tabla
                      //easier way to create media queries, no useMediaQuery hook needed.
                      sx: {
                        fontSize: {
                          xs: '20px',
                          sm: '11px',
                          md: '12px',
                          lg: '15px',
                          xl: '18px',
                          width: '10px',
                        },
                        '& .Mui-TableHeadCell-Content': {
                          justifyContent: 'space-between',//espacio entre los texto y los iconos
                        },
                        '& .Mui-TableHeadCell-Content svg': {
                          fontSize: '24px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '17px'
                        },
                        color:
                          '#5b95cf',
                        width: '1em',
                      },
                    }}

                  />
                </div>
                <div style={{ whiteSpace: "nowrap", display: "inline-block", }}>
                  <MaterialReactTable
                    data={dataRequisitosPerfil}
                    columns={columnasFormatos}
                    enableColumnFilters={false} //filtering does not work with memoized table body
                    enableDensityToggle={false} //density does not work with memoized table body
                    enableGlobalFilter={false} //searching does not work with memoized table body
                    enableHiding={false} //column hiding does not work with memoized table body
                    enableSorting={false} //sorting does not work with memoized table body
                    enableFullScreenToggle={false}
                    muiTopToolbarProps={muiTopToolbarProps}
                    muiBottomToolbarProps={muiBottomToolbarProps}
                    muiTableProps={muiTableProps2}
                    initialState={{ pagination: { pageSize: 5 } }}
                    localization={MRT_Localization_ES}
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Editar',//nombre de la columna enableEditing
                        muiTableHeadCellProps: {
                          align: 'center',
                        },
                        size: 60,
                      },
                    }}
                    muiTablePaperProps={{
                      sx: {
                        '& .MuiTypography-root': {
                          fontSize: '64px'
                        }
                      }
                    }}
                    muiTableHeadCellProps={{//encabezado de la tabla
                      //easier way to create media queries, no useMediaQuery hook needed.
                      sx: {
                        fontSize: {
                          xs: '20px',
                          sm: '11px',
                          md: '12px',
                          lg: '15px',
                          xl: '18px',
                        },
                        '& .Mui-TableHeadCell-Content': {
                          justifyContent: 'space-between',//espacio entre los texto y los iconos
                        },
                        '& .Mui-TableHeadCell-Content svg': {
                          fontSize: '24px',
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '17px'
                        },
                        color:
                          '#5b95cf',
                      },
                    }}
                  />
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <div style={{ whiteSpace: "nowrap", display: "inline-block", }}>
                  <Typography variant="h4" sx={{ display: 'inline-block', paddingRight: 3, paddingTop: 1.5 }}>Observación</Typography>
                  <TextField sx={{ paddingRight: 15, width: '700px' }} id="miTextFiel" />
                  <Stack spacing={2} sx={{ top: '32px', display: 'inline-block' }} direction="row">
                    <Button variant='contained' color='success' onClick={EnvioCambio}>Enviar</Button>
                    <Button variant='contained' sx={{ bgcolor: '#ff3d00', '&:hover': { bgcolor: '#b22a00' } }} onClick={handleCloseCambio}>Cancelar</Button>
                  </Stack>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
        {/**FIN DE LA MODAL DE CAMBIAR USUARIO DE PANTALLA*/}
      </ThemeProvider>
    </>
  )
}


export default SubDetalles