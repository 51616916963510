import React from "react";
import NiftyTable from "../../Nifty/UI/Table/NiftyTable";
import Row from "../../../components/Nifty/UI/Table/Row/NiftyRow";
import Cell from "../../../components/Nifty/UI/Table/Row/NiftyCell";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from "./DolarPrices.css";
import { faChevronUp, faChevronDown, faDotCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

const dolarPrices = props => {
  const prices = props.dolarPrices;
  return (
    <div className={["panel", "panel-primary", classes.DolarPrice].join(" ")}>
      <div className="panel-heading" style={{height: '36px', textAlign:'left'}}>
        <h3 className="panel-title text-left" style={{lineHeight: '36px', textAlign:'left'}}>Precios del dólar</h3>
      </div>
      <div className={["panel-body", classes.Body].join(" ")}>
        <NiftyTable lineHeight="36px" height="255px">

          <Row className="Estilos">
            <Cell style={{ lineHeight: props.lineHeight }}>
              <span className={["text-main", classes.Textos].join(" ") }  >TRM</span> {/*text-semibold*/}
            </Cell>
            <Cell>
              <span className={["text-2x", getFontClass(prices.trm.change),classes.CajasTextos].join(' ')}>{prices.trm.price} </span> {/*text-semibold*/}
              <FontAwesomeIcon className={getFontClass(prices.trm.change)} icon={getFontAwesomeIcon(prices.trm.change)} /> {/*text-semibold*/}
            </Cell>

          </Row>
          <Row>
            <Cell style={{ lineHeight: props.lineHeight, textAlign: props.textAlign }}>
              <span className={["text-main", classes.Textos].join(" ")}>Apertura *</span> {/*text-semibold*/}
            </Cell>
            <Cell className="text-center">
              <span className={["text-2x", getFontClass(prices.openPrice.change),classes.CajasTextos].join(' ')}>{prices.openPrice.price} </span> {/*text-semibold*/}
              <FontAwesomeIcon className={getFontClass(prices.openPrice.change)} icon={getFontAwesomeIcon(prices.openPrice.change)} /> {/*text-semibold*/}
            </Cell>
          </Row>
          <Row>
            <Cell style={{ lineHeight: props.lineHeight }}>
              <span className={["text-main", classes.Textos].join(" ")}>Mínimo *</span> {/*text-semibold*/}
            </Cell>
            <Cell className="text-center">
              <span className={["text-2x", getFontClass(prices.minPrice.change),classes.CajasTextos].join(' ')}>{prices.minPrice.price} </span> {/*text-semibold*/}
              <FontAwesomeIcon className={getFontClass(prices.minPrice.change)} icon={getFontAwesomeIcon(prices.minPrice.change)} /> {/*text-semibold*/}
            </Cell>
          </Row>
          <Row>
            <Cell style={{ lineHeight: props.lineHeight }}>
              <span className={["text-main", classes.Textos].join(" ")}>Máximo *</span> {/*text-semibold*/}
            </Cell>
            <Cell className="text-center">
              <span className={["text-2x", getFontClass(prices.maxPrice.change),classes.CajasTextos].join(' ')}>{prices.maxPrice.price} </span> {/*text-semibold*/}
              <FontAwesomeIcon className={getFontClass(prices.maxPrice.change)} icon={getFontAwesomeIcon(prices.maxPrice.change)} /> {/*text-semibold*/}
            </Cell>
          </Row>
        </NiftyTable>
      </div>
    </div>
  );
};

const getFontAwesomeIcon = str => {
  if (str === 'UP') {
    return faChevronUp
  }
  if (str === 'DOWN') {
    return faChevronDown
  }
  if (str === 'SAME') {
    return faDotCircle
  }
  return faMinusCircle
}

const getFontClass = str => {
  if (str === 'UP') {
    return "text-success"
  }

  if (str === 'DOWN') {
    return "text-danger"
  }
  if (str === 'SAME') {
    return "text-primary"
  }
  return "text-primary"
}

export default dolarPrices;
