import React from 'react';
import NiftyTable from '../../Nifty/UI/Table/NiftyTable';
import Row from '../../Nifty/UI/Table/Row/NiftyRow';
import Cell from '../../Nifty/UI/Table/Row/NiftyCell';
import classes from './DolarAmounts.css';

const dolarAmmounts = (props) => {

    return (
        <div className={["panel", "panel-primary", classes.DolarAmounts].join(' ')}>
            <div className="panel-heading"  style={{height: '36px'}}>
                <h3 className="panel-title text-left" style={{lineHeight: '36px'}}>Montos USD</h3>
            </div>
            <div className={["panel-body", classes.Body, classes.table_Dolar].join(" ")}>
            <NiftyTable lineHeight="18px" height="255px"  >
                    <Row>
                        <Cell>
                            <span className={["text-main ", classes.Textos].join(' ')}>Negociado</span>
                        </Cell>
                        <Cell className="text-center"><span className={["text-primary", classes.CajasTextos].join(' ')}>{props.dolarAmmounts.totalAmmount}</span></Cell>
                    </Row>
                    <Row className="text-left">
                        <Cell>
                            <span className={["text-main  ", classes.Textos].join(' ')}>Último *</span>
                        </Cell>
                        <Cell className="text-center">
                            <span className={["text-primary", classes.CajasTextos].join(' ')}>{props.dolarAmmounts.latestAmmount}</span></Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <span className={["text-main ", classes.Textos].join(' ')}>Promedio</span>
                        </Cell>
                        <Cell className="text-center"><span className={["text-primary", classes.CajasTextos].join(' ')}>{props.dolarAmmounts.avgAmmount}</span></Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <span className={["text-main ", classes.Textos].join(' ')}>Mínimo</span>
                        </Cell>
                        <Cell className="text-left"><span className={["text-primary", classes.CajasTextos].join(' ')}>{props.dolarAmmounts.minAmmount}</span></Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <span className={["text-main ", classes.Textos].join(' ')}>Maximos</span>
                        </Cell>
                        <Cell className="text-center"><span className={["text-primary", classes.CajasTextos].join(' ')}>{props.dolarAmmounts.maxAmmount}</span></Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <span className={["text-main ", classes.Textos].join(' ')}>Transacciones</span>
                        </Cell>
                        <Cell className="text-center"><span className={["text-primary", classes.CajasTextos].join(' ')}>{props.dolarAmmounts.transactions}</span></Cell>
                    </Row>
            </NiftyTable>
            </div>
        </div>
    )
}

export default dolarAmmounts;
