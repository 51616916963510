//ESTE ES EL QUE ESTA CONECTADO EN LA APP.JS
import React, { useEffect, useState } from 'react'
import { MaterialReactTable } from 'material-react-table'
import { Box, Button, Fade, Modal, Stack, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import { AppBarReciclable } from '../../AppBar'
import { Link } from "react-router-dom";
import PrivateRoute from '../../PrivateRoute/PrivateRoute';
import Detalles from './Detalles';
import { Switch, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { HttpNode } from '../../../../../axiosInstances'
import swal from 'sweetalert';
import { MRT_Localization_ES } from 'material-react-table/locales/es'; //lenguaje
// import { CheckBox } from '@material-ui/icons';
//import swal from 'sweetalert';

function MisProductos() {
  const history = useHistory()
  const style = {//estilos de las modales
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1250,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "5px",
    height: 500,
    '.MuiListItemText-secondary': { //tamaño del atributo secondary del componente ListItemText, https://mui.com/material-ui/api/list-item-text/
      fontSize: '15px'
    },
    '.MuiListItemText-primary': {
      fontSize: '15px'
    },
    '.MuiList-root': {
      border: '2px solid gray',
      borderRadius: '5px'
    }
  };
  const [PaqueteNuevo, setPaqueteNuevo] = useState([])
  async function NuevoPaquete() {
    try {
      const response = await HttpNode.get('/setfx/paquetes')
      const NuevosPaquetes = response.data.data
      console.log('valor de los nuevo paquetes :D: ', NuevosPaquetes)
      setPaqueteNuevo(NuevosPaquetes)
    }
    catch (error) {
      console.log(error)
    }
  }
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
    NuevoPaquete()//llamamos a la funcion cuando se abre la modal
  }
  const [data, setData] = useState([]);
  useEffect(() => {
    let isMounted = true;
    async function fetchProducts() {
      try {
        const entityId = localStorage.getItem('entidadId');
        const response = await HttpNode.get(`/setfx/entidades/${entityId}/suscripciones?agrupado=true`);
        const data = response.data.data;
        
        if (isMounted) {
          if (data && data.length > 0) {
            // Formatear el costo mensual antes de establecerlo en el estado
            const dataFormatted = data.map(item => ({
              ...item,
              precio: isNaN(item.precio) ? item.precio : parseFloat(item.precio).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
             }));
    
            setData(dataFormatted);
            console.log("datos de los productos por entidad ", response.data.data);
          } else {
            console.log("No se encontraron productos");
          }
        }
      } catch (error) {
        console.error(error.message);
        return swal({ title: 'Ha ocurrido un error', text: error.message, icon: 'error', button: 'OK' });
      }
    }

    fetchProducts();
    // Función de limpieza para cancelar cualquier trabajo pendiente cuando el componente se desmonte
    return () => {
      isMounted = false;
    };
  }, []);
  
  const columns = [
    {
      accessorKey: 'codigo',
      header: 'Paquete',
      size:100
    },
    {
      accessorKey: 'nombre_paquete',
      header: 'Nombre',
    size:400
    },
    {
      accessorKey: 'total_pantallas',
      header: 'Pantallas',
      size:100
    },
    {
      accessorKey: 'total_disponibles',
      header: 'Disponibles',
      size:100
    },
    {
      accessorKey: 'precio',
      header: 'Costo Mensual'
    },
    {
      accessorKey: 'noe',
      header: 'Detalles',

      Cell: ({ row }) => {

        const productId = row.original.paquete_id;
        console.log('Este es el id del paquete', productId);
        return (<>
          <Link to={`/imc/MisProductos/Detalles/${productId}`}>
            <Button variant='contained' color='success'>Detalles</Button>
          </Link>
        </>)
      }
    }
  ]

  const columnsNuevoPaquete = [
    {
      accessorKey: "codigo",
      header: "Codigo"
    },
    {
      accessorKey: "nombre",
      header: "Nombre",
      size: 500
    },
    {
      accessorKey: "precio",
      header: "Precio",
      Cell:({row}) => (parseFloat(row.original.precio).toLocaleString('es-CO', {style:'currency', currency:'COP'})) //.toLocaleString('es-CO') en este caso el formato de los numeros se tranforma al formato numerico de colombia y con minimumFractionDigits decimos que ponga los 2 decimales(pero solo coloca 0 asi que lo eliminamos), style:'currency', currency:'COP' da la moneda de colombia https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
    }
  ]
  const muiTopToolbarProps = {
    sx: {
      height: '55px',
      '& .MuiIconButton-root svg': {//dentro del div seleccione MuiIconButton-root y dentro de ese componente traiga los svg y le agregue estilos
        fontSize: '29px'
      },
      '& .MuiInputBase-root': {//search
        fontSize: '17px'
      },
      '& .MuiInputBase-root svg': {//icono de lupa con una x
        fontSize: '22px'
      },
      '& .MuiButtonBase-root': {
        fontSize: '34px'
      },
      display: 'contents'//sin esta propiedad no se puede ver muy bien el boton de crear usuarios, por que la cabeza de la tabla lo oculta un poco
    },
  };
  const muiBottomToolbarProps = {
    sx: {
      height: '60px',
      '& .MuiIconButton-root svg': {//iconos del header de la tabla
        fontSize: '22px'
      },
      '& .MuiTablePagination-selectLabel': {//Rows per page
        fontSize: '15px'
      },
      '& .MuiSelect-standard': {//arreglo
        fontSize: '15px',
        alignItems: 'center',
        display: 'flex',
      },
      '& .MuiSvgIcon-fontSizeMedium': {//icono del arreglo
        fontSize: '15px'
      },
      '& .MuiTablePagination-displayedRows': {//1-10 of 28
        fontSize: '15px'
      },
    }
  }
  const muiTableProps = {
    sx: {
      '& .MuiTableCell-root svg': {
        fontSize: '25px',
      },
      '& .MuiTableCell-root': {

      }
    }
  }
  const tableTheme = React.useMemo(
    () =>
      createTheme({
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.5rem', //override to make tooltip font size larger, tooltips
              },
            },
          },
          MuiTableCell: {//celdas de la tabla
            styleOverrides: {
              root: {
                fontSize: '15px', // Ajusta el tamaño de fuente de las celdas de la tabla
              },
            },
          },
        },
        typography: {//edicion de enableEditing, la lista del arreglo(despues del "Rows per page") y el Show/Hide columns(tercer icono parte superior)
          button: {//"botones" de enableEditing
            fontSize: '12px',
          },
          body1: {//formulario y lista del arreglo, si se coloca "body2" no se aplican los estilos
            fontSize: '14px'
          },
          h6: {//titulo de enableEditing(dentro de la clase de MaterialReactTable la clase del titulo de enableEditing esta definido como 'MuiTypography-h6' por ende y segun mi logica se coloca 'h6')
            fontSize: '20px'
          }
        }
      }),
    [],
  );
  const [search, setSearch] = useState('');
  const handleChangeFiltro = (event) => {
    setSearch(event.target.value);
  };
  const filtro = data.filter((buscar) => {
    if (buscar.codigo.toUpperCase().includes(search.toUpperCase())) {
      return true;
    }
    return false;
  });
  //Inicio de Validaciones de paquetes
  const [PaqueteAnadido, setPaqueteAnadido] = useState([])
  const [NumeroCompras, setNumeroCompras] = useState(1)
  const [clickedRow, setClickedRow] = useState(null)
  const handleClick = (id) => {
    console.info(id)
    setClickedRow(id)
  }
  let Valor = PaqueteAnadido * parseFloat(NumeroCompras)
  // console.log('paquetes añadidos: ', PaqueteAnadido)
  // let valor = 0;
  // console.log('Primer paquete',PaqueteAnadido[0])
  // const PaqueteVerificacion = PaqueteAnadido[0]//guardamos el primer paquetes que el usuario selecciones 
  // for (const iterator of PaqueteAnadido) {
  //   console.log('AVER: ', parseFloat(iterator.precio))
  //   valor += parseFloat(iterator.precio)
  //   if(iterator.id !== PaqueteVerificacion.id){//si algunos de los paquetes es diferente con la id
  //     swal({title:'Paquete Diferente.',icon:'warning',text:'No puedes comprar 2 paquetes diferentes'})
  //     setPaqueteAnadido([])//se envio un arreglo vacio si este se cumple
  //   }
  //   else{
  //     console.log('sigue')
  //   }
  // }
  //console.log(valor)
  //Fin de Validaciones de paquetes
  const [PaqueteID, setPaqueteID] = useState('')

  async function EnvioCompra(){
    const entityId = localStorage.getItem('entidadId');
    const usuario = localStorage.getItem('id_usr')
    const CompraEnvio ={
      entidad_id: parseFloat(entityId),
      paquete_id: parseFloat(PaqueteID),
      administrador_id: parseFloat(usuario),
      precio: PaqueteAnadido,
      cantidad: parseFloat(NumeroCompras)
    }
    if(PaqueteID === ''){
      return swal({title:'Alto!!!',text:'Seleccione el paquete de desea adquirir.',icon:'warning',button:'OK'})
    }
    else if(NumeroCompras === ''){
      return swal({title:'Alto!!!',text:'Seleccione la cantidad que desea solicitar.',icon:'warning',button:'OK'})
    }
    else{
      console.log('Objeto de envio hacia la api',CompraEnvio)
      try{
        const response = await HttpNode.post('/setfx/entidades/paquete/compra',{
          ...CompraEnvio
        })
        console.log(response.data)
        swal({title:'Solicitud Enviada.',text:'La solicitud fue enviada con exito.',icon:'success',button:'OK'})
        .then((enviado) =>{
          if(enviado){
            history.push('/imc/MisSolicitudes');
          }
        })
      }catch (error){
        console.log(error.response.data.message)
        swal({title:'Error!',text:error.response.data.message,icon:'error',button:'OK'})
      }
    }
  }
  return (
    <>
      <AppBarReciclable />
      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) => (
            <>
              <div>
                <h3 style={{ marginTop: '2px' }}>Mis Productos</h3>
                <Button variant='contained' style={{ fontSize: '11px', }} color='success' onClick={handleOpen}>Solicitar nuevo paquete</Button>
              </div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                slotProps={{ backdrop: { timeout: 500 } }}
                onClose={handleClose}
              >
                <Fade in={open}>
                  <Box sx={style}>
                        <MaterialReactTable
                          renderTopToolbarCustomActions={({ table }) => (<h3 style={{ marginTop: '2px' }}>Solicitar Paquete</h3>)}
                          muiTableBodyRowProps={({ row }) => ({
                            onClick: () => {
                              console.log(row.original)
                              //setPaqueteAnadido((prevPaquete) => [...prevPaquete, row.original])
                              setPaqueteAnadido(row.original.precio)
                              handleClick(row.original.id)
                              setPaqueteID(row.original.id)
                            },
                            sx: {
                              cursor: 'pointer',
                              backgroundColor: row.original.id === clickedRow ? '#eee' : 'white'
                            },
                          })}
                          data={PaqueteNuevo}
                          columns={columnsNuevoPaquete}
                          muiTopToolbarProps={muiTopToolbarProps}
                          muiBottomToolbarProps={muiBottomToolbarProps}
                          enableColumnFilters={false} //filtering does not work with memoized table body
                          enableDensityToggle={false} //density does not work with memoized table body
                          enableGlobalFilter={false} //searching does not work with memoized table body
                          enableHiding={false} //column hiding does not work with memoized table body
                          enableSorting={false} //sorting does not work with memoized table body
                          enableFullScreenToggle={false}
                          muiTableProps={muiTableProps}
                          positionActionsColumn='last'
                          initialState={{ pagination: { pageSize: 5 } }}//numero de filas que aparecen al crear el componente
                          localization={MRT_Localization_ES}
                          // enableColumnOrdering={{ -> ordenamiento de las columnas
                          //   color: '#5b95cf',
                          // }}
                          muiTablePaperProps={{
                            sx: {
                              '& .MuiTypography-root': {
                                fontSize: '64px'
                              }
                            }
                          }}
                          muiTableHeadCellProps={{//encabezado de la tabla
                            //easier way to create media queries, no useMediaQuery hook needed.
                            sx: {
                              fontSize: {
                                xs: '20px',
                                sm: '11px',
                                md: '12px',
                                lg: '15px',
                                xl: '18px',
                              },
                              '& .Mui-TableHeadCell-Content': {
                                justifyContent: 'space-between',//espacio entre los texto y los iconos
                              },
                              '& .Mui-TableHeadCell-Content svg': {
                                fontSize: '24px',
                              },
                              '& .MuiInputBase-input': {
                                fontSize: '17px'
                              },
                              color:
                                '#5b95cf',
                            },
                          }}
                        >
                        </MaterialReactTable>
                      {/* <div style={{ whiteSpace: "nowrap", display: "inline-block" , paddingLeft:'100px'}}>
                        <h1>Carrito de compras</h1>
                        <List
                          sx={{
                            width: '100%',
                            maxWidth: 550,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 300,
                            '& ul': { padding: 0 },
                          }}
                          subheader={<li />}
                        >
                          <ul>
                            {PaqueteAnadido.map((item) => (
                              <>
                                <ListItemButton role={undefined} dense>
                                  <ListItem key={item.id}>
                                    <ListItemText primary={`${item.nombre}`} secondary={`Valor: ${parseFloat(item.precio)}`} />
                                  </ListItem>
                                </ListItemButton>
                              </>
                            ))}
                          </ul>
                        </List>
                      </div> 
                    </div>*/}
                    <div style={{ paddingTop: '40px' }}>
                      <Typography variant='h4' gutterBottom sx={{ display: 'inline-block', marginLeft: { xs: '33px', md: '0px' }, marginBottom: { xs: '18px', md: '32px' }, marginRight: '20px', fontSize: { xs: '18px', md: '21px' } }}>Numero de paquetes a comprar</Typography>
                      <TextField defaultValue={NumeroCompras} sx={{marginTop:'-15px'}} onChange={(event) => setNumeroCompras(event.target.value)}/>
                      <Typography variant='h4' sx={{ marginLeft: '25px', display: 'inline-block' }}>TOTAL: {Valor.toLocaleString('es-CO',{style:'currency', currency:'COP'}) || 0}</Typography> {/*Si la variable "Valor" es indefinida va a mostrar 0 si no el valor que contiene dentro de la variable*/}
                      <Stack spacing={2} sx={{ top: '32px', display: 'inline-block', paddingLeft: '200px' }} direction="row">
                        <Button variant='contained' color='success' onClick={EnvioCompra}>Solicitar</Button>
                        <Button variant='contained' sx={{ bgcolor: '#ff3d00', '&:hover': { bgcolor: '#b22a00' } }} onClick={handleClose}>Desistir</Button>
                      </Stack>
                    </div>
                  </Box>
                </Fade>
              </Modal>
            </>)}
          muiSearchTextFieldProps={{
            placeholder: `Buscar por Paquete`,
            sx: { minWidth: '300px' },
            variant: 'outlined',
            size: 'small',
            value: search,
            onChange: handleChangeFiltro,
            //onClick: jeje,
          }}
          data={filtro}
          columns={columns}
          muiTopToolbarProps={muiTopToolbarProps}
          muiBottomToolbarProps={muiBottomToolbarProps}
          enableColumnFilters={false} //filtering does not work with memoized table body
          //enableGlobalFilter={false} //searching does not work with memoized table body
          muiTableProps={muiTableProps}
          positionActionsColumn='last'
          initialState={{ pagination: { pageSize: 5 } }}//numero de filas que aparecen al crear el componente
          localization={MRT_Localization_ES}
          enableColumnOrdering={{
            color: '#5b95cf',
          }}
          muiTablePaperProps={{
            sx: {
              '& .MuiTypography-root': {
                fontSize: '64px'
              }
            }
          }}
          muiTableHeadCellProps={{//encabezado de la tabla
            //easier way to create media queries, no useMediaQuery hook needed.
            sx: {
              fontSize: {
                xs: '20px',
                sm: '11px',
                md: '12px',
                lg: '15px',
                xl: '18px',
              },
              '& .Mui-TableHeadCell-Content': {
                justifyContent: 'space-between',//espacio entre los texto y los iconos
              },
              '& .Mui-TableHeadCell-Content svg': {
                fontSize: '24px',
              },
              '& .MuiInputBase-input': {
                fontSize: '17px'
              },
              color:
                '#5b95cf',
            },
          }}
        >
        </MaterialReactTable>
      </ThemeProvider>
      <Switch>
        <PrivateRoute path="/imc/MisProductos/Detalles/:productId" component={Detalles} />
      </Switch>
    </>
  )
}

export default MisProductos