import React, {Component} from 'react';
import swal from 'sweetalert';
import Layout from '../../../components/Auth/Layout';
import classes from './ResetPassword.css';
//import Input from '../../../components/Nifty/UI/Input/Input';
import Button from '../../../components/Nifty/UI/Button/Button';
import {Link} from 'react-router-dom';
import {Httpphp} from '../../../axiosInstances';
import { Divider } from '@mui/material';
import { Typography } from '@material-ui/core';
//import axios from 'axios';
class ResetPassword extends Component{

   constructor(props) {
    super(props);
    this.state = {value: '', revalue: '',token:'',token2:''};
    this.PassInput = React.createRef();
    this.RePassInput = React.createRef();
    this.focus = this.focus.bind(this);
    this.handleChangeV = this.handleChangeV.bind(this);
    this.handleChangeP = this.handleChangeP.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
      //console.log(this.props.location.search);
      //debugger;
        if(this.props.location.search!==''){
            if(this.props.location.search.split('?').length>1){
                //preguntar si llego algun dato
                var data = this.props.location.search.split('?')[1];
                if(data.split('&').length>1){
                    //preguntar si llego la division de los dos datos
                    if(data.split('&')[0].split('=').length>1 && data.split('&')[1].split('=').length>1){
                        //preguntar si los datos no sean nullos (que se tenga la division, pero un o los dos no tiene datos)
                        if(data.split('&')[0].split('=')[1]!="" && data.split('&')[1].split('=')[1]!=""){
                            //preguntar si los datos que trae los tokens no son vacias, guardar y validar por php si son correctas
                            //si son correctas no cambia la pagina y al cambiar la clave correctamente crea la sesion
                            // y redirige
                            if(data.split('&')[0].split('=')[0]==='token' && data.split('&')[1].split('=')[0]==='token2'){
                                //aca post
                                this.setState({token:data.split('&')[0].split('=')[1]});
                                this.setState({token2:data.split('&')[1].split('=')[1]});
                                //console.log(data.split('&')[0].split('=')[1]+" "+data.split('&')[1].split('=')[1]);
                                //debugger;
                                this.validarDatos(data.split('&')[0].split('=')[1],data.split('&')[1].split('=')[1]);
                                
                            }else{
                                //nombre datos diferentes
                                //console.log("nombre datos diferentes");
                                window.location="/"; 
                            }
                            
                        }else{
                            //uno o los dos tokens son vacios y redirigir al index
                            //console.log("no vino uno de los tokens");
                            window.location="/"; 
                        }
                        
                    }else{
                        //uno o los dos tokens estan null y redirigir al index
                        //console.log("no vino uno de los tokens");
                        window.location="/"; 
                    }
        
                }else{
                    //trae 1 token y y redirigir al index
                    //console.log("datos traidos incompletos falta");
                    window.location="/"; 
                }
            }
            else{
                //no trajo ningun dato y redirigir al index
                //console.log("ningun datos traidos ");
                window.location="/"; 
            }
            
        }else{
            window.location="/"; 
        } 

        
     }
     MedidorSeguridad(){
        let $password = document.getElementById("password")
        let $p = document.getElementById("p")
        let $hr1 = document.getElementById("hr1")
        let $hr2 = document.getElementById("hr2")
        let $hr3 = document.getElementById("hr3")
        let $hr4 = document.getElementById("hr4")
        
        let probar = $password.value

        if(probar.length<12){
            $p.textContent = "Contraseña pequeña"
            $p.style.color="#FF5733"
            $p.style.fontFamily="Arial"
            if(window.screen.width <= 500){
                $p.style.fontSize="18px"
            }
            else{
                $p.style.fontSize="20px"
            }
            $password.style.borderColor="#FF5733"
            $hr1.style.setProperty("background-color","#FF5733")
            $hr2.style.setProperty("background-color","#eee")
        }
        else if(probar.length>=12 && probar.length <=15){
            $p.textContent = "Contraseña mediana"
            $p.style.color="#ffb224"
            $password.style.borderColor="#ffb224"
            $hr1.style.setProperty("background-color","#ffb224")
            $hr2.style.setProperty("background-color","#ffb224")
            $hr3.style.setProperty("background-color","#eee")
        }
        else if(probar.length>15 && probar.length<18){
            $p.textContent = "Contraseña grande"
            $p.style.color="#00C1C1"
            $password.style.borderColor="#00C1C1"
            $hr1.style.setProperty("background-color","#00C1C1")
            $hr2.style.setProperty("background-color","#00C1C1")
            $hr3.style.setProperty("background-color","#00C1C1")
            $hr4.style.setProperty("background-color","#eee")
        }
        else if(probar.length>=18){
            $p.textContent = "Contraseña Segura"
            $p.style.color="#12C100"
            $password.style.borderColor="#12C100"
            $hr1.style.setProperty("background-color","#12C100")
            $hr2.style.setProperty("background-color","#12C100")
            $hr3.style.setProperty("background-color","#12C100")
            $hr4.style.setProperty("background-color","#12C100")
        }
        //---------restaurar color "original"
        if(probar.length<=0){
            $hr1.style.setProperty("background-color","#eee")
            $hr2.style.setProperty("background-color","#eee")
            $hr3.style.setProperty("background-color","#eee")
            $hr4.style.setProperty("background-color","#eee")
            $password.style.borderColor="#ccc"
            $p.textContent = ""
        }
     }

     validarDatos(token,token2){
         try {
              let formData = new FormData();
                formData.append('token', token);
                formData.append('token2', token2);
                
                Httpphp.post(
                   "/php-prueba/verifyTokens.php",
                    formData,
                    {
                        headers: {
                            "Content-type": "multipart/form-data",
                        },                    
                    }
                )
                .then(res => {
                    //debugger;
                    if(res.data == "OK"){
                        //console.log('OK');
                    }else if(res.data == 'NO'){
                        //No paso la verificacion y redirigir al index
                        //console.log("No paso la verificacion"); 
                        window.location="/";                                   
                    }
                })
                .catch(err => {
                    //// console.log(err);
                })

          } catch (error) {
            //// console.log(error);
          }
     }
   handleChangeV(event) {
   this.setState({value: event.target.value});
  }
  handleChangeP(event) {
    this.setState({revalue: event.target.value});
  }

   handleSubmit= (event) => {
    event.preventDefault();
   	//el evento cuando se unde el boton
       if(this.validate(this.state)){
           let token = this.state.token;
           let token2 = this.state.token2;
           let value = this.state.value;
           let revalue = this.state.revalue;
            try {
                  let formData = new FormData();
                    formData.append('token', token);
                    formData.append('token2', token2);
                    formData.append('value', value);
                    formData.append('revalue', revalue);

                    Httpphp.post(
                       "/php-prueba/ChangePassword.php",
                        formData,
                        {
                            headers: {
                                //"Content-type": "multipart/form-data",
                            },                    
                        }
                    )
                    .then(res => {
                        if(res.data.toString().includes('OK')) {
                            var reset =document.getElementById("password");
                            reset.value = "";
                            reset =document.getElementById("repassword");
                            reset.value = "";
                            //window.location="/"; 
                            swal({
                                title: '¡Cambio exitoso!',
                                text: 'Hemos actualizado tu clave, ya puedes iniciar sesión',
                                closeOnClickOutside: false,
                                icon: "success",                                
                                button: 'Ok'}); 
                        }else if(res.data.toString().includes('NO')) {
                            swal( 
                                'Lo sentimos...',
                                'No fue posible actualizar tu clave de acceso, por favor contacte a su agente de soporte.');
                        }
                    })
                    .catch(err => {
                        //// console.log(err);
                        swal({
                            title: 'Pedimos disculpas',
                            text: 'Ha ocurrido un error y no hemos podido procesar tu solicitud, por favor intenta más tarde.',
                            button: 'Acepto'});
                        var reset = document.getElementById("email");
                        reset.value = "";
                    })

              } catch (error) {
                //// console.log(error);
              }

       }else{

           this.focus();
       }
  }
  focus() {
    this.PassInput.current.focus();
    this.RePassInput.current.focus();
  }
  validate(values) {
    var validCharsPattern = /^[a-zA-Z0-9$#¡!¿?=&()*]+$/;
    var specialCharPattern = /[$#¡!¿?=&()*]/;
    var upperCasePattern = /[A-Z]/;

    if (!values.value || !values.revalue) {
        swal({
            title: "Campo Vacío",
            text: "Por favor no dejar campo vacio de contraseña",
            icon: "warning"
        });
        return false;
    } else if (values.revalue=='' || values.value==''){
        swal({
            title: "Contraseña Vacía",
            text: "El campo contraseña no puede estar vacio",
            icon: "warning"
        });
        return false;
    } else if(values.value.length<12 || values.revalue.length<12){
        swal({
            title: "Contraseña muy Corta",
            text: "La contraseña no puede ser menor de 12 caracteres",
            icon: "error"
        });
        return false;
    } else if(values.value!=values.revalue){
        swal({
            title: "Contraseñas Incompatibles",
            text: "Las contraseñas que ingresaste no coinciden. Por favor, inténtalo de nuevo.",
            icon: "error"
        });
        return false;
    } else if (!validCharsPattern.test(values.value)){
        swal({
            title: "Caracteres no válidos",
            text: "La contraseña solo puede contener letras, números y los siguientes caracteres especiales: $ # ¡ ! ¿ ? % = & ( ) *",
            icon: "error"
        });
        return false;
    } else if (!specialCharPattern.test(values.value)){
        swal({
            title: "Falta Caracter Especial",
            text: "La contraseña debe contener al menos uno de los siguientes caracteres especiales: $ # ¡ ! ¿ ? % = & ( ) *",
            icon: "error"
        });
        return false;
    } else if (!upperCasePattern.test(values.value)){
        swal({
            title: "Falta una letra mayúscula",
            text: "La contraseña debe contener al menos una letra mayúscula",
            icon: "error"
        });
        return false;
    }
    return true;
}

    render(){

        return(
            <Layout>
                <div className="panel-body">
               <div className={['mar-ver', 'pad-btm', classes.TitleSection].join(' ')}>
                  <h1 className="h3">Recupera tu contraseña</h1>
                  <p className="pad-btm">Ingrese su nueva contraseña y repitala para verificarla</p>
               </div>
                  <form encType="multipart/form-data">
                      <div className="form-group ">
                          <input id="password" name="password" type="password" ref={this.PassInput} value={this.state.value} onKeyUp={this.MedidorSeguridad} onChange={this.handleChangeV} placeholder="Escriba Contraseña"className="form-control" required/> 
                      </div>
                      <div style={{whiteSpace:"nowrap",textAlign:"center"}}>
                            <Divider id='hr1' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <Divider id='hr2' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <Divider id='hr3' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <Divider id='hr4' sx={{width:{xs:'34px',sm:'47px',md:"52px"}, display:"inline-block",margin:"0 5px",marginBottom:"32px"}}/>
                            <p id='p' style={{marginTop:'-23px'}}></p> 
                      </div>
                      <div className="form-group ">
                          <input id="repassword" name="repassword" type="password" ref={this.RePassInput} value={this.state.revalue} onChange={this.handleChangeP} placeholder="Re-escriba Contraseña"className="form-control" required/>
                          <small id="passwordHelp" class="form-text text-muted">*Doce caracteres como mínimo combinados con letras, números y sin espacios.</small>
                      </div>
                      <div className="form-group text-right">
                          <Button onClick={this.handleSubmit} type="danger">Restablecer contraseña</Button>
                      </div>
                  </form>
                  <div className="pad-top" style={{textAlign: 'center'}}>
                  <Link to="/auth/login/" className={['btn-link', 'text-bold'].join(' ')}>Regresar al inicio de sesión</Link>
                  </div>
              </div>
            </Layout>
        )
    }
}

export default ResetPassword;